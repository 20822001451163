import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPayload, RootState } from "redux/store";
import { Agent } from "model/agent";
import { RichTextEditor } from "component/shared/rich-text-editor";
import { updateAgent } from "redux/slice/agents";
import { SaveButton } from "component/shared/save-button";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
	{}

interface OwnProps {
	agent: Agent;
}

interface State {
	head: string;
	body: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		loading: state.agents.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			updateAgent,
		},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			head: this.props.agent.head || "",
			body: this.props.agent.body || "",
		};
	}

	private async save() {
		const { agent } = this.props;
		const { head, body } = this.state;
		getPayload(await this.props.updateAgent( 
			{ agent: 
				{
					...agent,
					head,
					body,
				}
			})
		);
	}

	render() {
		const { loading } = this.props;
		const { body, head } = this.state;
		return (
			<Mui.Grid container spacing={1}>
				<Mui.Grid container	alignItems="center"	justifyContent="center"	spacing={2}>
				<Mui.Grid item xs={12}>
						<Mui.Typography>
						Page Header
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<RichTextEditor
							onChange={(text) =>
								this.setState({ head: text })
							}
							toolbarId="head"
							value={head}
						/>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Mui.Typography>
							Body Text
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<RichTextEditor
							onChange={(text) =>
								this.setState({ body: text })
							}
							toolbarId="body"
							value={body}
						/>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<SaveButton 
							loading={loading || false}
							snackBar
							label={{
								primary: "Save",
								inProgress: "Saving...",
								completed: "Saved"
							}}
							onClick={() => this.save()}					
						/>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const BioTab = connect(mapStateToProps, mapDispatchToProps)(Component);