class Type {
	
	public static ACTIVE = new Type(1, "Active");
	public static COMPLETE = new Type(2, "Complete");
	public static DELETED = new Type(3, "Deleted");
	
	private constructor(
		public readonly id: number,
		public readonly name: string,
	) {
	}

	public get active() {
		return this === Type.ACTIVE;
	}

	public get complete() {
		return this === Type.COMPLETE;
	}

	public get deleted() {
		return this === Type.DELETED;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as TaskStatus };