import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { Details } from "./details";
import { Page } from "model/page";

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface State {
	expanded: boolean;
}

type Props = OwnProps
	& ReturnType<typeof mapDispatchToProps>
	& Mui.WithStyles<typeof styles>
;

interface OwnProps {
	page: Page;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	public render() {
		const {
			page,
			classes,
		} = this.props;
		const {
			expanded,
		} = this.state;
		return (
			<>
				{page && (
					<Mui.Accordion 
						onChange={() => this.setState({expanded: !expanded})}
					>
						<Mui.AccordionSummary expandIcon={<Icons.ChevronDown />}>
							<Mui.Typography 
								variant={(expanded) ? "h3" : undefined} 
								className={classes.title}
							>
								{page.type.label}
								{!page.enabled &&(
									<Mui.Tooltip 
										title="Make sure this page is set up. Once it is, expand these settings 
										and indicate the setup is complete." 
									>
										<FeatherIcon className={classes.pageAlert}>
											<Icons.AlertTriangle />
										</FeatherIcon>
									</Mui.Tooltip>
								)}
								{(expanded) && page.enabled && (
									<FeatherIcon className={classes.pageOk}>
										<Icons.CheckCircle />
									</FeatherIcon>
								)}
							</Mui.Typography>
						</Mui.AccordionSummary>
						<Mui.AccordionDetails>
							<Details page={page} />
						</Mui.AccordionDetails>
					</Mui.Accordion>
				)}
			</>
		);
	}
}

export const Accordion = Mui.withStyles(styles)(
	connect(null, mapDispatchToProps)(Component)
);
