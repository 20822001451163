import React from "react"
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface Props extends 
	Mui.WithStyles<typeof styles> {
	label: string,
	placeholder?: string,
	value?: string,
	errorText?: string | false,
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	sidebarNote?: string,
}

class Component extends React.Component<Props> {
	public render() {
		const { classes, onChange, value, errorText, label, placeholder } = this.props;

		return (
			<Mui.Grid container direction="row" spacing={2}>
				<Mui.Grid item xs={6}>
					<Mui.TextField 
										label={label}
										placeholder={placeholder}
										helperText={errorText}
										error={!!errorText}
										FormHelperTextProps={{
											className: classes.errorMessage
										}}
										variant="outlined"
										value={value}
										onChange={onChange}
										InputLabelProps={{ shrink: true }}
										fullWidth />
				</Mui.Grid>
				<Mui.Grid item xs={6}>
					{ this.props.sidebarNote !== undefined &&
						<div>
							<strong>Note: </strong> {this.props.sidebarNote}
						</div>
					}
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const FullWidthField = Mui.withStyles(styles)(Component)