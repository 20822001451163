import { LeadPhoneResource } from "api/resource/lead-phone";

export const leadPhones : LeadPhoneResource[] = [
	{
		id: 1,
		leadId: 1,
		typeId: 1,
		updatedOn: "2019-10-02T14:00:00.000-08:00",
		createdOn: "2019-10-02T14:00:00.000-08:00",
		value: "555.555.0001"
	},
	{
		id: 2,
		leadId: 1,
		typeId: 1,
		updatedOn: "2019-10-02T14:00:00.000-08:00",
		createdOn: "2019-10-02T14:00:00.000-08:00",
		value: "555.555.0002"
	},
	{
		id: 3,
		leadId: 1,
		typeId: 1,
		updatedOn: "2019-10-07T14:00:00.000-08:00",
		createdOn: "2019-10-07T14:00:00.000-08:00",
		value: "555.555.0003"
	},
	{
		id: 4,
		leadId: 2,
		typeId: 1,
		updatedOn: "2019-10-02T14:00:00.000-08:00",
		createdOn: "2019-10-02T14:00:00.000-08:00",
		value: "555.555.0004"
	},
	{
		id: 5,
		leadId: 2,
		typeId: 1,
		updatedOn: "2019-10-02T14:00:00.000-08:00",
		createdOn: "2019-10-02T14:00:00.000-08:00",
		value: "555.555.0005"
	},
	{
		id: 6,
		leadId: 2,
		typeId: 1,
		updatedOn: "2019-10-07T14:00:00.000-08:00",
		createdOn: "2019-10-07T14:00:00.000-08:00",
		value: "555.555.0006"
	}
]
