import React from "react";
import * as Mui from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

interface Props extends SvgIconProps {
	children: React.SVGAttributes<SVGAElement>;
	className?: string;
}

class Component extends React.Component<Props> {
	public render() {
		const { children, className, ...others } = this.props;
		return (
			<Mui.SvgIcon {...others} style={{padding: 1, boxSizing: "border-box"}} className={className}>
				{children}
			</Mui.SvgIcon>
		);
	}
}

export const FeatherIcon = Component;