import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		root: {
			display: "block",
			border: "none",
			fontSize: "1rem",
		},
		spacer: {
			display: "none",
		},
		actions: {
			marginLeft: "auto",
		},
	});
};
