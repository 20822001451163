import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { IntegrationType } from "type/integration-type";

interface Props extends
	Mui.WithStyles<typeof styles>
{
	integrationType: IntegrationType;
}

class Component extends React.Component<Props> {

	public render() {
		const { classes, integrationType } = this.props;
		return (
			<>
				{integrationType.logoUrl && (
					<Mui.Grid item>
						{integrationType.websiteUrl ? (
							<Mui.Link href={integrationType.websiteUrl} target="_blank">
								<Mui.CardMedia className={classes.logo} image={integrationType.logoUrl} />
							</Mui.Link>
						) : (
							<Mui.CardMedia className={classes.logo} image={integrationType.logoUrl} />
						)}
					</Mui.Grid>
				)}
			</>
		);
	}

}

export const Logo = Mui.withStyles(styles)(Component);