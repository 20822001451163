class Type {

	public static OFFICE_NAME = new Type("officeName", "Office Name");
	public static ADDRESS = new Type("address", "Address");
	public static CITY = new Type("city", "City");
	public static PRIORITY = new Type("priority", "Priority");
	public static ACTION = new Type("action", "Action");
	

	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as OfficeColumnType };
