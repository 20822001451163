import React from "react";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import * as Icons from "react-feather";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { CssHtmlTextField } from "component/shared/css-html-text-field";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { DashboardLayout } from "component/layout/dashboard";
import { User } from "model/user";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";
import { PageProps } from "shared/page-props";

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	user: User;
}

interface State {
	cssOverride: string;
	saving: boolean;
	shadowDomCss: string;
	shadowDomHtml: string;
}

const mapStateToProps = (state: RootState) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			...props.user,
			saving: false,
		};
	}

	public save() {
		const { saveUser } = this.props;
		const user = {
			...this.state,
		};
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const { authError, authLoading, user } = this.props;
		const { cssOverride, saving, shadowDomCss, shadowDomHtml } = this.state;
		const title = "CSS";
		return (
			<DashboardLayout
				permitted={user.permissions.standalone || (!user.websitePlatform.wordpress && user.kestrelVersion.all)}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						{user.kestrelVersion && (user.kestrelVersion.detail || user.kestrelVersion.all) && (
							<>
								<Mui.Grid item>
									<CssHtmlTextField
										label="Add CSS to IDX Stylesheet"
										onChange={(value: string) => {
											this.setState({
												shadowDomCss: value,
											});
										}}
										note="(v10 only) Add CSS overrides to the shadow DOM"
										value={shadowDomCss}
									/>
								</Mui.Grid>
								<Mui.Grid item>
									<CssHtmlTextField
										label="Add Code to IDX Content"
										onChange={(value: string) => {
											this.setState({
												shadowDomHtml: value,
											});
										}}
										note="(v10 only) Add scripts and other HTML to the shadow DOM"
										value={shadowDomHtml}
									/>
								</Mui.Grid>
							</>
						)}
						<Mui.Grid item>
							<CssHtmlTextField
								label="Add Code to Page Head"
								onChange={(value: string) => {
									this.setState({
										cssOverride: value,
									});
								}}
								note="Add CSS overrides, scripts, and other HTML to the head of the page"
								value={cssOverride}
							/>
						</Mui.Grid>
						<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									disabled={saving}
									onClick={() => this.save()}
									style={{ marginRight: 32 }}
									variant="contained"
								>
									Save Changes
								</Mui.Button>
								<Mui.Button
									onClick={() =>
										this.setState({
											saving: this.state.saving,
										})
									}
									variant="contained"
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
						open={saving && !authLoading && !authError}
						message="Settings saved"
						autoHideDuration={6000}
						onClose={() => this.setState({ saving: false })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ saving: false })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>Settings saved</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsCssPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Component);
