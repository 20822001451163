import React from "react";
import { PageProps } from "shared/page-props";
import * as Router from "react-router";
import { AgentPassword } from "./agent-password";
import { ClientPassword } from "./client-password";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		const { user } = this.props;
		if (user.admin)
			return (<ClientPassword {...this.props} />)
		else
			return (<AgentPassword {...this.props} />)
	}

}

export const SettingsPasswordPage = Component;