import React from "react";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import { AccountInfo } from "./account-info";
import { AgentBio } from "./agent-bio";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		const { user } = this.props;
		return (user.admin) ? <AccountInfo {...this.props} /> : <AgentBio {...this.props} />;
	}

}

export const SettingsAccountPage = Component;