import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		card: {
			overflow: "unset",
		},
		cardContent: {
			padding: 32,
			"& * > :first-child > h3": {
				marginTop: 0
			} 
		}
	})
}
