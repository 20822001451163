import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		chipContainer: {
			marginTop: 10
		},
		numberChip: {
			marginBottom: 10,
		},
		  addButton: {
			marginLeft: 10
		  },
	});
};