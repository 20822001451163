import { OneUserResource } from "api/resource/one-user-now";

export const oneUser: OneUserResource = {
	oneAgentId: 3125,
	permissions: {
		generateReports: true,
		manageBlog: true,
		manageMarketingExpenses: true,
		manageTeam: true,
		sendEmail: true,
		viewOmniPressDashboard: true,
	}
};