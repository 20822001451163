import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		searchBar: {
			backgroundColor: "white",
			borderRadius: 4,
			padding: "5px 20px",
		},
		filter: {
			textAlign: "right"
		},
		filterMobileButton: {
			paddingLeft: 2,
			paddingRight: 2,
		},
		filterIcon: {
			marginTop: "2px",
			marginBottom: "2px",
		}
	});
};
