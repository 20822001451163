class Type {

	public static LISTINGS = new Type("propertiesGallery", "Listings");
	public static FEATURED_LISTINGS = new Type("featuredListings", "Featured Listings");
	public static MARKETS = new Type("markets", "Market Index");
	public static MARKET_REPORT = new Type("marketReport", "Market");
	public static GALLERY_SLIDER = new Type("gallerySlider", "Gallery Slider");
	public static QUICK_SEARCH = new Type("quickSearch", "Quick Search");
	public static LISTING_SEARCH = new Type("listingSearch", "Listing Search");
	public static MARKET_REPORT_SIGNUP = new Type("marketReportSignup", "MarketBoost Report Signup");
	public static REGISTRATION_FORM = new Type("registrationForm", "Registration Form");
	public static VALUATION_FORM = new Type("valuationForm", "Sell My House");
	public static CONTACT_FORM = new Type("contactForm", "Contact Form");
	public static LOGIN = new Type("login", "Property Organizer Login");
	public static AGENT_LISTINGS = new Type("agentListings", "Agent Listings");
	public static OFFICE_LISTINGS = new Type("officeListings", "Office Listings");


	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type).sort((a, b) => a.label.localeCompare(b.label))
	}

	public static getById(id?: string) {
		return id ? this.values().sort((a, b) => a.label.localeCompare(b.label)).find(value => value.id === id) : undefined;
	}
}

export { Type as WidgetType };
