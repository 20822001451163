import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { getCustomTagsForLead } from "redux/selector";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setLeadsPageFilters } from "redux/slice/leads-page";
import { getLeadsPageFilters } from "redux/selector";

interface State {
	expanded: boolean,
}

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> 
{
	leadId: number;
}

interface OwnProps {
	leadId: number;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		tags: getCustomTagsForLead(state, ownProps.leadId),
		filters: getLeadsPageFilters(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setLeadsPageFilters,
}, dispatch);

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	public toggleExpanddedTag = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	public render() {
		const { tags, classes, filters, setLeadsPageFilters } = this.props;
		const tagChips = this.state.expanded ? tags : tags.slice(0, 3);
		return (
			<>
				{tagChips.map((tag, index) => {
					return (
						<Mui.Chip
							key={index}
							className={classes.tag}
							label={tag.text}
							onClick={() => {
								setLeadsPageFilters({
									...filters,
									tag: [tag.text],
								});
							}}
						/>
					);
				})}
				{tags.length > 3 && !this.state.expanded &&
					<Mui.Chip className={[classes.tag, classes.moreTag].join(" ")} label="..." onClick={() => this.toggleExpanddedTag()}/>
				}
				{this.state.expanded &&
					<Mui.Button className={classes.minButton} onClick={() => this.toggleExpanddedTag()}>
						<FeatherIcon fontSize="small">
							<Icons.ChevronUp/>
						</FeatherIcon>
					</Mui.Button>	
				}
			</>
		);
	}
}

export const Tag = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);