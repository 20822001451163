import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		errorMessage: {
			color: theme.palette.error.main,
			fontSize: "0.75rem",
			fontWeight: "bold",
		},
		edit: {
			minWidth: 20,
			marginLeft: 2,
		},
		infoRoot: {
			paddingTop: 2,
			paddingBottom: 2,
		},
		infoMessage: {
			paddingTop: 4,
			paddingBottom: 4,
		},
		infoMessageIcon: {
			alignSelf: "center",
			paddingTop: 1,
			paddingBottom: 1,
		},
	})
}
