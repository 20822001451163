export const UPPER = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const LOWER = "abcdefghijklmnopqrstuvwxyz";
export const NUMBER = "0123456789";

export const random = (length: number, characters: string) => {
	return Array
		.from({ length })
		.map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
		.join("")
	;
}