class Type {
	public static AGENT = new Type(1, "Agent");
	public static STAFF = new Type(2, "Staff");
	public static MANAGEMENT = new Type(3, "Management");

	private constructor(
		public readonly id: number,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.AGENT;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: number) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: number): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as AgentStaffType };
