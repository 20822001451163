import React from "react";
import * as Mui from "@material-ui/core";
import { TaskItem } from "./task-item";
import { AddEditTaskDialog } from "component/shared/add-edit-task-dialog";
import { Lead } from "model/lead";
import { Task } from "model/task";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import * as paths from "routes/paths";
import { styles } from "./style";

interface Props extends Mui.WithStyles<typeof styles> {
	tasks: Task[];
	lead?: Lead;
	taskDialogIsOpen?: boolean;
	onCloseDialog?: Function;
}

interface State {
	taskDialogIsOpen: boolean;
	selectedTask: Task | null;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			taskDialogIsOpen: !!props.taskDialogIsOpen,
			selectedTask: null
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.taskDialogIsOpen !== this.props.taskDialogIsOpen) {
			this.setState({ taskDialogIsOpen: !!this.props.taskDialogIsOpen });
		}
	}

	render() {
		const { tasks, lead, onCloseDialog, classes } = this.props;
		const { selectedTask, taskDialogIsOpen } = this.state;
		return (
			<>
				<AddEditTaskDialog
					open={taskDialogIsOpen}
					onClose={() => {
						this.setState({ selectedTask: null, taskDialogIsOpen: false });
						// Handles closing dialog on parent's state
						onCloseDialog && onCloseDialog();
					}}
					lead={lead}
					task={selectedTask}
				/>
				<Mui.CardContent>
					{!tasks.length && (
						<Mui.Typography align="center" style={{ margin: "8px 0"}}>
							No open tasks for this lead.
						</Mui.Typography>
					)}
					{!!tasks.length && tasks.map((task, index) => (
						<div key={index}>
							<TaskItem
								task={task}
								onClick={() =>
									this.setState({ selectedTask: task, taskDialogIsOpen: true })
								}
							/>
							<Mui.Divider />
						</div>
					))}
				</Mui.CardContent>
				<Mui.CardActions className={classes.cardActions}>
					<Mui.Button
						variant="contained"
						color="secondary"
						component={RouterLinkWrapper}
						to={paths.tasks}
					>
						View All
					</Mui.Button>
				</Mui.CardActions>
			</>
		);
	}
}

export const TasksComponent = Mui.withStyles(styles)(Component);