import { CampaignEmailResource } from "api/resource/campaign-email";

export const campaignEmails: CampaignEmailResource[] = [
	{
		id: 1,
		leadId: 1,
		campaignId: 100,
		campaignMessageId: 1,
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
		openedOn: "2019-03-13T14:46:41.723-09:00",
		clickedOn: "2019-03-13T14:46:41.723-09:02",
		clicks: 3,
	},
	{
		id: 2,
		leadId: 1,
		campaignId: 100,
		campaignMessageId: 2,
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
	},
	{
		id: 3,
		leadId: 1,
		campaignId: 100,
		campaignMessageId: 3,
		failedOn: "2019-03-10T14:46:41.723-08:00",
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
		openedOn: "2019-04-13T14:46:41.723-09:00",
		clicks: 0,
	},
	{
		id: 4,
		leadId: 1,
		campaignId: 100,
		campaignMessageId: 4,
		failedOn: "2019-03-10T14:46:41.723-08:00",
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
	},
	{
		id: 5,
		leadId: 1,
		campaignId: 100,
		campaignMessageId: 5,
		failedOn: "2019-03-13T14:46:41.723-08:00",
	},
	{
		id: 6,
		leadId: 1,
		campaignId: 400,
		campaignMessageId: 12,
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
		openedOn: "2019-03-13T14:46:41.723-09:00",
		clickedOn: "2019-03-13T14:46:41.723-09:02",
		clicks: 5,
	},
	{
		id: 7,
		leadId: 2,
		campaignId: 400,
		campaignMessageId: 12,
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
		openedOn: "2019-03-13T14:46:41.723-09:00",
	},
	{
		id: 8,
		leadId: 3,
		campaignId: 400,
		campaignMessageId: 12,
		// failedOn: "2019-03-13T14:46:41.723-08:00",
	},
	{
		id: 9,
		leadId: 4,
		campaignId: 400,
		campaignMessageId: 12,
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
		// failedOn: "2019-03-13T14:46:41.723-08:00",
	},
	{
		id: 10,
		leadId: 4,
		campaignId: 500,
		campaignMessageId: 13,
		deliveredOn: "2019-03-13T14:46:41.723-08:00",
		// failedOn: "2019-03-13T14:46:41.723-08:00",
	},
	
];