import React from "react";
import { Integration } from "model/integration";
import { TopProducerLtsIntegration } from "./top-producer-lts";
import { CustomLtsIntegration } from "./custom-lts";
import { ZapierNewLeadIntegration } from "./zapier-new-lead";
import { LeadAggregationIntegration } from "./lead-aggregation";
import { ZillowTechConnectIntegration } from "./zillow-tech-connect";
import { EmailCalendarIntegration } from "./email-calendar";
import { User } from "model/user";
import { GoogleLoginIntegration } from "./google-login";
import { WixIntegration } from "./wix-app";

interface Props {
	integration: Integration;
	user: User;
}

class Component extends React.Component<Props> {

	public render() {
		const { integration, user } = this.props;
		return (
			<>
				{integration.integrationType.topProducerLts && (
					<TopProducerLtsIntegration integration={integration} />
				)}
				{integration.integrationType.customLts && (
					<CustomLtsIntegration integration={integration} />
				)}
				{integration.integrationType.zapierNewLead && (
					<ZapierNewLeadIntegration integration={integration} />
				)}
				{integration.integrationType.leadAggregation && (
					<LeadAggregationIntegration integration={integration} />
				)}
				{integration.integrationType.zillowTechConnect && (
					<ZillowTechConnectIntegration integration={integration} />
				)}
				{integration.integrationType.emailCalendar && (
					<EmailCalendarIntegration integration={integration} user={user} />
				)}
				{integration.integrationType.googleLogin && (
					<GoogleLoginIntegration integration={integration} />
				)}
				{integration.integrationType.wixApp && (
					<WixIntegration integration={integration}/>
				)}
			</>
		);
	}

}

export const EditForm = Component;
