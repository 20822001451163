import * as pathToRegexp from "path-to-regexp";

type replacements = {
	[key: string]: string | number;
};

export class Url<T extends string> {

	private replacements: replacements = {};

	constructor(private path: T) {}

	public replace(replacements: replacements) {
		this.replacements = {
			...replacements,
		}
		return this;
	}

	public toString() {
		const compiled = pathToRegexp.compile(this.path);
		const result = compiled(this.replacements);
		return result as T;
	}

}