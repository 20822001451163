import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		title: {
			color: theme.palette.primary.contrastText,
			fontSize: theme.typography.h4.fontSize,
		},
		tags: {
			maxHeight: 128,
			overflowY: "auto",
		},
		dialogActions: {
			justifyContent: "flex-start",
			alignItems: "flex-start",
			padding: 15,
		},
	});