import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { DashboardLayout } from "component/layout/dashboard";
import { fetchPages } from "redux/slice/pages";
import { RootState } from "redux/store";
import { PageProps } from "shared/page-props";
import { PageType } from "type/page-type";
import { Accordion } from "./accordion";
import { getPages } from "redux/selector";
import { Page } from "model/page";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {	
		pages: getPages(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchPages,
}, dispatch);

type Props = OwnProps
	& PageProps
	& Router.RouteComponentProps
	& ReturnType<typeof mapStateToProps>
	& ReturnType<typeof mapDispatchToProps>
;

interface OwnProps {
}

class Component extends React.Component<Props> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			expandedItem: {},
		};
	}

	public componentDidMount() {
		const {
			fetchPages,
		} = this.props;
		fetchPages();
	}

	private getPages(pageTypes: PageType[]) {
		return pageTypes.map((pageType) => {
			return this.props.pages.find((page) => page.type === pageType);
		}).filter(Boolean) as Page[];
	}

	public render() {
		const { user } = this.props;
		const title = "SEO Pages";
		const searchAndResultsPages = this.getPages([
			PageType.LISTING,
			PageType.LISTINGS,
		]);
		const featuredAndMarketsPages = this.getPages([
			PageType.FEATURED_LISTINGS,
			PageType.PENDING_FEATURED_LISTINGS,
			PageType.SOLD_FEATURED_LISTINGS,
			PageType.OPEN_HOME_LISTINGS,
			PageType.SUPPLEMENTAL_LISTINGS,
			PageType.HOT_SHEETS,
			PageType.LISTING_REPORT,
			PageType.OPEN_HOME_REPORT,
			PageType.MARKET_REPORT,
		]);
		const brokerPages = this.getPages([
			PageType.AGENT,
			PageType.AGENTS,
			PageType.OFFICES,
			PageType.OFFICE,
		]);
		const otherPages = this.getPages([
			PageType.PROPERTY_ORGANIZER,
			PageType.EMAIL_ALERTS,
			PageType.MORTGAGE_CALCULATOR,
			PageType.VALUATION_FORM,
			PageType.CONTACT_FORM,
		]);
		return (
			<DashboardLayout
				permitted={user && user.permissions.listingDetail}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={3}>
					<Mui.Grid item md={12} lg={8} xl={6}>
						<Mui.Typography variant="h3">
							Set Up Your SEO Pages
						</Mui.Typography>
						<Mui.Typography>
							To enhance SEO, create a page on your website for each of the IDX pages below, including the embed code provided.
							Customize the title and meta tags within each page's settings. 
						</Mui.Typography>
					</Mui.Grid>
					{searchAndResultsPages.length > 0 && (
						<>
							<Mui.Grid item md={12} lg={8} xl={6}>
								<Mui.Typography variant="h4">
									Search & Results
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item md={12} lg={8} xl={6}>
								{searchAndResultsPages.map((page, index) => page && <Accordion key={index} page={page} />)}
							</Mui.Grid>
						</>
					)}
					{featuredAndMarketsPages.length > 0 && (
						<>
							<Mui.Grid item md={12} lg={8} xl={6}>
								<Mui.Typography variant="h4">
									Featured & Markets
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item md={12} lg={8} xl={6}>
								{featuredAndMarketsPages.map((page, index) => page && <Accordion key={index} page={page} />)}
							</Mui.Grid>
						</>
					)}
					{brokerPages.length > 0 && (
						<>
							<Mui.Grid item md={12} lg={8} xl={6}>
								<Mui.Typography variant="h4">
									Broker Pages
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item md={12} lg={8} xl={6}>
								{brokerPages.map((page, index) => page && <Accordion key={index} page={page} />)}
							</Mui.Grid>
						</>
					)}
					{otherPages.length > 0 && (
						<>
							<Mui.Grid item md={12} lg={8} xl={6}>
								<Mui.Typography variant="h4">
									Other
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item md={12} lg={8} xl={6}>
								{otherPages.map((page, index) => page && <Accordion key={index} page={page} />)}
							</Mui.Grid>
						</>
					)}
				</Mui.Grid>
			</DashboardLayout>
		);
	}
}

export const SettingsPagesPage = connect(mapStateToProps, mapDispatchToProps)(Component);
