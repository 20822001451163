import React from "react";
import * as Icons from "react-feather";

class Type {

	public static MORE_INFO_REQUEST = new Type(1, "More Info Request", "Requested more info", Icons.Info);
	public static SCHEDULE_SHOWING_REQUEST = new Type(2, "Schedule Showing", "Scheduled a showing", Icons.Calendar);
	public static PROPERTY_ORGANIZER_SIGNUP = new Type(3, "Organizer Signup", "Signed up for an Organizer", Icons.UserPlus);
	public static SAVED_LISTING = new Type(4, "Saved Listing", "Saved a listing", Icons.Heart);
	public static EMAIL_UPDATES_SIGN_UP = new Type(5, "Email Update Signup", "Signed Up for email updates", Icons.UserPlus);
	public static VALUATION_REQUEST = new Type(6, "Valuation Request", "Requested a valuation", Icons.Clipboard);
	public static CONTACT_REQUEST = new Type(7, "Contact Form", "Contacted you", Icons.Mail);
	public static LEAD_CONVERSION = new Type(8, "Lead Added", "Lead was added", Icons.UserPlus);
	public static WEB_REGISTRATION = new Type(9, "User Registered", "Registered", Icons.UserPlus);
	public static LISTING_REPORT_SIGNUP = new Type(10, "Listing Report Signup", "Listing report signup", Icons.PieChart);
	public static OPEN_HOME_REPORT_SIGNUP = new Type(11, "Open Home Report Signup", "Open Home signup", Icons.PieChart);
	public static MARKET_REPORT_SIGNUP = new Type(12, "Market Report Signup", "Market report signup", Icons.PieChart);
	public static EXTERNAL_LEAD = new Type(13, "Lead Added", "Lead was added", Icons.UserPlus);
	public static DETAIL_VIEW = new Type(14, "Viewed Detail", "Viewed a listing", Icons.Aperture);
	public static SEARCH = new Type(15, "Viewed Results", "Viewed results", Icons.List);
	public static SAVED_SEARCH = new Type(16, "Saved Search", "Saved a search", Icons.Search);
	public static LINK_CLICK = new Type(17, "Click-thru", "Clicked a link you sent", Icons.Link2);
	public static TEXT_MESSAGE_RESPONSE = new Type(18, "Text message response", "Responded to a text message", Icons.MessageSquare);
	public static PHONE_FORWARDED = new Type(19, "Lead Phone Forwarded", "Lead Phone Forwarded", Icons.PhoneForwarded);

	private constructor(
		public readonly id: number,
		public readonly label: string,
		public readonly phrase: string,
		public readonly icon: React.ComponentType<Icons.IconProps>
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as LeadActivityType };
