import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		layout: {
			display: "flex",
			minHeight: "100vh",
		},
		left: {
			width: 280,
			position: "sticky",
			top: 0,
			flexGrow: 0,
			flexShrink: 0,
			height: "100vh",
			display: "flex",
			flexDirection: "column",
			boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
			zIndex: 1200,
			"@media (max-width: 960px)": {
				transitionProperty: "left",
				transitionDuration: "0.25s",
				transitionTimingFunction: "ease-in-out",
				position: "fixed",
				left: -285,
				"&.show": {
					left: 0,
				},
				"&:not(.show)": {
					"& .backdrop": {
						opacity: 0,
						visibility: "hidden",
					}
				},
				"& .backdrop": {
					transitionProperty: "opacity visibility",
					transitionDuration: "0.25s",
					transitionTimingFunction: "ease-in-out",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: -1,
					position: "fixed",
					opacity: 0.5,
					backgroundColor: "black",
				}
			},
		},
		leftTop: {
			display: "flex",
			flexDirection: "row",
			flexShrink: 0,
			height: 64,
			padding: 10,
			boxSizing: "border-box",
			backgroundColor: theme.palette.primary.main,
			color: "#fff",
		},
		logoWrapper: {
			flex: 1,
			display: "flex",
			alignItems: "center",
		},
		logo: {
			width: "100%",
			height: "100%",
			objectFit: "contain",
			objectPosition: "top",
		},
		logoMissing: {
			textAlign: "center",
			width: "100%",
			fontSize: 26,
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		toggle: {
			width: 24,
			cursor: "pointer",
			alignSelf: "center",
			"@media (min-width: 960px)": {
				display: "none",
			}
		},
		leftBottom: {
			flexGrow: 1,
			overflowY: "auto",
			backgroundColor: "#303030",
			paddingBottom: 60,
		},
		namePlate: {
			backgroundColor: theme.palette.primary.main,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			padding: 15,
			marginBottom: 20,
		},
		line1: {
			fontSize: 20,
			color: theme.palette.primary.contrastText,
			textAlign: "center",
			marginBottom: 10,
		},
		line2: {
			fontSize: 18,
			color: theme.palette.primary.contrastText,
		},
		right: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
			width: 1, // hack
		},
		navBar: {
			backgroundColor: "#f5f5f5",
		},
		flexGrow: {
			flexGrow: 1
		},
		header: {
			background: theme.palette.primary.main,
			padding: "18px 0",
			zIndex: 1000,
			"& h1": {
				display: "flex",
				alignItems: "center",
				"& svg": {
					width: 30,
					height: 30,
					marginRight: 10,
				}	
			}
		},
		toolbar: {
			overflow: "hidden",
			marginRight: 16,
		},
		content: {
			flex: 1,
			backgroundColor: "#fafafa",
			display: "flex",
			flexDirection: "column",
		},
		mainContainer: {
			flex: 1,
			padding: 25,
		},
		fixedWidthContent: {
			display: "flex",
			alignItems: "center",
			flexWrap: "nowrap",
			flexDirection: "column",
		},
		fixedWidthChild: {
			maxWidth: 1024,
			width: "100%",
		},
		iframeContainer: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
			"& iframe": {
				flex: 1,
				border: "none",
			},
		},
	});
};