import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { LeadRanking } from "type/lead-ranking";
import { styles } from "./style";

interface Props extends Mui.WithStyles<typeof styles> {
	leadRanking: LeadRanking;
}
interface State {}

class Component extends React.Component<Props, State> {
	public render() {
		const { leadRanking, classes } = this.props;
		return (
			<div className={classes.container}>
				<FeatherIcon>
					<Icons.Star
						className={
							leadRanking.id > LeadRanking.NO_RANKING.id
								? classes.activeZap
								: classes.star
						}
					/>
				</FeatherIcon>
				<FeatherIcon>
					<Icons.Star
						className={
							leadRanking.id > LeadRanking.LOW.id ? classes.activeZap : classes.star
						}
					/>
				</FeatherIcon>
				<FeatherIcon>
					<Icons.Star
						className={
							leadRanking.id > LeadRanking.MEDIUM.id ? classes.activeZap : classes.star
						}
					/>
				</FeatherIcon>
			</ div>
		);
	}
}

export const Stars = Mui.withStyles(styles)(Component);
