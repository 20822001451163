import React from "react";
import { ErrorLayout } from "component/layout/error";

const Component = () => (
	<ErrorLayout
		title="Page Not Found"
		header="404"
		message="Sorry but we could not find the page you are looking for"
	/>
)

export const NotFoundPage = Component;