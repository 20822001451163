import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	const green = "#448829";
	return createStyles({
		pin: {
			display: "flex",
			position: "relative",
			width: 28,
			height: 28,
			borderRadius: "50%",
			backgroundColor: green,
			color: "white",
			alignItems: "center",
			justifyContent: "center",
			fontSize: ".9rem",
			"&:after": {
				position: "absolute",
				left: 4, 
				top: 24,
				border: "10px solid transparent",
				borderTop: "17px solid " + green,
			},
		},
	});
};
