import { LeadResource } from "api/resource/lead";

export const leads: LeadResource[] = [
	{
		id: 1,
		name: "Catherine Mcgregor",
		email: "cmcgregor@mailinator.com",
		email2: "gregory.mcgregor@mailinator.com",
		phone: "(123) 456-7890",
		address: "123 Main St.",
		city: "Smallsville",
		state: "CA",
		zip: "56359",
		note: "Wow, such great notes! \n okay what's next",
		source: "Zillow",
		createdOn: "2018-11-13T14:46:41.723-08:00",
		status: "active",
		ranking: 1100,
		agentId: 1,
	},
	{
		id: 2,
		name: "T-Jay Walls",
		email: "walls.tj@mailinator.com",
		phone: "0000000000",
		createdOn: "2018-11-13T14:46:41.723-08:00",
		status: "active",
		ranking: 1800,
		agentId: 2,
	},
	{
		id: 3,
		email: "1a2b3c@mailinator.com",
		createdOn: "2015-04-13T14:46:41.723-08:00",
		status: "disabled",
		source: "Zillow",
		ranking: 500,
		agentId: 3,
	},
	{
		id: 4,
		name: "Uncontacted",
		email: "dddd@mailinator.com",
		createdOn: "2018-09-30T14:46:41.723-08:00",
		source: "Top Producer",
		ranking: 0,
		status: "active",
	},
	{
		id: 5,
		name: "Bill Nye",
		email: "bill@mailinator.com",
		phone: "0000000000",
		createdOn: "2019-05-13T14:46:41.723-09:00",
		ranking: 300,
		status: "deleted",
	},
	{
		id: 6,
		name: "Donny Marie",
		email: "dm@mailinator.com",
		phone: "0000000000",
		createdOn: "2017-06-13T14:46:41.723-08:00",
		status: "disabled",
	},
	{
		id: 7,
		name: "Craig Jensen",
		email: "wacj@mailinator.com",
		phone: "0000000000",
		createdOn: "2019-01-13T14:46:41.723-08:00",
		source: "Zillow",
		ranking: 3000,
		status: "active",
	},
	{
		id: 8,
		name: "No Pipeline Status",
		email: "d@m.com",
		createdOn: "2018-09-13T14:46:41.723-08:00",
		status: "active",
	},
	{
		id: 9,
		name: "Lead McLeady",
		email: "lead@mailinator.com",
		phone: "(123) 456-7890",
		address: "123 Main St.",
		city: "Smallsville",
		state: "CA",
		zip: "56359",
		note: "Wow, such great notes! \n okay what's next",
		source: "Zillow",
		createdOn: "2018-11-13T14:46:41.723-08:00",
		status: "active",
		ranking: 2600,
		agentId: 1,
	},
	{
		id: 10,
		name: "Human Person",
		email: "hp@mailinator.com",
		phone: "(123) 456-7890",
		address: "ç",
		city: "Smallsville",
		state: "CA",
		zip: "56359",
		note: "Wow, such great notes! \n okay what's next",
		source: "Zillow",
		createdOn: "2018-11-13T14:46:41.723-08:00",
		status: "active",
		ranking: 600,
		agentId: 1,
	},
	{
		id: 11,
		name: "Mary Makenoffer",
		email: "mmakenoffer@mailinator.com",
		phone: "(123) 456-7890",
		address: "123 Main St.",
		city: "Smallsville",
		state: "CA",
		zip: "56357",
		note: "Addicted to searching listings! \n ready to buy soon",
		source: "Zillow",
		createdOn: "2018-11-13T14:46:41.723-08:00",
		status: "active",
		ranking: 4000,
		agentId: 1,
	},
	{
		id: 12,
		name: "Alex Allcash",
		email: "allcash@mailinator.com",
		phone: "510-555-5555",
		createdOn: "2020-01-13T14:46:41.723-08:00",
		source: "Zillow",
		ranking: 2900,
		status: "active",
	},
];