import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		iframe: {
			width: "100%",
			minHeight: 250,
			border: 0,
		},
		label: {
			minWidth: 140,
		},
		failedText: {
			fontSize: ".8rem",
			color: "red",
		}
	});
};
