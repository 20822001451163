import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		searchBar: {
			backgroundColor: "white",
			borderRadius: 4,
			padding: "5px 20px",
		},
		filter: {
			textAlign: "right"
		},
		filterMobileButton: {
			paddingLeft: 2,
			paddingRight: 2,
		},
		filterIcon: {
			marginTop: "2px",
			marginBottom: "2px",
		},
		dropdownButton: {
			maxWidth: 120,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			textTransform: "none",
			backgroundColor: "darkgray",
			color: "white",
			border: "1px gray solid",
			"&:hover": {
				backgroundColor: "gray"
			},
			"&:focus": {
				backgroundColor: "gray"
			},
		},
		idButton: {
			borderRadius: 20,
			"&:hover": {
				backgroundColor: "#DCDCDC",
			},
		},
		marketLink: {
			textDecoration: "none"
		},
		marketText: {
			maxWidth: 350
		},
		helperTextStyle: {
			color: "red",
			margin: 0
		},
		alertCircle: {
			height: 20,
			position: "relative",
			right: 5,
			top: 2
		}
	});
};
