import { SiteLinkResource } from "api/resource/site-link";

export const siteLinks: SiteLinkResource[] = [
	{
		id: "agent_list",
		url: "http://test1-java.idxhome.com/agentlist/123"
	},
	{
		id: "office_list",
		url: "http://test1-java.idxhome.com/officelist/123"
	},
	{
		id: "mls_search",
		url: "http://test1-java.idxhome.com/mls_search/123"
	},
	{
		id: "featured_listings",
		url: "http://example.com/featured_listings"
	},
	{
		id: "mobile_homepage",
		url: "http://example.com/mobile_homepage"
	},
	{
		id: "mortgage_calculator",
		url: "http://example.com/mortgage_calc"
	}
];