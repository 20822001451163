class Type {
	
	public static NO_RANKING = new Type(0, "No Ranking");
	public static LOW = new Type(1, "Low");
	public static MEDIUM = new Type(2, "Medium")
	public static HIGH = new Type(3, "High");
	
	private constructor(
		public readonly id: number,
		public readonly label: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as LeadRanking };