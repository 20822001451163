import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateTask } from "redux/slice/tasks";
import moment from "moment";
import { Task } from "model/task";
import { TaskType } from "type/task-type";
import { dateTimeFormatter } from "shared/date-formatter";
import { TaskStatus } from "type/task-status";
import { styles } from "./style";

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateTask,
		},
		dispatch
	);

interface Props
	extends Mui.WithStyles<typeof styles>,
		ReturnType<typeof mapDispatchToProps> {
	task: Task;
	onClick: Function;
}

interface State {}

class Component extends React.Component<Props, State> {
	private toggleCheckBox = () => {
		const { task, updateTask } = this.props;
		const status =
			task.status.active ? TaskStatus.COMPLETE : TaskStatus.ACTIVE;

		updateTask({ task: {
			...task,
			status
		}});
	};

	render() {
		const { task, onClick, classes } = this.props;
		const { title, type, startOn, endOn } = task;
		const checked = task.status !== TaskStatus.ACTIVE;
		const checkedStyle = { textDecoration: checked ? "line-through" : "none" };
		const dateText = type === TaskType.APPOINTMENT
				? `Starts ${dateTimeFormatter(startOn)}`
				: `Due ${dateTimeFormatter(endOn)}`;

		return (
			<div className={classes.container}>
				<Mui.Checkbox
					className={classes.checkbox}
					onChange={() => this.toggleCheckBox()}
					checked={checked}
				/>
				<Mui.Grid container direction="column" spacing={1}>
					<div className={classes.noWrapTypographyWrapper}>
						<Mui.Typography
							noWrap
							className={classes.title}
							style={checkedStyle}
							onClick={() => onClick()}
						>
							{title}
						</Mui.Typography>
					</div>
					<Mui.Grid
						container
						item
						direction="row"
						justifyContent="flex-start"
						style={{ padding: "4px 0" }}
					>
						{type && (
							<Mui.Chip
								label={type.name}
								size="small"
								className={classes.typeChip}
								style={{ backgroundColor: type.backgroundColor }}
							/>
						)}
						<Mui.Typography
							style={{ color: endOn > moment() ? "default " : "#f00" }}
							className={classes.date}
						>
							{dateText}
						</Mui.Typography>
					</Mui.Grid>
				</Mui.Grid>
			</div>
		);
	}
}

export const TaskItem = Mui.withStyles(styles)(
	connect(null, mapDispatchToProps)(Component)
);
