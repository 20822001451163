import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		header: {
			backgroundColor: "#eee"
		},
		messageLabel: {
			fontWeight: "bold",
			marginRight: 5
		},
		container: {
			alignItems: "center",
		},
		stage: {
			display: "inline-flex",
			alignContent:"center",
		},
		dragIndicator: {
			marginTop: 2,
			marginLeft: -10,
			color: "#aaa"
		},
		stat: {
			marginRight: 25,
		},
		stats: {
			textAlign: "center",
		},
		actions: {
			textAlign: "right",	
		},
		actionsIcon: {
			marginLeft: 5,
		},
		message: {
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
				cursor: "pointer"
			},
		},
		timeline: {
			color: "#616161",
			textAlign: "center",
			paddingTop: 0,
		},
		TimelineIcon: {
			fontSize: 38,
			lineHeight: .5
		},
		chip: {
			fontSize: 16,
			marginTop: 8,
			marginRight: 8
		},
		centerIcon: {
			float: "left",
		},
	});
