import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as yup from "yup";
import { Dialog } from "component/shared/dialog";
import { createOffice } from "redux/slice/offices";
import { getPayload } from "redux/store";
import { validate } from "shared/yup";
import { styles } from "../style";
import * as Router from "react-router-dom";
import { Row } from "../row";
import { urls } from "routes/urls";

interface Props
	extends OwnProps,
		ReturnType<typeof mapDispatchToProps>,
		Router.RouteComponentProps,
		Mui.WithStyles<typeof styles> {}

interface OwnProps {
	onClose: () => void;
	open: boolean;
}

interface State {
	name?: string;	
	email?: string;
	submitted: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createOffice,
}, dispatch);

const initialState: State = {
	name: "",
	email: "",
	submitted: false,
};

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState(initialState);
		}
	}

	private submit = async () => {
		const { onClose, history } = this.props;
		const {
			name,
			email,
		} = this.state;
		this.setState({ submitted: true });
		const errors = validate(this.validationSchema, this.state);
		if (!errors && email && name) {
			const officeResource = getPayload(await this.props.createOffice( { office: 
				{
					name: name,
					email: email,
				}
			}));
			if (officeResource && officeResource.id) {
				history.push(urls.settingsOfficeById(officeResource.id));
				onClose();
			}
		}
	};

	private cancel = () => {
		if (
			window.confirm(
				"Are you sure you want to close this dialog? You'll lose any unsaved progress."
			)
		) {
			this.props.onClose();
		}
	};

	private validationSchema = {
		email: yup.string().email("Invalid email.").required("Email is required."),
	};

	render() {
		const { classes, onClose, open } = this.props;
		const {
			name,
			email,
			submitted,
		} = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Dialog
				disableBackdropClick
				maxWidth="md"
				onClose={() => onClose()}
				scroll="paper"
				open={open}
			>
				<Mui.DialogTitle>
					Add Office
					<Mui.IconButton
						className={classes.closeButton}
						onClick={() => this.cancel()}
					>
						<Icons.X />
					</Mui.IconButton>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column" spacing={2} className={classes.container}>
						<Mui.Grid item xs={12}>
							<Row>
								<Mui.TextField
									fullWidth
									label="Office Name"
									margin="dense"
									onChange={(event) => this.setState({ name: event.target.value })}
									value={name}
									variant="outlined"
								/>
							</Row>
						</Mui.Grid>
						<Mui.Grid item xs={12}>
							<Row>
								<Mui.TextField
									error={submitted && !!errors && !errors.email}
									FormHelperTextProps={{
										className: classes.errorMessage,
									}}
									fullWidth
									helperText={submitted && errors && errors.email}
									label="Office Email"
									margin="dense"
									onChange={(event) => this.setState({ email: event.target.value })}
									value={email}
									variant="outlined"
								/>
							</Row>
						</Mui.Grid>
						<Mui.Grid item xs={12}>
							<Mui.Grid container justifyContent="center" spacing={2}>
								<Mui.Grid item>
									<Mui.Button
										className={classes.button}
										variant="contained"
										color="secondary"
										onClick={() => this.submit()}
										disabled={submitted && !!errors}
									>
										Save & Continue
									</Mui.Button>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Button
										variant="outlined"
										color="secondary"
										onClick={() => this.cancel()}
									>
										Cancel
									</Mui.Button>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
		);
	}
}

export const AddOfficeDialog = Router.withRouter(Mui.withStyles(styles)(
	connect(null, mapDispatchToProps)(Component))
);