import { SavedSearchResultTotalResource } from "api/resource/saved-search-result-total";

export const savedSearchResultTotals: SavedSearchResultTotalResource[] = [
	{
		id: 1,
		total: 77,
	},
	{
		id: 2,
		total: 39,
	},
];