class Type {

	public static ACTIVE = new Type("active", "Active");
	public static DISABLED = new Type("disabled", "Inactive");
	public static DELETED = new Type("deleted", "Deleted");

	private constructor(
		public readonly id: "active" | "disabled" | "deleted",
		public readonly label: string,
	) {
	}

	public get active() {
		return this === Type.ACTIVE;
	}

	public get disabled() {
		return this === Type.DISABLED;
	}

	public get deleted() {
		return this === Type.DELETED;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as LeadStatus };
