import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			padding: "10px 0"
		},
		checkbox: {
			height: 48,
			width: 48,
			marginRight: 10
		},
		title: {
			fontWeight: "bold",
			"&:hover": {
				textDecoration: "underline",
				cursor: "pointer"
			}
		},
		typeChip: {
			color: "white",
			marginRight: 10,			
		},
		date: {
			fontSize: 13,
			alignSelf: "center",
		},
		noWrapTypographyWrapper: {
			textOverflow: "ellipsis",
			overflow: "hidden",
			width: "100%"
		}
	});
};
