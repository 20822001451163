class Type {
	public static LEAD_FIRST_NAME = new Type("Lead First Name", "{{first_name}}", "lead");
	public static LEAD_LAST_NAME = new Type("Lead Last Name", "{{last_name}}", "lead");
	public static YOUR_NAME = new Type("Your Name", "{{client_name}}", "client");
	public static PHONE = new Type("Your Phone", "{{phone_number}}", "client");
	public static WEBSITE = new Type("Your Website", "{{web_site}}", "client");
	public static RECOMMENDED_LISTING = new Type("Recommended Listing", "{{recommended_listing}}");

	private constructor(
		public readonly label: string,
		public readonly tag: string,
		public readonly type?: "lead" | "client"
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getByTag(tag: string) {
		return this.values().find(value => value.tag === tag);
	}

}

export { Type as CampaignTextMessageMergeTags };
