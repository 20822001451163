import { Theme, createStyles } from "@material-ui/core";
export const styles = (theme: Theme) => {
    return createStyles({
		textField: {backgroundColor: "#fff"},
		focused: {
			"& fieldset": {
				borderWidth: 2
			}
		},
    });
};
