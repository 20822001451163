import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		noteContainer: {
			display: "flex",
			alignItems: "top",
			width: "fit-content",
			height: "100%"
		},
		verticalDivider: {
			marginRight: 15, 
			marginLeft: 15
		},
		note: {
			display: "flex"
		}
	});
};
