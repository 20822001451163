import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		icon: {
			position: "absolute",
			right: "0",
			pointerEvents: "none",
		},
		adornedEnd: {
			paddingRight: 5,
		},
		button: {
			paddingLeft: 15,
			paddingRight: 15,
		},
		errorMessage: {
			color: theme.palette.error.main,
			fontWeight: "bold",
		}
	});
};
