import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface Props extends Mui.WithStyles<typeof styles> {
	index: number;
}

const Component = (props: Props) => {
	const { classes, index } = props;
	return (
		<div className={classes.pin}>
			{index + 1}
		</div>
	);
};

export const LocationIcon = Mui.withStyles(styles)(Component);
