class Type {
	
	public static TYPE = new Type("type");
	public static STATUS = new Type("status");
	public static BOOKMARKED = new Type("bookmarked");
	public static SUBSCRIBED = new Type("subscribed");
	public static RANKING = new Type("ranking");
	public static SOURCE = new Type("source");
	public static PIPELINE = new Type("pipeline");
	public static TAG = new Type("tag");
	
	private constructor(
		public readonly id: string,
	) {
	}

	public get type() {
		return this === Type.TYPE;
	}

	public get status() {
		return this === Type.STATUS;
	}

	public get bookmarked() {
		return this === Type.BOOKMARKED;
	}

	public get subscribed() {
		return this === Type.SUBSCRIBED;
	}

	public get ranking() {
		return this === Type.RANKING;
	}

	public get source() {
		return this === Type.SOURCE;
	}

	public get pipeline() {
		return this === Type.PIPELINE;
	}

	public get tag() {
		return this === Type.TAG;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as LeadFilterType };