import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { FullPageCard } from "component/shared/full-page-card";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { LeadCaptureStrength } from "./lead-capture-strength"
import { LeadCaptureStrengthType } from "type/lead-capture-strength";
import { SearchPagePromptType } from "type/search-page-prompt";
import { SearchResultsPromptType } from "type/search-results-prompt";
import { SearchDetailPromptType } from "type/search-detail-prompt";
import MuiAlert from "@material-ui/lab/Alert";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { User } from "model/user";
import { connect } from "react-redux";

interface OwnProps {
	user: User;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
}, dispatch);


interface State {
	saving: boolean;
	leadCaptureType?: LeadCaptureStrengthType;
	searchPagePromptType?: SearchPagePromptType;
	searchPagePromptVisits: number;
	searchResultsPromptType?: SearchResultsPromptType;
	searchResultsPromptVisits: number;
	searchDetailPromptType?: SearchDetailPromptType;
	searchDetailPromptVisits: number;
	hotsheetsInResultsLimit: boolean;
	registrationFormHeader: string;
	registrationFormFooter: string;
}

interface Props extends 
	PageProps, Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			...props.user, 
			saving: false,
		}
	}

	private hideSearchPagePrompt() {
		return this.props.user.permissions.eurekaSearch 
			&& this.props.user.eurekaEnabled;
	}

	public save() {
		const { saveUser } = this.props;
			const user = { 
				...this.state,
			};
			if (this.hideSearchPagePrompt()) {
				user.searchPagePromptType = SearchPagePromptType.NEVER;
				user.searchPagePromptVisits = 0;
			}
			saveUser({user});
			this.setState({saving: true});
	}

	public render() {
		const { user, classes, authLoading, authError } = this.props;
		const { 
			saving, 
			leadCaptureType,
			searchPagePromptType,
			searchPagePromptVisits,
			searchResultsPromptType,
			searchResultsPromptVisits,
			searchDetailPromptType,
			searchDetailPromptVisits,
			hotsheetsInResultsLimit,
			registrationFormHeader,
			registrationFormFooter,
		} = this.state;
		const title = "User Registration";
		return (
			<DashboardLayout
				permitted={user && user.permissions.listingDetail}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						<Mui.Grid item>
							<h3>Lead Capture Strength</h3>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Typography>
								Use the settings below to configure how the system should prompt a user for
								registration. You can choose from a very light prompt that the user can
								ignore to a very heavy prompt that requires the user to validate the information
								entered.
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<LeadCaptureStrength 
								value={(leadCaptureType || LeadCaptureStrengthType.OFF).id}
								onChange={event => this.setState({leadCaptureType: LeadCaptureStrengthType.getById(parseInt(event.target.value))})}
								options={LeadCaptureStrengthType.valuesSorted()} />
						</Mui.Grid>
						<Mui.Grid item>
							<h3>Registration Trigger Points</h3>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Typography>
								Use the settings below to configure where and when a user should be prompted for
								registration. When a user exceeds any of the access limits you specify below,
								he or she will be prompted to register.
							</Mui.Typography>
						</Mui.Grid>
						{!this.hideSearchPagePrompt() &&
							<Mui.Grid item>
								<Mui.Grid container direction="row" spacing={2} alignItems="center">
									<Mui.Grid item xs={3}>
										<Mui.Box fontWeight="fontWeightBold">
											Search Page Prompt User:
										</Mui.Box>
									</Mui.Grid>
									<Mui.Grid item xs={9}>
										<Mui.RadioGroup row={true}
											onChange={event => this.setState({searchPagePromptType: SearchPagePromptType.getById(parseInt(event.target.value))})}
											value={(searchPagePromptType || SearchPagePromptType.NEVER).id}>
												<Mui.FormControlLabel
													value={SearchPagePromptType.NEVER.id} control={<Mui.Radio />} label="Never" />
												<Mui.FormControlLabel
													value={SearchPagePromptType.IMMEDIATELY.id} control={<Mui.Radio />} label="Immediately" />
												<Mui.FormControlLabel
													value={SearchPagePromptType.AFTER.id} control={<Mui.Radio />} label="After" />
												<Mui.TextField
													value={searchPagePromptVisits}
													onChange={event => this.setState({searchPagePromptVisits: parseInt(event.target.value)})}
													className={classes.pagesField}
													InputProps={{type:"number", inputProps:{min: 0}}}
													variant="outlined" />
												<Mui.Typography className={classes.limitFieldLabel}>visits to search page</Mui.Typography>
											</Mui.RadioGroup>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						}
						<Mui.Grid item>
							<Mui.Grid container direction="row" spacing={2} alignItems="center" >
								<Mui.Grid item xs={3}>
									<Mui.Box fontWeight="fontWeightBold">
										Search Results Prompt User:
									</Mui.Box>
								</Mui.Grid>
								<Mui.Grid item xs={9}>
									<Mui.RadioGroup row={true} 
										onChange={event => this.setState({searchResultsPromptType: SearchResultsPromptType.getById(parseInt(event.target.value))})}
										value={(searchResultsPromptType || SearchResultsPromptType.NEVER).id}>
										<Mui.FormControlLabel 
											value={SearchResultsPromptType.NEVER.id} control={<Mui.Radio />} label="Never" />
										<Mui.FormControlLabel 
											value={SearchResultsPromptType.IMMEDIATELY.id} control={<Mui.Radio />} label="Immediately" />
										<Mui.FormControlLabel 
											value={SearchResultsPromptType.AFTER.id} control={<Mui.Radio />} label="After" />
										<Mui.TextField 
											value={searchResultsPromptVisits}
											onChange={event => this.setState({searchResultsPromptVisits: parseInt(event.target.value)})}
											className={classes.pagesField}
											InputProps={{type: "number", inputProps:{min: 0}}} 
											variant="outlined" />
										<Mui.Typography className={classes.limitFieldLabel}>pages of results</Mui.Typography>
									</Mui.RadioGroup>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="row" spacing={2} alignItems="center">
								<Mui.Grid item xs={3}>
									<Mui.Box fontWeight="fontWeightBold">
										Include Saved Search Page Views in Results Page Limit?
									</Mui.Box>
								</Mui.Grid>
								<Mui.Grid item xs={5}>
									<Mui.RadioGroup row={true} 
										onChange={event => this.setState({hotsheetsInResultsLimit: event.target.value === 'true'})}
										value={hotsheetsInResultsLimit}>
										<Mui.FormControlLabel value={true} control={<Mui.Radio />} label="On" />
										<Mui.FormControlLabel value={false} control={<Mui.Radio />} label="Off" />
									</Mui.RadioGroup>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="row" spacing={2} alignItems="center" >
								<Mui.Grid item xs={3}>
									<Mui.Box fontWeight="fontWeightBold">
										Property Details Prompt User:
									</Mui.Box>
								</Mui.Grid>
								<Mui.Grid item xs={9}>
									<Mui.RadioGroup 
										row={true} 
										onChange={event => this.setState({searchDetailPromptType: SearchDetailPromptType.getById(parseInt(event.target.value))})}
										value={(searchDetailPromptType || SearchDetailPromptType.NEVER).id}>
											<Mui.FormControlLabel 
												value={SearchDetailPromptType.NEVER.id} control={<Mui.Radio />} label="Never" />
											<Mui.FormControlLabel 
												value={SearchDetailPromptType.IMMEDIATELY.id} control={<Mui.Radio />} label="Immediately" />
											<Mui.FormControlLabel 
												value={SearchDetailPromptType.AFTER.id} control={<Mui.Radio />} label="After" />
										<Mui.TextField 
											value={searchDetailPromptVisits}
											onChange={event => this.setState({searchDetailPromptVisits: parseInt(event.target.value)})}
											className={classes.pagesField}
											InputProps={{type: "number", inputProps: {min: 0}}} 
											variant="outlined" />
										<Mui.Typography className={classes.limitFieldLabel}>detail views</Mui.Typography>
									</Mui.RadioGroup>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<h3>Registration Form Text</h3>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Typography>
								Use the fields below to configure the text used on the registration form.
								If left blank, default text will be used. Name and Email will be included
								and required on the form. If you would also like to include and require
								Phone number, enable the "Require Phone Number on Sign-Up Forms" option
								on the General Settings page.
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container>
								<Mui.Grid item xs={6}>
									<Mui.TextField 
										value={registrationFormHeader}
										onChange={(event: any) => this.setState({registrationFormHeader: event.target.value})}
										label="Header Text"
										variant="outlined"
										InputLabelProps={{shrink: true}}
										minRows={4}
										fullWidth={true}
										multiline={true} />
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container>
								<Mui.Grid item xs={6}>
									<Mui.TextField 
										value={registrationFormFooter}
										onChange={(event: any) => this.setState({registrationFormFooter: event.target.value})}
										label="Footer Text"
										variant="outlined"
										InputLabelProps={{shrink: true}}
										minRows={4}
										fullWidth={true}
										multiline={true} />
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
								<Mui.Grid item>
									<Mui.Button
										color="secondary"
										variant="contained"
										disabled={saving}
										onClick={() => this.save()}
										style={{ marginRight: 32 }}
									>
										Save Changes
									</Mui.Button>
									<Mui.Button
										variant="contained"
										onClick={() => this.setState({
											saving, 
											...user
										})}
									>
										Cancel
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
								open={saving && !authLoading && !authError}
								message="Settings saved"
								autoHideDuration={6000}
								onClose={() => this.setState({ saving: false })}
								action={
									<Mui.IconButton 
										size="small" 
										aria-label="close" 
										color="inherit" 
										onClick={() => this.setState({ saving: false })}>
										<Icons.X fontSize="small" />
									</Mui.IconButton>
								}>
									<MuiAlert severity="info">
										<Mui.Typography>Settings saved</Mui.Typography>
									</MuiAlert>
							</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsUserRegistrationPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);