import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		info: {
			marginLeft: 10
		},
		dynamicContentContainer: {
			width: "95%"
		}
	});
};