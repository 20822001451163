import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		root: {
			borderBottom: "1px solid rgba(0, 0, 0, .05)",
			"&:hover": {
				backgroundColor: "rgba(0, 0, 0, .05)",
				"& .showOnHover": {
					visibility: "visible",
				},
			},
			"& .showOnHover": {
				visibility: "hidden",
			},
		},
	});
};
