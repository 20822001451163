import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		root: {
			borderRight: "1px solid rgba(0, 0, 0, .02)",
			cursor: "pointer",
		},
		asc: {
			boxShadow: "inset 0 -3px 0 0 rgba(0, 0, 0, .6)",
		},
		desc: {
			boxShadow: "inset 0 3px 0 0 rgba(0, 0, 0, .6)",
		},
	});
};
