import { MarketReportResource } from "api/resource/market-report";

export const marketReports: MarketReportResource[] = [
	{
		id: 11,
		typeId: 1,
		marketId: 1001,
		inactive: false,
		pageUrl: "http://yahoo.com"
	},
	{
		id: 12,
		typeId: 2,
		marketId: 1001,
		inactive: false,
		pageUrl: "http://yahoo.com"
	},
	{
		id: 13,
		typeId: 3,
		marketId: 1001,
		inactive: false,
		pageUrl: "http://yahoo.com"
	},
	{
		id: 14,
		typeId: 1,
		marketId: 1002,
		inactive: false,
		pageUrl: "http://github.com"
	},
	{
		id: 15,
		typeId: 2,
		marketId: 1002,
		inactive: false,
		pageUrl: "http://github.com"
	},
	{
		id: 16,
		typeId: 3,
		marketId: 1002,
		inactive: false,
		pageUrl: "http://thriftbooks.com"
	},
	{
		id: 17,
		typeId: 1,
		marketId: 1003,
		inactive: false,
		pageUrl: "http://amazon.com"
	},
	{
		id: 18,
		typeId: 2,
		marketId: 1003,
		inactive: false,
		pageUrl: ""
	},
	{
		id: 19,
		typeId: 3,
		marketId: 1003,
		inactive: false,
		pageUrl: "http://amazon.com"
	},
];