import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "../style";
import { Dialog } from "component/shared/dialog";

interface Props extends
	Mui.WithStyles<typeof styles>
{
	deleteLeadDialogIsOpen: boolean;
	hideDeleteLeadDialog: Function;
	deleteLead: Function;

}

const component = (props: Props) => {
	
	const { deleteLeadDialogIsOpen, hideDeleteLeadDialog, deleteLead, classes } = props;
	
	return (

		<Dialog
			open={deleteLeadDialogIsOpen}
			onClose={() => hideDeleteLeadDialog()}
		>
			<Mui.DialogTitle>Delete Contact</Mui.DialogTitle>
			<Mui.DialogContent className={classes.dialogContent}>
				<Mui.DialogContentText>
					Are you sure you want to delete this contact?
				</Mui.DialogContentText>
			</Mui.DialogContent>
			<Mui.DialogActions className={classes.dialogActions}>
				<Mui.Button
					onClick={() => deleteLead()}
					variant="contained"
					color="secondary"
				>
					Yes
				</Mui.Button>
				<Mui.Button onClick={() => hideDeleteLeadDialog()} variant="outlined">
					No
				</Mui.Button>
			</Mui.DialogActions>
		</Dialog>
	);
};

export const DeleteLeadDialog = Mui.withStyles(styles)(component);