import React from "react";
import * as Mui from "@material-ui/core";
import { Logo } from "../logo";
import { IntegrationType } from "type/integration-type";

interface Props {
	integrationType: IntegrationType;
}

class Component extends React.Component<Props> {

	public componentDidMount() {
		const element = document.createElement("script");
		element.src = "https://zapier.com/apps/embed/widget.js?services=ihomefinder&limit=10&html_id=zapier-embed";
		document.body.appendChild(element);
	}

	public render() {
		const { integrationType } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Logo integrationType={integrationType} />
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Typography>
						Send iHomefinder leads to other apps using Zapier. <Mui.Link href="https://kb.ihomefinder.com/s/article/How-to-Set-Up-a-Zapier-Integration" target="_blank">Get started</Mui.Link>
					</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid id="zapier-embed" item />
			</Mui.Grid>
		);
	}

}

export const ZapierNewLeadIntegration = Component;