import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		block: {
			position: "relative",
			"& pre": {
				minHeight: 50, // prevent copy button overflow
			},
		},
		codeBlockCopy : {
			position: "absolute", 
			right: 0, 
			bottom: 0,
			margin: 8,
		},
		codeBlockCopyIcon: {
			marginLeft: 5,
		},
	})
}
