import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { saveIntegration } from "redux/slice/integrations";
import { IntegrationType } from "type/integration-type";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveIntegration,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	integrationType: IntegrationType;
	onClose: Function;
}

class Component extends React.Component<Props> {

	public render() {
		const { integrationType, onClose, saveIntegration } = this.props;
		return (
			<Mui.Button
				variant="contained"
				color="secondary"
				style={{ marginRight: 8 }}
				onClick={() => {
					saveIntegration({ integration: {
						integrationType,
						data: {},
					}})
					onClose();
				}}
			>
				Add {integrationType.name}
			</Mui.Button>
		);
	}

}

export const AddButton = connect(mapStateToProps, mapDispatchToProps)(Component);
