import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		container: {
			height: "100%",
			"& ihf-search .ihf-dropdown-menu.ihf-pt-3.ihf-show": {
				maxHeight: "calc(100vh - 136px) !important",
				overflowY: "scroll",
			},
		},
	});
}