class Type {

	public static PRICE_DECENDING = new Type("pd", "Price (High to Low)");
	public static PRICE_ACENDING = new Type("pa", "Price (Low to High)");
	public static STATUS = new Type("st", "Status");
	public static CITY = new Type("cn", "City");
	public static LISTING_DATE = new Type("ds", "Listing Date");
	public static TYPE_PRICE_DECENING = new Type("lpd", "Type / Price Descending");
	public static LISTING_NUMBER = new Type("ln", "Listing Number");
	
	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return id ? this.values().find(value => value.id === id) : undefined;
	}

}

export { Type as ListingSortType };