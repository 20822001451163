import { CampaignStatResource } from "api/resource/campaign-stat";

export const campaignStats: CampaignStatResource[] = [
	{
		campaignId: 400,
		sents: 2,
		opens: 1,
		clicks: 1,
	},
	{
		campaignId: 100,
		sents: 2,
		opens: 1,
		clicks: 1,
	}
];