import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		closeButton: {
			position: "absolute",
			right: 8,
			top: 8,
			color: "#fff",
		},
	});
};
