import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		title: {
			color: theme.palette.primary.contrastText,
			fontSize: theme.typography.h4.fontSize,
		},
		reactivateButtonLabel: {
			textTransform: "none",
		},
		text: {
			paddingLeft: 5,
		},
		activeChip: {
			color: "white",
			backgroundColor: "#8fa638",
			fontSize: "1rem",
		},
		inactiveChip: {
			color: "black",
			backgroundColor: "#e0e0e0",
			fontSize: "1rem",
		},
		tagChip: {
			margin: 4,
		},
		addTagChip: {
			margin: 4,
			width: 34,
			color: "#616161",
		},
	});
};

