import { IntegrationResource } from "api/resource/integration";

export const integrations: IntegrationResource[] = [
	{
		id: 1,
		integrationTypeId: 1,
		createdOn: "2019-06-10T11:51:03.780-07:00",
	},
	{
		id: 2,
		integrationTypeId: 2,
		createdOn: "2019-06-10T12:51:03.780-07:00",
		data: {
			url: "http://localhost",
		},
	},
	{
		id: 3,
		integrationTypeId: 4,
		createdOn: "2019-06-10T13:51:03.780-07:00",
		data: {
			url: "https://hooks.zapier.com/hooks/standard/3598583/e02a209386614751b5df46c29f2471a1/",
			editUrl: "https://zapier.com/app/editor/41057309",
		},
	},
	{
		id: 4,
		integrationTypeId: 5,
		name: "Lead Aggregation Email",
		createdOn: "2019-06-10T14:51:03.780-07:00",
		data: {
			externalKey: "L61034_NXD@idxhome.com",
		},
	},
	{
		id: 5,
		integrationTypeId: 6,
		createdOn: "2019-06-10T15:51:03.780-07:00",
		data: {
			externalKey: "bcf99724-6299-47d0-9ea0-38cb48f16e23",
		},
	},
	{
		id: 6,
		integrationTypeId: 7,
		createdOn: "2019-06-10T16:51:03.780-07:00",
		data: {
			// accessToken: "8sdfn43rhunsdf843",
		},
	},
	{
		id: 7,
		integrationTypeId: 8,
		createdOn: "2022-06-10T16:51:03.780-07:00",
		name: "Google Login",
		data: {
			GoogleLoginOAuthId: "949616362578-uu2hmitquncqveg99dd479kc6gt6gc0e.apps.googleusercontent.com",
			GoogleLoginClientId: "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"
		},
	},
	{
		id: 8,
		integrationTypeId: 9,
		createdOn: '2023-04-18T15:12:34.600-07:00',
		name: "Wix",
		errored: false,
		data: {
		  wixInstanceId: 'f3ec36e8-c71a-4e3a-a8b5-3c7628ea5463',
		  wixRefreshToken: 'OAUTH2.eyJraWQiOiJkZ0x3cjNRMCIsImFsZyI6IkhTMjU2In0.eyJkYXRhIjoie1wiaWRcIjpcIjAwNTI1MGYyLTYxNjQtNDJkOS1iZWM2LTIxYzZlOTlmNDI5Y1wifSIsImlhdCI6MTY4MjUzMDkxOSwiZXhwIjoxNzQ1NjAyOTE5fQ.d9zp9ghgBJbebRr2lKFbd6KvhwWe5D3yJrtucaaTwm4',
		  wixAppVersion: 'latest',
		  wixSiteId: 'c1b4025e-556a-4b92-b453-fcb90bc6a5cc'
		}
	  }
];