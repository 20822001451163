import React from "react";
import * as Mui from "@material-ui/core";

interface Option {
	id: number,
	label: string,
	help?: string,
}


interface Props {
	onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
	value: number,
	options: Option[],
}

class Component extends React.Component<Props> {
	public render() {
		const { options, value } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={4}>
				<Mui.Grid item>
					<Mui.RadioGroup 
						aria-label="Lead capture strength" 
						onChange={this.props.onChange}
						value={value}>
					{options.map((option) => {
						return (
						<Mui.Grid alignItems="center" spacing={4} container direction="row" key={option.id}>
							<Mui.Grid item xs={4}>
								<Mui.FormControlLabel 
									value={option.id}
									label={<Mui.Box fontWeight="fontWeightBold">{option.label}</Mui.Box>} 
									control={<Mui.Radio />} />
							</Mui.Grid>
							<Mui.Grid item xs={8}><Mui.Box fontSize="small">{option.help}</Mui.Box></Mui.Grid>
						</Mui.Grid>);
					})}
					</Mui.RadioGroup>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const LeadCaptureStrength = Component;