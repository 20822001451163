import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { BulkDeleteDialog } from "./dialogs/bulk-delete-dialog";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { IconButton } from '@material-ui/core';

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
	leadIds: number[];
}

interface State {
	dialogOpen: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			dialogOpen: false,
		};
	}

	private onClose = () => {
		this.setState({ dialogOpen: false });
	}

	 render() {
		const { leadIds } = this.props;
		const { dialogOpen } = this.state;
        return (
			<>
				<BulkDeleteDialog 
					open={dialogOpen} 
					onClose={()=> this.onClose()} 
					leadIds={leadIds}
				/>
				<IconButton size="small" onClick={() => this.setState({dialogOpen:true})}>
					<FeatherIcon>
						<Icons.Trash />
					</FeatherIcon>
				</IconButton>
			</>
		)
	}
}

export const BulkDelete = connect(mapStateToProps, mapDispatchToProps)(Component)