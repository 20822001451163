import { EmailSentResource } from "api/resource/email-sent";

export const emailSents: EmailSentResource[] = [
	{
		id: "1_1_1_1681340305_SFR",
		leadId: 1,
		savedSearchId: 1,
		sentOn: "2019-10-31T14:46:41.723-08:00",
	},
	{
		id: "2_1_1_1681340305_SFR",
		leadId: 1,
		savedSearchId: 1,
		sentOn: "2019-10-30T14:46:41.723-08:00",
	},
	{
		id: "3_1_1_1681340305_SFR",
		leadId: 1,
		marketReportId: 11,
		sentOn: "2019-10-30T14:46:41.723-08:00",
	},
];