import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		container: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			height: "80%",
		},
	});
};
