import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		header: {
			textTransform: "uppercase",
			paddingTop: 20,
			height: 60,
		},
		avatar: {
			marginRight: 15,
		},
		icon: {
			height: 35,
			width: 35,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginRight: 15,
			color: theme.palette.secondary.main,
		},
		row: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			height: 80,
			paddingLeft: 10,
			paddingRight: 10,
		},
		left: {
			borderRight: "1px solid lightgray",
			[theme.breakpoints.only("md")]: {
				borderLeft: "none",
			},
		},
		right: {
			borderLeft: "1px solid lightgray",
			[theme.breakpoints.only("md")]: {
				borderLeft: "none",
			},
		},
	});
};
