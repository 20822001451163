class Type {

	public static AVATAR = new Type("avatar", "Avatar");
	public static BOOKMARKED = new Type("bookmarked", "Bookmarked");
	public static LABEL = new Type("label", "Name");
	public static CREATED_ON = new Type("createdOn", "Created");
	public static LAST_ACTIVE_ON = new Type("lastActiveOn", "Last Active");
	public static STATUS = new Type("status", "Status");
	public static SUBSCRIBED = new Type("subscribed", "Subscriber");
	public static SOURCE = new Type("source", "Source");
	public static RANKING = new Type("ranking", "Rating");
	public static AGENT = new Type("agent", "Agent");
	public static PIPELINE = new Type("pipeline", "Pipeline Stage");
	public static TYPE = new Type("type", "Type");
	public static TAGS = new Type("tags", "Tags");
	public static LAST_LOGIN = new Type("lastLogin", "Last Login");
	public static OFFICE_NAME = new Type("officeName", "Office Name");
	public static ADDRESS = new Type("address", "Address");
	public static CITY = new Type("city", "City");
	public static PRIORITY = new Type("priority", "Priority")
	public static ACTION = new Type("action", "Action");
	

	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public get avatar() {
		return this === Type.AVATAR;
	}

	public get bookmarked() {
		return this === Type.BOOKMARKED;
	}

	public get createdOn() {
		return this === Type.CREATED_ON;
	}

	public get status() {
		return this === Type.STATUS;
	}

	public get subscribed() {
		return this === Type.SUBSCRIBED;
	}

	public get source() {
		return this === Type.SOURCE;
	}

	public get ranking() {
		return this === Type.RANKING;
	}

	public get agent() {
		return this === Type.AGENT;
	}

	public get pipeline() {
		return this === Type.PIPELINE;
	}

	public get type() {
		return this === Type.TYPE;
	}

	public get lastActiveOn() {
		return this === Type.LAST_ACTIVE_ON;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as LeadColumnType };