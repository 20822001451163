class Type {
	public static EMAIL = new Type("Email");
	public static TEXT = new Type("Text");

	private constructor(
		public readonly id: string,
	) {
	}

	public get label() {
		return this.id;
	}

	public get email() {
		return this === Type.EMAIL;
	}

	public get text() {
		return this === Type.TEXT;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as CampaignMessageType };
