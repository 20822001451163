import * as Mui from "@material-ui/core";
import { Dialog } from "component/shared/dialog";
import { Office } from "model/office";
import { styles } from "../style";

interface Props extends Mui.WithStyles<typeof styles> {
	office: Office;
	deleteOfficeDialogIsOpen: boolean;
	hideDeleteOfficeDialog: Function;
	deleteOffice: Function;
}

const component = (props: Props) => {
	const {
		office,
		classes,
		deleteOffice,
		deleteOfficeDialogIsOpen,
		hideDeleteOfficeDialog,
	} = props;

	return (
		<Dialog
			open={deleteOfficeDialogIsOpen}
			onClose={() => hideDeleteOfficeDialog()}
		>
			<Mui.DialogTitle>Delete Contact</Mui.DialogTitle>
			<Mui.DialogContent className={classes.dialogContent}>
				<Mui.DialogContentText>
					{`Are you sure you want to delete the ${office.name} office?`}
				</Mui.DialogContentText>
			</Mui.DialogContent>
			<Mui.DialogActions className={classes.dialogActions}>
				<Mui.Button
					onClick={() => deleteOffice()}
					variant="contained"
					color="secondary"
				>
					Yes
				</Mui.Button>
				<Mui.Button onClick={() => hideDeleteOfficeDialog()} variant="outlined">
					No
				</Mui.Button>
			</Mui.DialogActions>
		</Dialog>
	);
};

export const DeleteOfficeDialog = Mui.withStyles(styles)(component);
