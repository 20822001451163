import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { getActiveLeads } from "redux/selector";
import { CreateCampaign } from "component/page/campaign/create-campaign";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		allLeads: getActiveLeads(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: () => void;
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles> &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

interface State {}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
		}
	}

	public render() {
		const { onClose, open } = this.props;
		
		return (
			<>
				<Dialog open={open} onClose={() => onClose()} scroll="paper" fullWidth={true} maxWidth="md">
					<Mui.DialogTitle>
						<Mui.Grid container direction="column" spacing={1}>
							<Mui.Grid item xs={12}>Add a Campaign</Mui.Grid>
						</Mui.Grid>
					</Mui.DialogTitle>
					<Mui.DialogContent>
						<CreateCampaign onSave={() => onClose()} />	
					</Mui.DialogContent>
				</Dialog>
			</>
		);
	}

}

export const AddCampaign = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);