import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface RadioOption {
	label: string;
	value: any;
}

interface Props extends Mui.WithStyles<typeof styles> {
	default?: boolean;
	label: string;
	onChange: (value: any) => void;
	options: RadioOption[];
	value: any;
}

class Component extends React.Component<Props> {
	public render() {
		const { classes, options } = this.props;
		const value =
			this.props.value !== undefined
				? this.props.value
				: this.props.default || false;
		return (
			<Mui.Grid container direction="row" spacing={2}>
				<Mui.Grid item xs={12}>
					<Mui.Grid container spacing={3}>
						<Mui.Grid item xs={4}>
							<Mui.Typography className={classes.label}>
								{this.props.label}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={8}>
							<Mui.RadioGroup
								row
								value={value}
								onChange={(event) => {
									this.props.onChange(event.target.value);
								}}
							>
								{options.map((option) => {
									return (
										<Mui.FormControlLabel
											key={option.label}
											label={option.label}
											control={<Mui.Radio />}
											value={option.value}
										/>
									);
								})}
							</Mui.RadioGroup>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const MultiRadioField = Mui.withStyles(styles)(Component);
