import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		searchBar: {
			backgroundColor: "white",
			borderRadius: 4,
			padding: "5px 20px",
		},
		filter: {
			textAlign: "right"
		},
	});
};
