class Type {
	
	public static LEFT = new Type("left", "Left");
	public static RIGHT = new Type("right", "Right");
	
	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public get left() {
		return this === Type.LEFT;
	}

	public get right() {
		return this === Type.RIGHT;
	}

	public static get default(): Type {
		return this.LEFT;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find(value => value.id === id);
	}

	public static getByIdOrDefault(id?: string) {
		return this.getById(id) || this.default;
	}
}

export { Type as MapPositionType };