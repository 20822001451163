import { CampaignSubscriptionResource } from "api/resource/campaign-subscription";

export const campaignSubscriptions: CampaignSubscriptionResource[] = [
	{
		id: 7,
		leadId: 1,
		campaignId: 100,
		status: "Active",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 13,
		leadId: 2,
		campaignId: 100,
		status: "Active",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 14,
		leadId: 3,
		campaignId: 100,
		status: "Inactive",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 8,
		leadId: 1,
		campaignId: 400,
		status: "Complete",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 9,
		leadId: 2,
		campaignId: 400,
		status: "Complete",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 10,
		leadId: 3,
		campaignId: 400,
		status: "Complete",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 11,
		leadId: 4,
		campaignId: 400,
		status: "Complete",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 12,
		leadId: 4,
		campaignId: 500,
		status: "Complete",
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	{
		id: 13,
		leadId: 4,
		campaignId: 600,
		status: "Active",
		createdOn: "2022-11-19T14:46:41.723-08:00",
	},
];