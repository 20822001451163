import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";

interface OwnProps {
	note?: React.ReactNode;
}

interface Props extends OwnProps, Mui.WithStyles<typeof styles>, Mui.WithTheme {}

class Component extends React.Component<Props> {

	public render() {
		const { classes, children, note } = this.props;
		return (
			<Mui.Grid container direction="row">
				<Mui.Grid item xs={12} lg={8} xl={6}>
					{children}
				</Mui.Grid>
				<Mui.Grid item xs={12} lg={4} xl={6}>	
					<Mui.Box className={classes.noteContainer}>		
							<>	
								{note &&
									<Mui.Divider orientation="vertical" flexItem className={classes.verticalDivider} />
								}
								{typeof(note) === "string" ? (	
									<Mui.Typography className={classes.note}>
										{note}
									</Mui.Typography>
								) : (
									<>
										{note}
									</>
								)}
							</>
					</Mui.Box>
				</Mui.Grid>					
			</Mui.Grid>
		);
	}
}

export const InputLayout = Mui.withStyles(styles)(
	Mui.withTheme(
		Component
	)
);
