import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		arrow: {
			"&:before": {
				left: "100%",
				top: "50%",
				border: "solid transparent",
				height: 0,
				width: 0,
				borderLeftColor: "#FAFAFA",
				borderWidth: "24px",
				marginTop: "-24px",
				pointerEvents: "none",
			},
			"&:after": {
				left: "100%",
				top: "50%",
				border: "solid transparent",
				height: 0,
				width: 0,
				borderWidth: "18px",
				marginTop: "-18px",
				pointerEvents: "none",
				transition: theme.transitions.create(["background-color", "border"], {
					duration: theme.transitions.duration.short,
				}),
			},	
		},
		incompleted: {
			"&:hover": {
				backgroundColor: theme.palette.grey[300],
			},
			"&:before": {
				position: "absolute"
			},
			"&:after": {
				borderLeftColor: theme.palette.grey[300],
				position: "absolute"
			}
		},
		completed: {
			"&:hover": {
				backgroundColor: theme.palette.secondary.main,
			},
			"&:before": {
				position: "absolute"
			},
			"&:after": {
				borderLeftColor: theme.palette.secondary.main,
				position: "absolute"
			}
		},
		dead: {
			backgroundColor: "#2b2b2b",
			color: "white",
			"&:hover": {
				backgroundColor: "#2b2b2b",
			},
			"&:before": {
				position: "absolute"
			},
			"&:after": {
				borderLeftColor: "#2b2b2b",
				position: "absolute"
			}
		},
		lineThrough: {
			textDecoration: "line-through"
		},
		pipelineButton: {
			paddingLeft: 30,
			cursor: "pointer",
			fontSize: "1rem",
			border: "none",
			textAlign: "left",
			textTransform: "none",
			outline: "none",
			borderRadius: 0,
			marginBottom: 10,
			boxShadow: "none",
			"&:focus": {
				boxShadow: "none",
			}
		},
		pipelineStart: {
			paddingLeft: 15,
			borderRadius: "20px 0px 0px 20px",
			"&:before": {
				position: "absolute",
			},
			"&:after": {
				position: "absolute",
			}
		},
		pipelineEnd: {
			paddingRight: "13px",
			borderRadius: "0px 20px 20px 0px",
			marginRight: 10,
		},
		closedButton: { 
			borderRadius: 10,
			textTransform: "none",
			fontSize: "1rem",
			marginBottom: 10,
			boxShadow: "none",
			"&:focus": {
				boxShadow: "none",
			}
		},
		fab: {
			width:260,
			height: 35
		},
		fabLabel: {
			textTransform: "uppercase", 
			fontWeight: "bold",
		},
		itemButton: {
			textTransform: "none",
			justifyContent:"left",
			borderRadius: 0,
			paddingLeft: 10,
			width: "100%",
			"&:hover": {
				background: "none",
			},
		},
		itemCompleted: {
			backgroundColor: theme.palette.secondary.main,
			color: "white",
			"&:hover": {
				background: theme.palette.secondary.main,
				color: "white",
			},
		},
		popover: {
			width: 200,
		},
		popoverText: {
			padding: 7,
		}
	});
};