class Type {

	public static CLIENT = new Type("client", "Account/Team Owner");
	public static AGENT = new Type("agent", "Team Member");
	
	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public get client() {
		return this === Type.CLIENT;
	}

	public get agent() {
		return this === Type.AGENT;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as UserType };
