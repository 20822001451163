import { SavedListingResource } from "api/resource/saved-listing";
import { listings } from "./listings";

export const savedListings: SavedListingResource[] = [
	{
		id: 1,
		leadId: 1,
		rating: 5,
		comments: "Nice living room, garden. Can fit guest room. Close to freeway; far from elementary schools. 10 minute commute for Jim, 22 min Barbara.",
		createdOn: "2018-11-13T14:46:41.723-08:00",
		deleted: false,
		listing: listings[0],
	},
	{
		id: 2,
		leadId: 1,
		rating: 0,
		comments: "Nice living room, garden. Can fit guest room. Close to freeway; far from elementary schools. 10 minute commute for Jim, 22 min Barbara.",
		createdOn: "2018-11-14T14:46:41.723-08:00",
		deleted: false,
		listing: listings[1],
	},
	{
		id: 3,
		leadId: 1,
		rating: 3,
		createdOn: "2018-11-12T14:46:41.723-08:00",
		deleted: false,
		listing: listings[2],
	},
];
