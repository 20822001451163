import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { Table } from "component/shared/table";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { formatPrice } from "shared/price-formatter";
import { MarketReportSubscription } from "model/market-report-subscription";
import { CriterionComponent } from "component/shared/criterion-component";
import { updateMarketReportSubscription } from "redux/slice/market-report-subscriptions"
import { Dialog } from "component/shared/dialog";
import moment from "moment";
import { dateFormatter } from "shared/date-formatter";

const mapStateToProps = (state: RootState) => {
	return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarketReportSubscription,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> 
{
	subscription: MarketReportSubscription;
	index: number; 
}

interface State {
	showConfirmDialog: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			showConfirmDialog: false,
		};
	}

	activateSubscription = (value: boolean) => {
		let subscription = {
			...this.props.subscription,
			inactive: !value,
		};
		this.props.updateMarketReportSubscription({subscription});
	};

	renderConfirmationDialog = () => {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.state.showConfirmDialog}
				onClose={() => this.setConfirmDialog(false)}
			>
				<Mui.DialogTitle>Remove Contact</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<p>
						Are you sure you want to remove this contact from receiving the MarketBoost
						Report?
					</p>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						variant="contained"
						color="secondary"
						onClick={() => {
							this.setConfirmDialog(false);
							this.activateSubscription(false);
						}}
					>
						Yes
					</Mui.Button>
					<Mui.Button variant="outlined" onClick={() => this.setConfirmDialog(false)}>
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	}

	setConfirmDialog = (showConfirmDialog: boolean) => {
		this.setState({ showConfirmDialog });
	};

	render() {
		const { subscription } = this.props;
		const monthAgo = moment().subtract(1, "M");
		const sentCount = subscription.emailSents.filter(emailSent => emailSent.sentOn > monthAgo).length;
		return (
			<>
				{this.renderConfirmationDialog()}
				<Mui.ExpansionPanel>
					<Mui.ExpansionPanelSummary
						expandIcon={
							<FeatherIcon>
								<Icons.ChevronDown />
							</FeatherIcon>
						}
					>
						<Mui.Grid container justifyContent="space-between">
							<Mui.Grid item>
								<Mui.Typography variant="h4">
									{subscription.report.market && subscription.report.market.name} -{" "}
									{subscription.report.market && subscription.report.type.label}
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Chip
									label={subscription.inactive ? "Inactive" : "Active"}
									size="small"
									variant="outlined"
									color={subscription.inactive ? "default" : "secondary"}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.ExpansionPanelSummary>
					<Mui.ExpansionPanelDetails>
						<Mui.Grid container direction="column" spacing={2}>
							<Mui.Grid item>
								<Mui.Typography align="right">
								{sentCount} {sentCount === 1 ? "email" : "emails"} sent in the past 30 days
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								{subscription.report.market && subscription.report.market.criteria && (
									<CriterionComponent criteria={subscription.report.market.criteria} />
								)}
							</Mui.Grid>
							<Mui.Grid item>
								<Table
									className="-highlight"
									items={subscription.emailClicks}
									columns={[
										{
											id: "clickedOn",
											title: "Date Clicked",
											width: 165,
											value: (emailClick) => {
												return (
													<Mui.Typography>
														{dateFormatter(emailClick.clickedOn)}
													</Mui.Typography>
												);
											},
											style: {
												alignItems: "flex-start",
											},
										},
										{
											id: "listingAddress",
											title: "Opened Listing",
											value: (emailClick) => {
												return (
													<Mui.Typography>
														{emailClick.listing ? (
															<Mui.Link href={emailClick.listing.detailUrl} target="_blank">
																{emailClick.listing.addressOneLine}
															</Mui.Link>
														) : (
															"--"
														)}
													</Mui.Typography>
												);
											},
										},
										{
											id: "listingPrice",
											title: "Listing Price",
											width: 110,
											value: (emailClick) => {
												return (
													<Mui.Typography>
														{emailClick.listing ? (
															formatPrice(emailClick.listing.price)
														) : (
															"--"
														)}
													</Mui.Typography>
												);
											},
										},
									]}
									style={{
										width: "100%",
									}}
									minRows={0}
									showPagination={subscription.emailClicks.length > 5 ? true : false }
									defaultPageSize={5}
									NoDataComponent={() => (
										<Mui.Typography>No emails clicked yet.</Mui.Typography>
									)}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.ExpansionPanelDetails>
					<Mui.ExpansionPanelActions>
						<Mui.Button
							variant="contained"
							size="small"
							color="secondary"
							onClick={() => {
								subscription.inactive
									? this.activateSubscription(true)
									: this.setConfirmDialog(true);
							}}
						>
							{subscription.inactive ? "Activate" : "Deactivate"}
						</Mui.Button>
					</Mui.ExpansionPanelActions>
				</Mui.ExpansionPanel>
			</>
		);
	}
};

export const ExpansionTable = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
