class Type {
	public static ANY = new Type(0, "Any");
	public static THREE_MONTH = new Type(3, "3 months");
	public static SIX_MONTHS = new Type(6, "6 months");
	public static ONE_YEAR = new Type(12, "1 year");

	private constructor(
		public readonly id: 0 | 3 | 6 | 12,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.ANY;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: number) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: number): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as LimitSoldResultsByMonthsType };
