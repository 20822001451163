class Type {

	public static DRIP = new Type(1, "Drip");
	public static BLAST = new Type(2, "Blast");
	public static BIRTHDAY = new Type(3, "Birthday");
	public static TRANSACTION = new Type(4, "Transaction");

	private constructor(
		public readonly id: number,
		public readonly label: string,
	) {
	}

	public get drip() {
		return this === Type.DRIP;
	}

	public get blast() {
		return this === Type.BLAST;
	}

	public get birthday() {
		return this === Type.BIRTHDAY;
	}

	public get transaction() {
		return this === Type.TRANSACTION;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as EmailMarketingType };
