class Type {

	public static PENDING = new Type();
	public static FAILED = new Type();
	public static SUCCESS = new Type();

	public get pending() {
		return this === Type.PENDING;
	}

	public get failed() {
		return this === Type.FAILED;
	}

	public get success() {
		return this === Type.SUCCESS;
	}

}

export { Type as CampaignEmailStatus };
