import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		topMenuItem: {
			textTransform: "none",
			fontWeight: 300,
			marginRight: theme.spacing(1),
		},
		label: {
			marginLeft: theme.spacing(1),
			whiteSpace: "nowrap",
		},
	});
};
