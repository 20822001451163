import { CampaignCampaignMessageStatResource } from "api/resource/campaign-campaign-message-stat";

export const campaignCampaignMessageStats: CampaignCampaignMessageStatResource[] = [
	{
		campaignCampaignMessageId: 1,
		sents: 1,
		opens: 1,
		clicks: 2,
	},
	{
		campaignCampaignMessageId: 2,
		sents: 1,
		opens: 0,
		clicks: 0,
	},
	{
		campaignCampaignMessageId: 3,
		sents: 0,
		opens: 0,
		clicks: 0,
	},
	{
		campaignCampaignMessageId: 4,
		sents: 0,
		opens: 0,
		clicks: 0,
	},
	{
		campaignCampaignMessageId: 5,
		sents: 0,
		opens: 0,
		clicks: 0,
	}
];