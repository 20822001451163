import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		chipContainer: {
			marginTop: 10,
		},
		chip: {
			margin: theme.spacing(0.5),
			fontSize: "1rem",
		}

	});
};
