import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		cardHeader: {
			padding: "10px 15px 0px",
		},
		cardSpacing: {
			marginBottom: "16px",
		},
		container: { 
			marginTop: 20,
			padding: "20px 10px 10px 20px" 
		},
		addNewButton: {
			display: "block",
			marginTop: 20,
			marginLeft: "auto",
			marginRight: 20,
		},
		button: {
			textTransform: "none" 
		},
		title: {
			fontSize: 22,
			fontWeight: "bold",
		},
		bold: {
			fontWeight: "bold",
		},
		smallText: {
			fontSize: "0.75em",
		},
		footer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
		},
		dialogContent: {
			[theme.breakpoints.down("xs")]: {
				display: "flex",
				alignItems: "flex-end",
				textAlign: "center",
			},
		},
		dialogActions: {
			[theme.breakpoints.down("xs")]: {
				height: "50%",
				justifyContent: "center",
				alignItems: "flex-start",
				paddingLeft: 30,
				paddingRight: 30,
				"& button": {
					flex: 1,
				}
			},
		},

	});
};
