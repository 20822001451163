import React from "react";
import * as Mui from "@material-ui/core";
import { Logo } from "../logo";
import { IntegrationType } from "type/integration-type";
import { AddButton } from "../add-button";

interface Props {
	integrationType: IntegrationType;
	onClose: Function;
}

class Component extends React.Component<Props> {

	public render() {
		const { integrationType, onClose } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Logo integrationType={integrationType} />
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Typography>
						Zillow is the leading real estate and rental marketplace dedicated to empowering consumers with data, inspiration and knowledge around the place they call home, and connecting them with the best local professionals who can help.
					</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Typography>
						Premier Agent allows you to connect your Zillow and Trulia leads in one place. Log into the <Mui.Link href="https://premieragent.zillow.com/crm/agentlogin/" target="_blank\">Premier Agent</Mui.Link> with your Zillow agent credentials.
					</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<AddButton integrationType={integrationType} onClose={onClose} />
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const ZillowTechConnectIntegration = Component;