class Type {

	public static NEVER = new Type(0);
	public static IMMEDIATELY = new Type(1);
	public static AFTER = new Type(2);

	private constructor(
		public readonly id: number
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static valuesSorted(): Type[] {
		return this.values().sort((a,b) => b.id - a.id);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id)
	}

}

export { Type as SearchDetailPromptType };
