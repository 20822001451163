import React from "react";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { CustomLabelRadioField } from "component/shared/custom-label-radio-field";
import { CssHtmlTextField } from "component/shared/css-html-text-field";
import { DashboardLayout } from "component/layout/dashboard";
import { FullPageCard } from "component/shared/full-page-card";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { User } from "model/user";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		WithMediaQuery {
	user: User;
}

interface State {
	brandingType: "hostedBranding" | "brandingUrl";
	brandingUrl: string;
	defaultFooterHtml: string;
	defaultHeaderHtml: string;
	saving: boolean;
}

const mapStateToProps = (state: RootState) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			...props.user,
			brandingType: props.user.brandingUrl ? "brandingUrl" : "hostedBranding",
			saving: false,
		};
	}

	public save() {
		const { saveUser } = this.props;
		const { brandingType, brandingUrl, defaultFooterHtml, defaultHeaderHtml } =
			this.state;
		const user = {
			...this.state,
			brandingUrl: brandingType === "brandingUrl" ? brandingUrl : "",
			defaultFooterHtml:
				brandingType === "hostedBranding"
					? defaultFooterHtml
					: this.props.user.defaultFooterHtml,
			defaultHeaderHtml:
				brandingType === "hostedBranding"
					? defaultHeaderHtml
					: this.props.user.defaultHeaderHtml,
		};
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const { authError, authLoading, mediaQuery, user } = this.props;
		const {
			brandingType,
			brandingUrl,
			defaultFooterHtml,
			defaultHeaderHtml,
			saving,
		} = this.state;
		const isMobile = mediaQuery;
		const title = "Branding Setup";
		return (
			<DashboardLayout
				permitted={user && user.permissions.standalone}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						<Mui.Grid item xs>
							<CustomLabelRadioField
								onChange={(value) => {
									this.setState({
										brandingType: value,
									});
								}}
								options={[
									{
										label: "Hosted Branding",
										value: "hostedBranding",
									},
									{
										label: "Branding URL",
										value: "brandingUrl",
									},
								]}
								value={brandingType}
							/>
						</Mui.Grid>
						{brandingType === "hostedBranding" && (
							<Mui.Grid item xs>
								<Mui.Typography>
									Add the header and footer HTML branding that will be applied to every
									IDX page of your site. All links, scripts, and images must have
									absolute URLs
									{!isMobile && " (e.g. http://www.example.com/images/banner.jpg)"}.
								</Mui.Typography>
							</Mui.Grid>
						)}
						{brandingType === "brandingUrl" && (
							<Mui.Grid item xs>
								<Mui.Typography>
									Add branding to your pages by entering the URL where your branding is
									hosted below. All links, scripts, and images must have absolute URLs
									{!isMobile && " (e.g. http://www.example.com/images/banner.jpg)"}.
								</Mui.Typography>
							</Mui.Grid>
						)}
					</Mui.Grid>
					{brandingType === "hostedBranding" && (
						<>
							<Mui.Grid item xs>
								<CssHtmlTextField
									label="Default Header HTML:"
									onChange={(value) =>
										this.setState({
											defaultHeaderHtml: value,
										})
									}
									value={defaultHeaderHtml}
								/>
							</Mui.Grid>
							<Mui.Grid item xs>
								<CssHtmlTextField
									label="Default Footer HTML:"
									onChange={(value) =>
										this.setState({
											defaultFooterHtml: value,
										})
									}
									value={defaultFooterHtml}
								/>
							</Mui.Grid>
						</>
					)}
					{brandingType === "brandingUrl" && (
						<Mui.Grid item xs>
							<Mui.TextField
								fullWidth
								label="URL"
								onChange={(event) =>
									this.setState({
										brandingUrl: event.target.value,
									})
								}
								value={brandingUrl}
								variant="outlined"
							/>
						</Mui.Grid>
					)}
					<Mui.Grid container direction="row" spacing={1} style={{ marginTop: 24 }}>
						<Mui.Grid item xs={isMobile}>
							<Mui.Button
								color="secondary"
								disabled={saving}
								fullWidth={isMobile}
								onClick={() => this.save()}
								style={{ marginRight: 32 }}
								variant="contained"
							>
								Save{!isMobile && " Changes"}
							</Mui.Button>
						</Mui.Grid>
						<Mui.Grid item xs={isMobile}>
							<Mui.Button
								fullWidth={isMobile}
								onClick={() =>
									this.setState({
										saving: this.state.saving,
									})
								}
								variant="contained"
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
						open={saving && !authLoading && !authError}
						message="Settings saved"
						autoHideDuration={6000}
						onClose={() => this.setState({ saving: false })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ saving: false })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>Settings saved</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsBrandingPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(withMediaQuery("(max-width:600px)")(Component));
