import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		icon: {
			padding: 3,
			right: 5,
		},
		selectMenu: {
			height: 24,
			marginTop: -5,
		},
		text: {
			marginLeft: 10,
			marginRight: 6,
		},
		checkbox: {
			marginRight: 15,
		},
		listItem: {
			paddingLeft: 15,
		},
		menuItemSelected: {
			backgroundColor: "unset !important",
		},
	});
};