import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		form: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			width: "100%",
		},
		textField: {
			marginBottom: 16,
		},
		alignVertical: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		login: {
			width: 224,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: 20,
		},
		forgotLink: {
			alignSelf: "flex-end",
		},
		createAccountContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			paddingTop: 32,
		},
		error: {
			color: theme.palette.error.main,
		}
	});
};
