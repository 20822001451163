import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import * as yup from "yup";
import { validate } from "shared/yup";
import { WidgetConfigLayout } from "../widget-config-layout";
import { getUser } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface State {
	redirectUrl: string,
	buttonText: string,
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props) 
		this.state = {
			redirectUrl: "",
			buttonText: "",
		}
	}

	private validationSchema = {
		redirectUrl: yup
			.string()
			.url("Full URL (http://example.com) is required.")
			.required("Full URL (http://example.com) is required."),
		buttonText: yup
			.string()
			.required("Button Text is required.")
	};

	public render() {
		const { user } = this.props;
		const { redirectUrl, buttonText} = this.state;
		const errors = validate(this.validationSchema, this.state);
		const embedCode = kestrelEmbedCode.registrationFormWidget(
			user.websitePlatform,
			redirectUrl,
			buttonText,
		);
		return (
			<WidgetConfigLayout user={user} embedCode={!errors ? embedCode : undefined}>
				<Mui.Grid container spacing={2} direction="row">
					<Mui.Grid item xs={12}>
						<Mui.Typography variant="h4">
							Widget Options
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								fullWidth
								variant="outlined"
								required
								value={redirectUrl}
								onChange={(event) => this.setState({redirectUrl: event.target.value})}
								label="Redirect URL"
								helperText={(redirectUrl && errors?.redirectUrl) && errors.redirectUrl}
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								fullWidth
								variant="outlined"
								required
								value={buttonText}
								onChange={(event) => this.setState({buttonText: event.target.value})}
								label="Button Text"
							/>
						</Mui.FormControl>
					</Mui.Grid>
				</Mui.Grid>
			</WidgetConfigLayout>
		);
	}
}

export const RegistrationFormConfig = connect(mapStateToProps, mapDispatchToProps)(Component);