import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { styles } from "../../style";
import { Dialog } from "component/shared/dialog";
import { updateLead } from "redux/slice/leads";
import { getLeadsById } from "redux/selector";
import { LeadStatus } from "type/lead-status";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		leads: getLeadsById(state, ownProps.leadIds),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	open: boolean, 
	onClose: Function,
	leadIds: number[],
}

interface State {
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	private deleteLeads() {
		const { leads, updateLead } = this.props;
		leads.forEach((lead) => {
			const leadToDelete= {
				...lead,
				status: LeadStatus.DELETED,
			}
			updateLead({lead: leadToDelete});
		});
		this.onClose();
	}

	private onClose() {
		this.props.onClose();
	}

    render() {
		const { open, leadIds, classes } = this.props;
		
        return (
			<Dialog
				open={open}
				onClose={() => this.onClose()}
				fullWidth={true}
				maxWidth="xs" 
			>
				<Mui.DialogTitle>Delete Contacts</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.DialogContentText className={classes.dialogText}>
						You have selected {leadIds.length} records to delete. Are you sure you want to delete these contacts?
					</Mui.DialogContentText>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => this.deleteLeads()}
						variant="contained"
						color="secondary"
					>
						Yes
					</Mui.Button>
					<Mui.Button 
						onClick={() => this.onClose()} 
						variant="outlined"
					>
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
	)
	}
}

export const BulkDeleteDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);