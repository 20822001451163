import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { saveIntegration } from "redux/slice/integrations";
import { Integration } from "model/integration";
import { Card } from "../card";
import { styles } from "./style";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveIntegration,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	integration: Integration;
}

interface State {
	dashboard: string,
	editor: string
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			dashboard: `https://manage.wix.com/dashboard/${props.integration.data.wixSiteId}/home`,
			editor: `https://manage.wix.com/editor/${props.integration.data.wixSiteId}/`,
		};
	}

	public render() {
		const { integration, classes } = this.props;
		const { dashboard, editor } = this.state;

		return (
			<Card
				integration={integration}
				deletable={true}
				content={
					<Mui.Grid container direction="column" spacing={5} alignItems="center">
						<Mui.Grid item>
							{integration.integrationType.logoUrl && (
								<img
									className={classes.icon}
									src={integration.integrationType.logoUrl}
									alt="Wix logo"
								/>
								)}
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="row" spacing={10}>
								<Mui.Grid item>
									<Mui.Link
										className={classes.link}
										href={dashboard}
										target="_blank"
									>
										View Dashboard
									</Mui.Link>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Link
										className={classes.link}
										href={editor}
										target="_blank"
										>
										View Editor
									</Mui.Link>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				}
			/>
		);
	}
	
}

export const WixIntegration = Mui.withStyles(styles)(
	connect(
		mapStateToProps, 
		mapDispatchToProps,
	)(Component)
);