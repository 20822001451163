class Type {
	public static ON_SEPARATE_PAGE = new Type(false, "On separate page");
	public static ON_FEATURED_LISTINGS_PAGE = new Type(true, "On Featured Listings Page");

	private constructor(
		public readonly id: boolean,
		public readonly label: string
	) {}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: boolean) {
		return this.values().find((value) => value.id === id);
	}
}

export { Type as SoldsOnFeaturedType };
