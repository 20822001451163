import React from "react";

class Component extends React.Component {

	public render() {
		return (
			// Relies on classes created in index.html
			<div className="app-loading">
				<div className="spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}

}

export const LoadingPage = Component;