import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		title: {
			display: "flex", 
			alignItems: "center"
		},
		pageAlert: {
			color: "#ff0000", 
			marginLeft: 10, 
			height: 21,
			width: 21,
		},
		pageOk: {
			color: "#00bb00", 
			marginLeft: 10, 
			height: 21,
			width: 21,
		}
	})
}
