import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { getIntegrations } from "redux/selector";
import { Dialog } from "component/shared/dialog";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { IntegrationType } from "type/integration-type";
import { User } from "model/user";
import { TopProducerLtsIntegration } from "./top-producer-lts";
import { CustomLtsIntegration } from "./custom-lts";
import { ZapierNewLeadIntegration } from "./zapier-new-lead";
import { LeadAggregationIntegration } from "./lead-aggregation";
import { ZillowTechConnectIntegration } from "./zillow-tech-connect";
import { EmailCalendarIntegration } from "./email-calendar";
import { GoogleLoginIntegration } from "./google-login";
import { WixIntegration } from "./wix-app";

const mapStateToProps = (state: RootState) => {
	return {
		integrations: getIntegrations(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	open: boolean;
	onClose: Function;
	user: User;
}

class Component extends React.Component<Props, any> {

	public render() {
		const { open, onClose, classes, user, integrations } = this.props;
		const integrationTypes = IntegrationType
			.values()
			.filter(integrationType => integrationType.permitted(user))
			.filter(integrationType => {
				const count = integrations.filter(integration => integration.integrationType === integrationType).length;
				return integrationType.limit > count;
			})
			.sort((a, b) => a.name.localeCompare(b.name))
		;

		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>Add an Integration</Mui.DialogTitle>
				<Mui.DialogContent>
					{integrationTypes.map((integrationType) => (
						<Mui.ExpansionPanel key={integrationType.id}>
							<Mui.ExpansionPanelSummary
								expandIcon={
									<FeatherIcon>
										<Icons.ChevronDown />
									</FeatherIcon>
								}
							>
								{integrationType.iconUrl && (
									<Mui.CardMedia className={classes.icon} image={integrationType.iconUrl} />
								)}
								<Mui.Typography>{integrationType.name}</Mui.Typography>
							</Mui.ExpansionPanelSummary>
							<Mui.ExpansionPanelDetails>
								{integrationType.topProducerLts && (
									<TopProducerLtsIntegration integrationType={integrationType} onClose={onClose} />
								)}
								{integrationType.customLts && (
									<CustomLtsIntegration integrationType={integrationType} onClose={onClose} />
								)}
								{integrationType.zapierNewLead && (
									<ZapierNewLeadIntegration integrationType={integrationType} />
								)}
								{integrationType.leadAggregation && (
									<LeadAggregationIntegration integrationType={integrationType} onClose={onClose} />
								)}
								{integrationType.zillowTechConnect && (
									<ZillowTechConnectIntegration integrationType={integrationType} onClose={onClose} />
								)}
								{integrationType.emailCalendar && (
									<EmailCalendarIntegration integrationType={integrationType} onClose={onClose} />
								)}
								{integrationType.googleLogin && (
									<GoogleLoginIntegration integrationType={integrationType} onClose={onClose} />
								)}
								{integrationType.wixApp && (
									<WixIntegration integrationType={integrationType} onClose={onClose}/>
								)}
							</Mui.ExpansionPanelDetails>
						</Mui.ExpansionPanel>
					))}
				</Mui.DialogContent>
			</Dialog>
		);
	}
}

export const AddDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);
