import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		label: {
			textTransform: "uppercase",
			fontSize: 13,
			fontWeight: "bold",
		},
		text: {
			paddingLeft: 5,
		},
		tagChip: {
			margin: 2,
		},
		autoSuggestSelect: {
			"Select__menu-list": {
				maxHeight: 12
			}
		},
		dividerTop: {
			margin: 5,
		},
		dividerBottom: {
			margin: 120,
		},
		alignItemTop: {
			"align-self": "flex-start",
		}
	});
};

