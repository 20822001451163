import { KestrelVersion } from 'type/kestrel-version';

class Type {

	public static STANDARD = new Type("standard", "Standard", 0);
	public static PHOTO_FIRST = new Type("photofirst", "Photo First", 1);

	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly displayOrder: number,
	) {
	}

	public static get default(): Type {
		return this.STANDARD;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static valuesSorted(): Type[] {
		return this.values().sort((a,b) => a.displayOrder - b.displayOrder);
	}

	public static valuesByKestrelVersion(version: KestrelVersion | undefined): Type[] {
		return Object.values(Type)
			.filter(layout => layout.kestrelVersion === version)
			.sort((a,b) => a.displayOrder - b.displayOrder);
	}

	public static getById(id?: string) {
		return this.values().find(value => value.id === id);
	}

	public static getByIdOrDefault(id?: string): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as ListingDetailPageLayoutType };
