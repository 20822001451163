import { LeadPipelineResource } from "api/resource/lead-pipeline";

export const leadPipelines : LeadPipelineResource[] = [
	{
		id: 1,
		leadId: 1,
		typeId: 2,
		createdOn: "2018-11-13T12:46:41.723-08:00",
	},
	{
		id: 4,
		leadId: 3,
		typeId: 5,
		createdOn: "2019-10-03T12:00:00.000-08:00",
	},
	{
		id: 2,
		leadId: 4,
		typeId: 1,
		createdOn: "2019-10-02T12:00:00.000-08:00",
	},
	{
		id: 3,
		leadId: 5,
		typeId: 2,
		createdOn: "2019-10-02T12:00:00.000-08:00",
	},
	{
		id: 5,
		leadId: 6,
		typeId: 6,
		createdOn: "2018-10-30T12:00:00.000-08:00",
	},
	{
		id: 6,
		leadId: 7,
		typeId: 6,
		createdOn: "2018-11-01T12:00:00.000-08:00",
	},
	/*{
		id: 7,
		leadId: 8,
		typeId: 6,
		createdOn: "2018-11-01T12:00:00.000-08:00",
	}*/
]