import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		actionsIcon: {
			marginLeft: 5,
		},
	});