import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { FullWidthDropdown } from "component/shared/full-width-dropdown";
import MuiAlert from "@material-ui/lab/Alert";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { styles } from "./style";
import { connect } from "react-redux";
import { saveUser } from "redux/slice/authentication";
import { User } from "model/user";
import { canDisplayExternalLinksOnPropResultsAndDetails } from "redux/selector";
import { canShowPhotoLayoutOptions } from "redux/selector";
import { canShowSupplementalOnSeparatePage } from "redux/selector";
import { LeadCaptureSidebarPositionType } from "type/lead-capture-sidebar-position";
import { ListingDetailPageLayoutType } from "type/listing-detail-page-layout";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
		canDisplayExternalLinksOnPropResultsAndDetails:
			canDisplayExternalLinksOnPropResultsAndDetails(state),
		canShowPhotoLayoutOptions: canShowPhotoLayoutOptions(state),
		canShowSupplementalOnSeparatePage: canShowSupplementalOnSeparatePage(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

interface State {
	saving: boolean;
	settingShowDownPaymentAssistanceLinks: boolean;
	settingListingDetailPageStyle: ListingDetailPageLayoutType;
	settingShowScheduleShowing: boolean;
	settingShowMapLink: boolean;
	settingShowMortgageCalculator: boolean;
	settingShowWalkScore: boolean;
	settingShowSchools: boolean;
	listingDetailSidebarPosition: LeadCaptureSidebarPositionType;
	listingDetailStickyContent: boolean;
	listingDetailContiguous: boolean;
	listingDetailSimilarListings: boolean;
	listingDetailCarouselThumbnails: boolean;
	listingDetailCarouselCenterMode: boolean;
	listingDetailEmbedVirtualTour: boolean;
}

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {
		user: User;
}

interface OwnProps {
	user: User;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		const settingShowDownPaymentAssistanceLinks =
			props.user.settingShowDownPaymentAssistanceLinks ||
			props.user.settingShowDownPaymentAssistanceLinks === undefined;
		this.state = {
			...props.user,
			saving: false,
			settingShowDownPaymentAssistanceLinks,
			listingDetailSidebarPosition:
				props.user.listingDetailSidebarPosition ||
				LeadCaptureSidebarPositionType.SIDEBAR_RIGHT,
			settingListingDetailPageStyle:
				props.user.settingListingDetailPageStyle ||
				ListingDetailPageLayoutType.STANDARD,
		};
	}

	public save() {
		const { saveUser } = this.props;
		const user = { ...this.state };
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const {
			user,
			authLoading,
			authError,
			canDisplayExternalLinksOnPropResultsAndDetails,
			canShowPhotoLayoutOptions,
			canShowSupplementalOnSeparatePage,
		} = this.props;
		const {
			saving,
			settingListingDetailPageStyle,
			settingShowScheduleShowing,
			settingShowDownPaymentAssistanceLinks,
			settingShowMapLink,
			settingShowMortgageCalculator,
			settingShowSchools,
			settingShowWalkScore,
			listingDetailStickyContent,
			listingDetailSidebarPosition,
			listingDetailCarouselThumbnails,
			listingDetailCarouselCenterMode,
			listingDetailEmbedVirtualTour,
			listingDetailContiguous,
			listingDetailSimilarListings,
		} = this.state;
		const title = "Property Detail Settings";
		return (
			<DashboardLayout
				permitted={user && user.permissions.listingDetail}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						{(user.kestrelVersion.detail ||	user.kestrelVersion.all) && (
							<>
								<Mui.Grid item>
									<YesNoRadioField
										label="Sticky lead capture"
										onOff={true}
										value={listingDetailStickyContent}
										default={true}
										sidebarNote="Turn on to fix the lead capture options to the top of the browser window. Turn off to scroll past them."
										onChange={(value) =>
											this.setState({ listingDetailStickyContent: value })
										}
									/>
								</Mui.Grid>
								<Mui.Grid item>
									<FullWidthDropdown
										label="Lead Capture Sidebar Placement"
										value={listingDetailSidebarPosition.id}
										sidebarNote="We recommend turning this sidebar off if you already have a sidebar built into your page outside of the Property Listing content."
										onChange={(value) =>
											this.setState({
												listingDetailSidebarPosition:
													LeadCaptureSidebarPositionType.getByIdOrDefault(value),
											})
										}
									>
										{LeadCaptureSidebarPositionType.valuesSorted().map((value) => (
											<Mui.MenuItem key={value.id} value={value.id}>
												{value.label}
											</Mui.MenuItem>
										))}
									</FullWidthDropdown>
								</Mui.Grid>
								<Mui.Grid item>
									<YesNoRadioField
										label="Show thumbnails under Photo Carousel"
										onOff={true}
										value={listingDetailCarouselThumbnails}
										onChange={(value) =>
											this.setState({ listingDetailCarouselThumbnails: value })
										}
									/>
								</Mui.Grid>
								<Mui.Grid item>
									<YesNoRadioField
										label="Photo Carousel Center Mode"
										onOff={true}
										value={listingDetailCarouselCenterMode}
										onChange={(value) =>
											this.setState({ listingDetailCarouselCenterMode: value })
										}
									/>
								</Mui.Grid>
								{user.permissions.embedVirtualTours && (
									<Mui.Grid item>
										<YesNoRadioField
											label="Embed Virtual Tour"
											onOff={true}
											value={listingDetailEmbedVirtualTour}
											onChange={(value) =>
												this.setState({ listingDetailEmbedVirtualTour: value })
											}
										/>
									</Mui.Grid>
								)}
								{canShowSupplementalOnSeparatePage && (
									<Mui.Grid item>
										<YesNoRadioField
											label="Show extra listing content in tabs"
											onOff={true}
											default={false}
											sidebarNote="Turn on to display Map, Mortgage Calculator, Walk Score and Schools information within tabs. Turn off to have extra content display below the listing data."
											value={listingDetailContiguous}
											onChange={(value) =>
												this.setState({ listingDetailContiguous: value })
											}
										/>
									</Mui.Grid>
								)}
								{user.permissions.similarListings && (
									<Mui.Grid item>
										<YesNoRadioField
											label={'Show "Similar Listings" section on Property Detail Pages'}
											onOff={true}
											default={true}
											value={listingDetailSimilarListings}
											onChange={(value) =>
												this.setState({ listingDetailSimilarListings: value })
											}
										/>
									</Mui.Grid>
								)}
							</>
						)}
						{user.kestrelVersion.off && canShowPhotoLayoutOptions && (
							<Mui.Grid item>
								<FullWidthDropdown<string>
									label="Property Listing Page Layout"
									value={settingListingDetailPageStyle.id}
									onChange={(value) =>
										this.setState({
											settingListingDetailPageStyle:
												ListingDetailPageLayoutType.getByIdOrDefault(value),
										})
									}
								>
									{ListingDetailPageLayoutType.valuesSorted().map((value) => (
										<Mui.MenuItem key={value.id} value={value.id}>
											{value.label}
										</Mui.MenuItem>
									))}
								</FullWidthDropdown>
							</Mui.Grid>
						)}
						<Mui.Grid item>
							<YesNoRadioField
								label={'Show "Schedule a Showing" Form on Property Detail Pages'}
								onOff={true}
								value={settingShowScheduleShowing}
								sidebarNote={
									<>
										"Schedule a Showing" form appears with the "Request Information" form.
										If turned off, only the "Request Information" form will show.
									</>
								}
								onChange={(value) =>
									this.setState({ settingShowScheduleShowing: value })
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label={'Show "Map" section on Property Detail Pages'}
								onOff={true}
								value={settingShowMapLink}
								onChange={(value) => this.setState({ settingShowMapLink: value })}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label={'Show "Mortgage Calculator" on Property Detail Pages'}
								onOff={true}
								value={settingShowMortgageCalculator}
								onChange={(value) =>
									this.setState({ settingShowMortgageCalculator: value })
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label={'Show "Walk Score" section on Property Detail Pages'}
								onOff={true}
								value={settingShowWalkScore}
								onChange={(value) => this.setState({ settingShowWalkScore: value })}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label={'Show "School" section on Property Detail Pages'}
								onOff={true}
								value={settingShowSchools}
								onChange={(value) => this.setState({ settingShowSchools: value })}
							/>
						</Mui.Grid>
						{canDisplayExternalLinksOnPropResultsAndDetails && (
							<Mui.Grid item>
								<YesNoRadioField
									label={'Show "Down Payment Assistance" Links When Available'}
									value={settingShowDownPaymentAssistanceLinks}
									onChange={(value) =>
										this.setState({ settingShowDownPaymentAssistanceLinks: value })
									}
								/>
							</Mui.Grid>
						)}
						<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									variant="contained"
									disabled={saving}
									onClick={() => this.save()}
									style={{ marginRight: 32 }}
								>
									Save Changes
								</Mui.Button>
								<Mui.Button
									variant="contained"
									onClick={() =>
										this.setState({
											...user,
											saving,
											settingShowDownPaymentAssistanceLinks:
												this.state.settingShowDownPaymentAssistanceLinks,
											settingListingDetailPageStyle:
												this.state.settingListingDetailPageStyle,
										})
									}
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
						open={saving && !authLoading && !authError}
						message="Settings saved"
						autoHideDuration={6000}
						onClose={() => this.setState({ saving: false })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ saving: false })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>Settings saved</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsListingDetailsPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
