import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		pageTitle: {
			fontSize: 42
		},
		iconBorder:{
			border: "1px solid grey",
			borderRadius: "50%",
			padding: "15px !important",
			height: 95,
			width: 95
		},
		icon: {
			marginTop: 7.5,
			marginLeft: 7.5,
			height: 47,
			width: 47,
		},
		topicTitle: {
			fontWeight: 800
		},
		topicLink:{
			color: "#2A2A2A",
			fontWeight: 600
		},
		topicButton: {
			border: "3px solid #2A2A2A",
			width: 80
		},
	});
