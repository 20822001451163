import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { IntegrationType } from "type/integration-type";
import { getIntegrationByTypeId } from "redux/selector";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { WixLandingPage } from "./wix-landing-page";
import { ControlPanelOverview } from "./cp-overview";

interface Props 
	extends PageProps, 
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps> {
}

const mapStateToProps = (state: RootState) => {
	const wixIntegration = getIntegrationByTypeId(state, IntegrationType.WIX_APP)
	return {
		wixIntegration
	};
};

class Component extends React.Component<Props> {

	public render() {
		const title = "Welcome To Your Control Panel";
		const { user, wixIntegration } = this.props;
		return (
			<DashboardLayout
				fullScreen={false}
				title="Overview"
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				{!wixIntegration ? (
						<ControlPanelOverview user={user}/>
					):(
						<WixLandingPage user={user}/>
					)
				}
			</DashboardLayout>
		);
	}

}

export const SettingsOverviewPage = (connect(mapStateToProps)(Component));