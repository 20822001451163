import React from "react";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import * as yup from "yup";
import { FullPageCard } from "component/shared/full-page-card";
import { FullWidthDropdown } from "component/shared/full-width-dropdown";
import { DashboardLayout } from "component/layout/dashboard";
import { MapSearchMapType } from "type/map-search-map-type";
import { SliderWithNote } from "component/shared/slider-with-note";
import { TextFieldWithNote } from "component/shared/text-field-with-note";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { FeatherIcon } from "component/shared/feather-icon";
import { User } from "model/user";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";
import { PageProps } from "shared/page-props";
import { validate } from "shared/yup";
import { Map } from "./map";
import { MapPositionType } from "type/map-position";
import { styles } from "./style"

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {
	user: User;
}

interface State {
	displayDrawOnMap: boolean;
	eurekaEnabled: boolean;
	eurekaSearchHeight: string;
	mapSearchCenterAddress: string;
	mapSearchMapType: MapSearchMapType;
	saving: boolean;
	mapSearchZoom: number;
	mapPosition: string;
	listingDetailModal: boolean
}

const mapStateToProps = (state: RootState) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			...props.user,
			saving: false,
			eurekaSearchHeight: props.user.eurekaSearchHeight ? props.user.eurekaSearchHeight.toString() : "",
			mapPosition: props.user.mapPosition ? props.user.mapPosition.id : MapPositionType.LEFT.id,
			listingDetailModal: props.user.listingDetailModal,
		};
	}

	private validationSchema = {
		eurekaSearchHeight: yup
			.number()
			.typeError("Value must be a number.")
			.nullable()
			.transform((value, originalValue) => {
				if (originalValue.trim() === "") {
					return null;
				} else {
					return value;
				}
			}),
	};

	public save() {
		const { saveUser } = this.props;
		const { eurekaSearchHeight, mapSearchMapType, mapPosition, listingDetailModal } = this.state;
		const newMapPosition = MapPositionType.getById(mapPosition);
		const user = {
			...this.state,
			eurekaSearchHeight: parseInt(eurekaSearchHeight) || 0,
			mapSearchMapTypeId: mapSearchMapType.id,
			mapPosition: newMapPosition,
			listingDetailModal: listingDetailModal,
		};
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const { authError, authLoading, user, classes } = this.props;
		const {
			displayDrawOnMap,
			eurekaEnabled,
			eurekaSearchHeight,
			mapSearchCenterAddress,
			mapSearchMapType,
			saving,
			mapSearchZoom,
			mapPosition,
			listingDetailModal,
		} = this.state;
		const errors = validate(this.validationSchema, this.state);
		const title = "Map Search Settings";
		return (
			<DashboardLayout
				permitted={
					user &&
					(user.permissions.listingMapSearch ||
						user.permissions.listingPolygonSearch)
				}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item>
							<Mui.Grid container spacing={3}>
								<Mui.Grid item xs>
									<Mui.Grid container direction="column" spacing={3}>
										<Mui.Grid item xs>
											<SliderWithNote
												defaultValue={10}
												label="Zoom Level for Map Search"
												max={20}
												min={1}
												marks={[...Array(20)].map((v, i) => i + 1).map(value => ({
													label: value === 1 ? "far" : value === 20 ? "near" : "",
													value,
												}))}
												onChange={(event, value) =>
													this.setState({
														mapSearchZoom: value as number,
													})
												}
												sidebarNote={false}
												step={1}
												value={mapSearchZoom}
											/>
										</Mui.Grid>
										<Mui.Grid item>
											<TextFieldWithNote
												label="Map Search Center Address"
												onChange={(value: string) => {
													this.setState({
														mapSearchCenterAddress: value,
													});
												}}
												sidebarNote={false}
												value={mapSearchCenterAddress}
												placeholder="Contact address is used by default"
											/>
										</Mui.Grid>
										<Mui.Grid item>
											<FullWidthDropdown<string>
												label="Map Search Type"
												onChange={(value) => {
													this.setState({
														mapSearchMapType:
															MapSearchMapType.getByIdOrDefault(value),
													});
												}}
												sidebarNote={false}
												value={mapSearchMapType.id}
											>
												{MapSearchMapType.values().map((value) => (
													<Mui.MenuItem key={value.id} value={value.id}>
														{value.label}
													</Mui.MenuItem>
												))}
											</FullWidthDropdown>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item xs>
									<Map
										zoom={mapSearchZoom}
										address={mapSearchCenterAddress}
										mapType={mapSearchMapType}
									/>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						{!user.kestrelVersion.all && (
							<Mui.Grid item>
								<YesNoRadioField
									label='Display "Draw on Map" option on search forms'
									onChange={(value) => {
										this.setState({
											displayDrawOnMap: value,
										});
									}}
									value={displayDrawOnMap}
								/>
							</Mui.Grid>
						)}
						{user.permissions.eurekaSearch && !user.kestrelVersion.all && (
							<Mui.Grid item>
								<Mui.Grid container spacing={2} alignItems="center">
									<Mui.Grid item>
										<Mui.FormLabel id="map-position" className={classes.formLabel}>
											Enable Eureka Search
										</Mui.FormLabel>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.FormControl>
											<Mui.RadioGroup 
												name="map-position"
												row={true} 
												value={this.state.eurekaEnabled} 
												onChange={(event) => this.setState({eurekaEnabled: event.target.value === "true"})}
											>
												<Mui.FormControlLabel label="Yes" control={<Mui.Radio />} value={true} />
												<Mui.FormControlLabel label="No" control={<Mui.Radio />} value={false} />
											</Mui.RadioGroup>
										</Mui.FormControl>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
						{user.permissions.eurekaSearch && user.kestrelVersion.all && 
							<Mui.Grid item>
								<Mui.Grid container spacing={2} alignItems="center">
									<Mui.Grid item>
										<Mui.FormLabel id="map-position" className={classes.formLabel}>
											Map Position
										</Mui.FormLabel>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.FormControl>
											<Mui.RadioGroup
												name="map-position"
												row={true} 
												value={mapPosition} 
												onChange={(event) => this.setState({mapPosition: event.target.value})}
											>
												<Mui.FormControlLabel label="Left (default)" control={<Mui.Radio />} value={"left"} />
												<Mui.FormControlLabel label="Right" control={<Mui.Radio />} value={"right"} />
											</Mui.RadioGroup>
										</Mui.FormControl>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						}
						{user.permissions.eurekaSearch && eurekaEnabled && user.kestrelVersion.all && 
							<Mui.Grid item>
								<Mui.Grid container spacing={2} alignItems="center">
									<Mui.Grid item>
										<Mui.FormLabel id="details-view" className={classes.formLabel}>
											Details Open In
										</Mui.FormLabel>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.FormControl>
											<Mui.RadioGroup
												name="details-view"
												row={true} 
												value={listingDetailModal} 
												defaultValue={"false"}
												onChange={(event) => this.setState({listingDetailModal: event.target.value === "true"})}
											>
												<Mui.FormControlLabel label="New Tab (default)" control={<Mui.Radio />} value={false} />
												<Mui.FormControlLabel label="Modal" control={<Mui.Radio />} value={true} />
											</Mui.RadioGroup>
										</Mui.FormControl>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						}
						{user.permissions.eurekaSearch && eurekaEnabled && !user.kestrelVersion.all && 
							<Mui.Grid item>
								<TextFieldWithNote
									endAdornment={
										<Mui.InputAdornment position="end">px</Mui.InputAdornment>
									}
									error={!!errors && !!errors.eurekaSearchHeight}
									errorText={errors && errors.eurekaSearchHeight}
									label="Eureka Search Height"
									onChange={(value: string) => {
										this.setState({
											eurekaSearchHeight: value,
										});
									}}
									placeholder="auto"
									sidebarNote="When no height is specified, Eureka will be full screen height. When a fixed height is used, the navigation menu will not remain visible at the top of the window as the user scrolls down"
									value={eurekaSearchHeight}
								/>
							</Mui.Grid>
						}
						<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									disabled={saving || !!errors}
									onClick={() => this.save()}
									style={{ marginRight: 32 }}
									variant="contained"
								>
									Save Changes
								</Mui.Button>
								<Mui.Button
									onClick={() =>
										this.setState({
											saving: this.state.saving,
										})
									}
									variant="contained"
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
						open={saving && !authLoading && !authError}
						message="Settings saved"
						autoHideDuration={6000}
						onClose={() => this.setState({ saving: false })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ saving: false })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>Settings saved</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
					
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsMapSearchPage = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Component)
);
