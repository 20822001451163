import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import { WidgetConfigLayout } from "../widget-config-layout";
import { getUser } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface State {
}

class Component extends React.Component<Props, State> {

	public render() {
		const { user } = this.props;
		const embedCode = kestrelEmbedCode.marketsWidget(user.websitePlatform);
		return (
			<WidgetConfigLayout user={user} embedCode={embedCode} />
		);
	}
}

export const MarketsConfig = connect(mapStateToProps, mapDispatchToProps)(Component);