import React from "react";
import * as Mui from "@material-ui/core";

interface Props {
	title?: string;
	text?: JSX.Element | string;	
}

class Component extends React.Component<Props> {

	public render() {
		const {title, text} = this.props;
		return (
			<Mui.Box marginBottom={1}>
				{title && 
					<Mui.Typography variant="h4">
						{title}
					</Mui.Typography>
				}
				
				{text &&
					<Mui.Typography>
						{text}
					</Mui.Typography>
				}
			</Mui.Box>
		);
	}
}

export const SectionHeading = (Component);
