import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		errorMessage: {
			color: theme.palette.error.main,
		},
		nextButton: {
			marginTop: 15,
		}
	});
};
