class Type {

	public static LISTING_ADDRESS = new Type("listingAddress", "Listing Address");
	public static LISTING_CITY = new Type("listingCity", "Listing City");
	public static LISTING_POSTAL_CODE = new Type("listingPostalCode", "Listing Postal Code");
	public static LISTING_PHOTO_URL = new Type("listingPhotoUrl", "Listing Photo URL");
	public static LISTING_PHOTO_WIDTH = new Type("listingPhotoWidth", "Listing Photo Width");
	public static LISTING_PHOTO_HEIGHT = new Type("listingPhotoHeight", "Listing Photo Height");
	public static LISTING_PRICE = new Type("listingPrice", "Listing Price");
	public static LISTING_SOLD_PRICE = new Type("listingSoldPrice", "Listing Sold Price");
	public static LISTING_SQUARE_FEET = new Type("listingSquareFeet", "Listing Square Feet");
	public static LISTING_BEDROOOMS = new Type("listingBedrooms", "Listing # of Bedrooms");
	public static LISTING_BATHROOMS = new Type("listingBathrooms", "Listing # of Bathrooms");
	public static LISTING_NUMBER = new Type("listingNumber", "Listing Number");
	public static LISTING_DESCRIPTION = new Type("listingDescription", "Listing Description");
	public static MARKET_ID = new Type("marketId", "Market ID");
	public static MARKET_NAME = new Type("marketName", "Market Name");
	public static MARKET_DESCRIPTION = new Type("marketDescription", "Market Description");
	public static AGENT_ID = new Type("agentId", "Agent ID");
	public static AGENT_NAME = new Type("agentName", "Agent Name");
	public static AGENT_DESIGNATION = new Type("agentDesignation", "Agent Designation");
	public static OFFICE_ID = new Type("officeId", "Office ID");
	public static OFFICE_NAME = new Type("officeName", "Office Name");
	
	private constructor(
		public readonly id: string,
		public readonly label: string,
	
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as PageVariableType };
