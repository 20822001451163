import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		loader: {
			marginLeft: 0,
		},
		field: {
			marginRight: 0,
		},
		error: {
			color: "red", 
			fontSize: "small"
		}
	});
};
