class Type {
	public static ARCH_TELECOM = new Type("archtelecom", "Arch Telecom");
	public static BOOMTOWN = new Type("boomtown", "Boomtown");
	public static BUYER_ACQUIRE = new Type("buyeracquire", "BuyerAcquire");
	public static CDPE = new Type("cdpe", "CDPE");
	public static CONSOLNET = new Type("consulnet", "ConsulNet");
	public static DAKNO = new Type("dakno", "Dakno Marketing");
	public static DIVERSE_SOLUTIONS = new Type("diversesolutions", "Diverse Solutions");
	public static DYNETECH = new Type("dynetech", "DyneTech");
	public static EEDGE = new Type("eedge", "EEdge");
	public static EPROPERTY = new Type("eproperty", "EProperty");
	public static FACEBOOKADS = new Type("facebookads", "Facebook Ads");
	public static GABRIELS = new Type("gabriels", "Gabriels");
	public static GOOMZEE = new Type("goomzee", "Goomzee");
	public static HOMEFINDER = new Type("homeFinder", "HomeFinder");
	public static HOMES_COM = new Type("homes", "Homes.com");
	public static HOTPADS = new Type("hotPads", "HotPads");
	public static IDX_BROKER = new Type("idxBroker", "IDX Broker");
	public static IDX_PRO = new Type("idxPro", "IDX PRO");
	public static IHOMEFINDER = new Type("ihomeFinder", "iHomefinder");
	public static IHOUSEWEB = new Type("idxPro", "iHOUSEweb");
	public static LEADMX = new Type("leadmx", "LeadMX");
	public static Market_Leader = new Type("marketLeader", "Market Leader");
	public static NUMBER1EXPERT = new Type("number1expert", "Number1Expert");
	public static PHXHOMEQUEST = new Type("phxhomequest", "PHX HomeQuest");
	public static POINT2 = new Type("point2", "Point2");
	public static REALTOR_COM = new Type("Realtor.com", "Realtor.com");
	public static REAL_PRO_SYSTEMS = new Type("realprosystems", "Real Pro Systems");
	public static REAL_WEB_SOLUTIONS = new Type("realwebsolutions", "Real Web Solutions");
	public static RELIANCE = new Type("reliance", "Reliance");
	public static SPATIAL_MATCH = new Type("spatialMatch", "Spatial Match");
	public static SUCCESS_WEBSITE = new Type("successWebsite", "Success Website");
	public static TIGER_LEAD = new Type("tigerLead", "TigerLead");
	public static TOP_PRODUCER = new Type("topProducer", "Top Producer");
	public static TRULIA = new Type("trulia", "Trulia");
	public static WOLFNET = new Type("wolfnet", "Wolfnet");
	public static ZILLOW = new Type("zillow", "Zillow");
	public static ZURPLE = new Type("zurple", "Zurple");

	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public get archtelecom() {
		return this === Type.ARCH_TELECOM;
	}

	public get boomtown() {
		return this === Type.BOOMTOWN;
	}

	public get buyeracquire() {
		return this === Type.BUYER_ACQUIRE;
	}

	public get cdpe() {
		return this === Type.CDPE;
	}

	public get consulnet() {
		return this === Type.CONSOLNET;
	}

	public get dakno() {
		return this === Type.DAKNO;
	}

	public get diversesolutions() {
		return this === Type.DIVERSE_SOLUTIONS;
	}

	public get dynetech() {
		return this === Type.DYNETECH;
	}

	public get eedge() {
		return this === Type.EEDGE;
	}

	public get eproperty() {
		return this === Type.EPROPERTY;
	}

	public get facebookads() {
		return this === Type.FACEBOOKADS;
	}

	public get gabriels() {
		return this === Type.GABRIELS;
	}

	public get goomzee() {
		return this === Type.GOOMZEE;
	}

	public get homeFinder() {
		return this === Type.HOMEFINDER;
	}

	public get homes() {
		return this === Type.HOMES_COM;
	}

	public get hotPads() {
		return this === Type.HOTPADS;
	}

	public get idxBroker() {
		return this === Type.IDX_BROKER;
	}

	public get idxPro() {
		return this === Type.IDX_PRO;
	}

	public get ihomeFinder() {
		return this === Type.IHOMEFINDER;
	}

	public get iHouseWeb() {
		return this === Type.IHOUSEWEB;
	}

	public get leadmx() {
		return this === Type.LEADMX;
	}

	public get marketLeader() {
		return this === Type.Market_Leader;
	}

	public get number1expert() {
		return this === Type.NUMBER1EXPERT;
	}

	public get phxhomequest() {
		return this === Type.PHXHOMEQUEST;
	}

	public get point2() {
		return this === Type.POINT2;
	}

	public get Realtor() {
		return this === Type.REALTOR_COM;
	}

	public get realprosystems() {
		return this === Type.REAL_PRO_SYSTEMS;
	}

	public get realwebsolutions() {
		return this === Type.REAL_WEB_SOLUTIONS;
	}

	public get reliance() {
		return this === Type.RELIANCE;
	}

	public get spatialMatch() {
		return this === Type.SPATIAL_MATCH;
	}

	public get successWebsite() {
		return this === Type.SUCCESS_WEBSITE;
	}

	public get tigerLead() {
		return this === Type.TIGER_LEAD;
	}

	public get topProducer() {
		return this === Type.TOP_PRODUCER;
	}

	public get trulia() {
		return this === Type.TRULIA;
	}

	public get wolfnet() {
		return this === Type.WOLFNET;
	}

	public get zillow() {
		return this === Type.ZILLOW;
	}

	public get zurple() {
		return this === Type.ZURPLE;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

	public static getByLabel(label: string) {
		return this.values().find(value => value.label.toLowerCase() === label.toLowerCase());
	}

}
export { Type as LeadSourceType };