import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		itemWrapper: {
			display: "flex",
		},
		item: {
			flex: 1,
			marginRight: 15,
			height: 44,
			borderTopRightRadius: 20,
			borderBottomRightRadius: 20,
			textTransform: "none",
			justifyContent: "unset",
			color: "#fff",
		},
		active: {
			color: theme.palette.secondary.contrastText,
		},
		inactive: {
		},
		icon: {
			marginRight: 6,
		},
		chevron: {
			marginLeft: "auto",
			marginTop: 5,
		},
	})
}