import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		upgradeCrmButton: {

			margin: 35,
			marginLeft: 44,
			backgroundColor: "#8fa638",
			color: "white",
			textTransform: "none",

			"&:hover": {
				backgroundColor: "#8fa638"
			},

			[theme.breakpoints.only('md')]: {
				marginLeft: 42
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: 30
			},
			[theme.breakpoints.between('sm', 'md')]: {
				marginLeft: 45
			}
		},
		
		upgradeCrmText: {

			fontWeight: "bold",
			textDecoration: "none",
			color: "#8fa638",
			width: "100%",
			marginTop: 30,
			marginBottom: -10,
			marginLeft: 44,

			[theme.breakpoints.only('md')]: {
				marginLeft: 42
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: 30
			},
			[theme.breakpoints.between('sm', 'md')]: {
				marginLeft: 45
			}
		},

	
	})
}