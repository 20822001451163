import React from "react";
import { ErrorLayout } from "component/layout/error";

const Component = () => (
	<ErrorLayout
		title="Forbidden"
		header="403"
		message="The page or action is not permitted"
	/>
)

export const NotPermittedPage = Component;