import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		featurePoints: {
			[theme.breakpoints.up("lg")]: {
				height: "100%",
			},
		},
		screenShot: {
			[theme.breakpoints.up("lg")]: {
				width: 400,
			},
			[theme.breakpoints.down("lg")]: {
				width: 300,
			},
		},
		subtitle: {
			fontWeight: "bold", fontSize: "larger"
		},
		featureCard: {
			width: 300,
			height: "100%"
		}

	});
