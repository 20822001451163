import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		icon: {
			width: 24,
			height: 24,
			marginRight: 10,
		},
	});
};
