import React from "react";
import { FullPageCard } from "component/shared/full-page-card";
import { settingsPageLinks, leadActivity } from "routes/paths";
import { User } from "model/user";

interface Props {
	user: User
}

class Component extends React.Component<Props> {
	
	private mailto(email: string) {
		let result = `mailto:${email}`;
		return result;
	}

	public render() {
		const { user } = this.props;
		const adminEmail = user.reseller.email;
		return (
			<>
				{!user.permissions.oneCrm ? (
					<FullPageCard>
						<p>This Control Panel enables you to manage all aspects of your IDX account, including your leads, your listings, and how property search and listings are displayed on your website.</p>
						<p>You can navigate to the major sections of the Control Panel by clicking on the large buttons at the top of the screen (LEADS, LISTINGS, REPORTS, etc.). Most sections have their own menu of pages listed along the left side of the screen.</p>
						{ adminEmail && (
							<p>If you are a new IDX account holder, we recommend you visit the SETUP section and review the Search Settings, Email Settings, Lead Capture, and Listing Settings. If you have questions, consult the help resource
								or <a href={this.mailto(adminEmail)}>contact us</a> for assistance.</p>
						)}
						{ user.permissions.standalone && (
							<p>
								<b>(Note: if you are using the framed/wrapped IDX product, you will also need the links to your IDX pages found 
									under <a href={settingsPageLinks}>IDX Page Links</a>.)
								</b>
							</p>
						)}
						<p>Once your IDX pages are setup to your liking and are in operation, you'll want to primarily use the LEADS section to manage the leads generated by your account. You should also periodically review the various statistics and graphs in the REPORTS section to understand how your account is performing.</p> 
						{ user.permissions.leads && (
							<h4 style={{ textAlign: "center"}}><a href={leadActivity}>View Lead Activity</a></h4>
						)}
					</FullPageCard>
				) : (
					<FullPageCard>
						<p>If you are a new IDX account holder, we recommend you review the following account settings:</p>
						<p>Search Settings, to customize the search experience on your site </p>
						<p>Alert &amp; Email Settings, to change lead notification and IDX email alert settings</p>
						<p>Lead Capture, to customize lead forms or require user registration</p>
						<p>Listing Settings, to choose display options for results and property details</p>
						<p>If you have questions, <a href="mailto:{adminEmail}">contact us</a> for assistance.</p>
					</FullPageCard>)}
			</>
		);
	}

}

export const ControlPanelOverview = Component;