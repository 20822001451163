import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface Props extends Mui.WithStyles {
	label: string;
	onChange: (value: string) => void;
	note?: string;
	value: string;
}

class Component extends React.Component<Props> {
	render() {
		const { classes, label, onChange, note, value } = this.props;

		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item alignItems="center">
					<h3 className={classes.label}>{label}</h3>
				</Mui.Grid>
				{!!note && <Mui.Grid item>{note}</Mui.Grid>}
				<Mui.Grid item>
					<Mui.TextField
						fullWidth
						inputProps={{
							spellCheck: false,
						}}
						multiline
						onChange={(event) => {
							onChange(event.target.value);
						}}
						minRows={10}
						value={value}
						variant="outlined"
					/>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const CssHtmlTextField = Mui.withStyles(styles)(Component);
