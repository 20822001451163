class Type {

	public static INACTIVE = new Type("Inactive");
	public static ACTIVE = new Type("Active");
	public static COMPLETE = new Type("Complete");
	public static UNSUBSCRIBED = new Type("Unsubscribed");
	public static DELETED = new Type("Deleted");

	private constructor(
		public readonly id: string,
	) {
	}

	public get label() {
		return this.id;
	}

	public get inactive() {
		return this === Type.INACTIVE;
	}

	public get active() {
		return this === Type.ACTIVE;
	}

	public get complete() {
		return this === Type.COMPLETE;
	}

	public get unsubscribed() {
		return this === Type.UNSUBSCRIBED;
	}

	public get deleted() {
		return this === Type.DELETED;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as CampaignSubscriptionStatus };
