import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		icon: {
			width: 300,
			paddingTope: 2,
		},
		link: {
			fontSize: 20
		}
	});
};
