import React from 'react';
import * as Mui from '@material-ui/core';
import { styles } from './style';

interface Props extends OwnProps,
	Mui.WithStyles<typeof styles> {
}

interface OwnProps {
	icon?: React.ReactNode,
	action?: React.ReactNode,
}

class MyComponent extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.state = {
			
		};
	}

	render() {
		const { action, children, classes } = this.props;
		return (
			<Mui.Grid container alignItems="center" spacing={1}>
				<Mui.Grid item xs>
					{children}
				</Mui.Grid>
				<Mui.Grid item xs={1} className={classes.iconContainer}>
					{action && (
						<>{action}</>
					)}
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const Row = Mui.withStyles(styles)(MyComponent);