import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		bookmark: {
			color: "#e0e0e0"
		},
		activeBookmark: {
			color: "#fa8a33",
			fill: "currentColor",
		},
	});
};