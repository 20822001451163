import React from "react";
import Plot from "react-plotly.js";
import { Lead } from "model/lead";
import moment from "moment";

interface Props {
	leads: Lead[];
}

class Component extends React.Component<Props> {
	public render() {
		const { leads } = this.props;
		const leadsLastActive30Days = leads.filter(
			({ lastActiveOn }) =>
				lastActiveOn && moment().subtract(1, "M") < lastActiveOn,
		);
		const percentage = (leadsLastActive30Days.length / leads.length) * 100;
		const data = [
			{
				type: "indicator",
				value: Math.round(percentage),
				number: {
					font: {
						size: "40%",
					},
					suffix: "%",
				},
				mode: "gauge+number",
				gauge: {
					shape: "angular",
					bar: {
						thickness: 1,
						color: "#ab47bc",
					},
					bgcolor: "#f0f2f8",
					borderwidth: 0,
					axis: {
						range: [0, 100],
						visible: true,
						tickmode: "array",
						tickvals: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
						tickcolor: "#ddd",
						tickfont: {
							color: "#aaa",
							size: "10%",
						},
						tickangle: 0,
					},
				},
			},
		];
		return (
			<Plot
				// @ts-ignore
				data={data}
				layout={{
					showlegend: false,
					margin: {
						t: 30,
						b: 10,
						l: 30,
						r: 30,
						pad: 0,
					},
					xaxis: {
						fixedrange: true,
					},
					yaxis: {
						fixedrange: true,
					},
				}}
				config={{
					displayModeBar: false,
					responsive: true,
				}}
			/>
		);
	}

}

export const Chart = Component;
