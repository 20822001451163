import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface OwnProps {
}

type Props = OwnProps & Mui.WithStyles<typeof styles> & Mui.TableRowProps;

class Component extends React.Component<Props> {

	public render() {
		const { classes, ...others } = this.props;
		return (
			<Mui.TableRow
				{...others}
				classes={{
					root: classes.root,
				}}
			/>
		);
	}
}

export const TableRow = Mui.withStyles(styles)(Component);