import {
	oneUser,
} from "./mock-data/one-user";

const delay = 1000;

export const getUser = () => {
	return resolve(oneUser);
}

const resolve = (data?: any) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => resolve(data), delay);
	});
}

// const reject = (message?: any) => {
// 	return new Promise((resolve, reject) => {
// 		const error = new Error(message);
// 		setTimeout(() => reject(error), delay);
// 	});
// }
