import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		root: {
			fontWeight: "bold",
			textTransform: "uppercase",
		},
	});
};
