import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		formContainer: {
			display: "table"
		},
		hiddenMuiMenuItem: {
			display: "none",
		},
		input: {
			backgroundColor: "#fff",
		},
		block: {
			position: "relative", 
			marginTop: 10,
			marginBottom: 10,
		},
		pageBlock: {
			backgroundColor: "#F4F5F7", 
			padding: "10px 10px 10px 10px",
		},
		platformSelect: {
			marginTop: 5,
		},
		platformOption: {
			width: 135,
			border: "2px solid"
		},
		baseUrlRequired: {
			fontWeight: "bold", 
			color: "#f00",
		},
		logo: {
			width: "100%",
		},
		logoText: {
			fontSize: ".85rem"
		},
		wixSelected: {
			width: "150px",
			border: "2px solid #616161",
			borderRadius: "5px",
		}
	})
}
