import React from "react";
import * as Mui from "@material-ui/core";
import * as Router from "react-router-dom";
import { styles } from "./style";
import { getLeads } from "redux/selector";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

interface RouteParams {
	id: string;
}

interface OwnProps extends Router.RouteComponentProps<RouteParams>, Mui.WithStyles<typeof styles> {
	onSubmit: () => void;	
}

interface State {
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		leads: getLeads(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

class Component extends React.Component<Props, State> {

	public render() {
		const { classes, onSubmit } = this.props;
		return (
			<Mui.Paper elevation={1} className={classes.content}>
				<Mui.Grid container direction="column" spacing={1} justifyContent="center">
					<Mui.Grid item>
						<Mui.Typography variant="h2" paragraph>
							Get Started With Texting
						</Mui.Typography>
						<Mui.Typography variant="body1" paragraph>
						Marketing Automation now includes text messages with Smart Content. 
						Leads who opt-in to text messages will receive drip Marketing Automation texts from a number rented on your behalf. 
						To enable this feature, complete the setup wizard.
						</Mui.Typography>
						<Mui.Typography paragraph>
							We support the following opt-out keywords: <strong>STOP, CANCEL, END, QUIT, and UNSUBSCRIBE</strong>
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Button variant="contained" color="secondary" onClick={() => onSubmit()}>
							Enable Texting Now
					</Mui.Button>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Paper>
		);
	}

}

export const OptIn = Mui.withStyles(styles)(
	Router.withRouter(
		connect<StateProps, DispatchProps, OwnProps, RootState>(
			mapStateToProps,
			mapDispatchToProps
		)(Component)
	)
);