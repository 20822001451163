import React from "react";
import { getTypeTagsForLead } from "redux/selector";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

interface State {
}

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	leadId: number;
}

interface OwnProps {
	leadId: number;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		tags: getTypeTagsForLead(state, ownProps.leadId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props, State> {

	public render() {
		const { tags } = this.props;
		return tags.map(tag => tag.text).join(", ");
	}
}

export const Type = connect(mapStateToProps, mapDispatchToProps)(Component);