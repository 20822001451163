import { LanguageResource } from "api/resource/language";

export const languages: LanguageResource[] = [
    {
        id: 1,
        name: "Afrikaans",
    },
    {
        id: 2,
        name: "Akan",
    },
    {
        id: 3,
        name: "Australian",
    },
    {
        id: 4,
        name: "Bosnian",
    },
    {
        id: 5,
        name: "Bulgarian",
    },
    {
        id: 6,
        name: "Cambodian",
    },
    {
        id: 7,
        name: "Canadian",
    },
    {
        id: 8,
        name: "Chinese",
    },
    {
        id: 9,
        name: "Creole",
    },
    {
        id: 10,
        name: "Dari",
    },
    {
        id: 11,
        name: "Dutch",
    },
    {
        id: 12,
        name: "Farsi",
    },
    {
        id: 13,
        name: "Filipino",
    },
    {
        id: 14,
        name: "Finnish",
    },
    {
        id: 15,
        name: "French",
    },
    {
        id: 16,
        name: "Georgian",
    },
    {
        id: 17,
        name: "German",
    },
    {
        id: 18,
        name: "Greek",
    },
    {
        id: 19,
        name: "Gujarati",
    },
    {
        id: 20,
        name: "Gujurati",
    },
    {
        id: 21,
        name: "Hebrew",
    },
    {
        id: 22,
        name: "Hindhi",
    },
    {
        id: 23,
        name: "Hindi",
    },
    {
        id: 24,
        name: "Hokkien",
    },
    {
        id: 25,
        name: "Hungarian",
    },
    {
        id: 26,
        name: "Ibibio",
    },
    {
        id: 27,
        name: "Ibo",
    },
    {
        id: 28,
        name: "Igbo",
    },
    {
        id: 29,
        name: "Indonesian",
    },
    {
        id: 30,
        name: "Italian",
    },
    {
        id: 31,
        name: "Japanese",
    },
    {
        id: 32,
        name: "Kikuyu",
    },
    {
        id: 33,
        name: "Kiluba",
    },
    {
        id: 34,
        name: "Korean",
    },
    {
        id: 35,
        name: "Kreyol",
    },
    {
        id: 36,
        name: "Laos",
    },
    {
        id: 37,
        name: "Laotian",
    },
    {
        id: 38,
        name: "Lithuanian",
    },
    {
        id: 39,
        name: "Llocano",
    },
    {
        id: 40,
        name: "Macedonian",
    },
    {
        id: 41,
        name: "Malagasie",
    },
    {
        id: 42,
        name: "Malay",
    },
    {
        id: 43,
        name: "Malayalam",
    },
    {
        id: 44,
        name: "Mandalin",
    },
    {
        id: 45,
        name: "Mandarin",
    },
    {
        id: 46,
        name: "Marathi",
    },
    {
        id: 47,
        name: "Moroccan",
    },
    {
        id: 48,
        name: "Naija",
    },
    {
        id: 49,
        name: "Pashto",
    },
    {
        id: 52,
        name: "Polish",
    },
    {
        id: 53,
        name: "Portuguese",
    },
    {
        id: 55,
        name: "Punjabi",
    },
    {
        id: 56,
        name: "Romanian",
    },
    {
        id: 57,
        name: "Russian",
    },
    {
        id: 58,
        name: "Serbian",
    },
    {
        id: 59,
        name: "SerboCroat",
    },
    {
        id: 60,
        name: "Sign Language",
    },
    {
        id: 61,
        name: "Sindhi",
    },
    {
        id: 62,
        name: "Southern",
    },
    {
        id: 63,
        name: "Spanish",
    },
    {
        id: 64,
        name: "Swahili",
    },
    {
        id: 65,
        name: "Swedish",
    },
    {
        id: 66,
        name: "Tagalog",
    },
    {
        id: 67,
        name: "Taiwanese",
    },
    {
        id: 68,
        name: "Telugu",
    },
    {
        id: 69,
        name: "Teochew",
    },
    {
        id: 70,
        name: "Thai",
    },
    {
        id: 71,
        name: "Turkish",
    },
    {
        id: 72,
        name: "Urdu",
    },
    {
        id: 73,
        name: "Urhobo",
    },
    {
        id: 74,
        name: "Vietnamese",
    },
    {
        id: 75,
        name: "Wala",
    },
    {
        id: 76,
        name: "Yiddish",
    },
    {
        id: 77,
        name: "Yoruba",
    },
    {
        id: 78,
        name: "Zulu",
    },
    {
        id: 79,
        name: "Tamil",
    },
    {
        id: 80,
        name: "Armenian",
    },
    {
        id: 81,
        name: "Arabic",
    },
    {
        id: 82,
        name: "Assyrian",
    },
    {
        id: 83,
        name: "Danish",
    },
    {
        id: 84,
        name: "Norwegian",
    },
    {
        id: 85,
        name: "Ukrainian",
    },
    {
        id: 86,
        name: "Cantonese",
    },
    {
        id: 87,
        name: "Ilocano",
    },
    {
        id: 88,
        name: "Flemish",
    },
    {
        id: 90,
        name: "Ossetian",
    },
    {
        id: 91,
        name: "Shanghainese",
    },
    {
        id: 92,
        name: "Szechuanese",
    },
    {
        id: 93,
        name: "Chiuchownese",
    },
    {
        id: 94,
        name: "Hmong",
    },
    {
        id: 95,
        name: "Burmese",
    },
    {
        id: 96,
        name: "Croatian",
    },
    {
        id: 97,
        name: "Slovene",
    }
];