import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		dropdownButton: {
			maxWidth: 300,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			textTransform: "none",
		},
		line1: {
			fontSize: "1.1em",
			fontWeight: 300,
			color: "#3a3a3a",
			maxWidth: 125,
			textAlign: "left",
		},
		line2: {
			fontSize: "0.9em",
			maxWidth: 125,
			textAlign: "left",
		},
		menuItemText: {
			paddingLeft: 8,
		},
		copyMessage: {
			color: "#304572", 
			backgroundColor:'#E2F2FC', 
			fontSize:'10px',
			width:'35px',
			padding:'5px'
		},
	});
};
