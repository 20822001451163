import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		header: {
			textTransform: "uppercase",
			paddingTop: 20,
			height: 60,
		}
	});
};
