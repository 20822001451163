import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface Props extends Mui.WithStyles {
	defaultValue: number;
	label: string;
	marks?: { label: string; value: number }[];
	max?: number;
	min?: number;
	onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
	step?: number;
	sidebarNote?: React.ReactNode;
	value: number;
}

const Component = (props: Props) => {
	const {
		classes,
		defaultValue,
		label,
		marks,
		max,
		min,
		onChange,
		step,
		sidebarNote,
		value,
	} = props;

	return (
		<Mui.Grid container direction="row" spacing={2}>
			<Mui.Grid item xs>
				<Mui.Typography
					className={classes.label}
					gutterBottom
				>
					{`${label}: ${value}`}
				</Mui.Typography>
				<Mui.Slider
					defaultValue={defaultValue}
					marks={marks || true}
					max={max}
					min={min}
					onChange={onChange}
					step={step}
					value={value}
					valueLabelDisplay="auto"
				/>
			</Mui.Grid>
			{sidebarNote !== false && (
				<Mui.Grid item xs>
					{sidebarNote && (
						<Mui.Typography>
							<strong>Note:</strong>
							{" "}
							{sidebarNote}
						</Mui.Typography>
					)}
				</Mui.Grid>
			)}
		</Mui.Grid>
	);
};

export const SliderWithNote = Mui.withStyles(styles)(Component);
