import { createStyles, Theme } from "@material-ui/core";
import * as Colors from "@material-ui/core/colors";

export const styles = (theme: Theme) => {
	return createStyles({
		image: {
			height: 225,
		},
		delete: {
			marginLeft: "auto",
		},
		starred: {
			color: Colors.amber[400],
			fill: Colors.amber[400],
		},
		unstarred: {
			color: Colors.grey[300],
			fill: Colors.grey[300],
		},
		dialogContent: {
			[theme.breakpoints.down("xs")]: {
				display: "flex",
				alignItems: "flex-end",
				textAlign: "center",
			},
		},
		dialogActions: {
			[theme.breakpoints.down("xs")]: {
				height: "50%",
				justifyContent: "center",
				alignItems: "flex-start",
				paddingLeft: 30,
				paddingRight: 30,
				"& button": {
					flex: 1,
				}
			},
		},
	});
};
