class Type {
	public static OPTION_1 = new Type("st", "Status, Property Type, Price Descending");
	public static OPTION_2 = new Type("cn", "Property Type, City, Street Number, Street Name");
	public static OPTION_3 = new Type("ds", "Property Type, Date Imported");
	public static OPTION_4 = new Type("lpd", "Property Type, Price Descending");
	public static OPTION_5 = new Type("lpa", "Property Type, Price Ascending");
	public static OPTION_6 = new Type("ln", "Property Type, Listing Number");

	private constructor(
		public readonly id: string,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.OPTION_1;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: string): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as FeaturedSortCodeType };
