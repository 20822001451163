import { EmailClickResource } from "api/resource/email-click";
import { listings } from "./listings";

export const emailClicks: EmailClickResource[] = [
	{
		id: 1,
		leadId: 1,
		savedSearchId: 1,
		clickedOn: "2018-11-13T15:46:41.723-08:00",
		listing: listings[0],
	},
	{
		id: 2,
		leadId: 1,
		savedSearchId: 1,
		clickedOn: "2018-11-20T15:46:41.723-08:00",
		listing: listings[0],
	},
	{
		id: 3,
		leadId: 1,
		marketReportId: 11,
		clickedOn: "2018-11-20T15:46:41.723-08:00",
		listing: listings[1],
	},
];