class Type {

	public static MINUTES = new Type("minutes", "Minute", "Minutes");
	public static DAYS = new Type("days", "Day", "Days");

	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly pluralLabel: string,
	) {
	}

	public get minutes() {
		return this === Type.MINUTES;
	}

	public get days() {
		return this === Type.DAYS;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find((value) => value.id === id);
	}

}

export { Type as TimeUnitType };
