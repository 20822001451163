import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { SavedSearches } from "./saved-searches";
import { SavedListings } from "./saved-listings";
import { styles } from "./style";
import { Lead } from "model/lead";
import { User } from "model/user";

interface Props extends Mui.WithStyles<typeof styles> {
	user: User;
	lead: Lead;
}

interface State {
	tabIndex: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			tabIndex: 0,
		};
	}

	public render() {
		const { user, lead } = this.props;
		const { tabIndex } = this.state;
		const organizerIsActivated = true;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				{organizerIsActivated &&
					<>
						<Mui.Grid item style={{width: "100%"}}>
							<Mui.Paper square>
								<Mui.Tabs
									value={tabIndex}
									textColor="secondary"
									centered
									onChange={(_event: any, tabIndex: number) => this.setState({ tabIndex })}
								>
									{user.permissions.leadSaveSearch && 
										<Mui.Tab label="Saved Searches" icon={
											<FeatherIcon>
												<Icons.Search />
											</FeatherIcon>
										} />
									}
									<Mui.Tab label="Favorites" icon={
										<FeatherIcon>
											<Icons.Heart />
										</FeatherIcon>
									} />
								</Mui.Tabs>
							</Mui.Paper>
						</Mui.Grid>
						<Mui.Grid item>
							{user.permissions.leadSaveSearch && tabIndex === 0 &&
								<SavedSearches user={user} lead={lead} />
							}
							{tabIndex === 1 &&
								<SavedListings user={user} lead={lead} />
							}
						</Mui.Grid>
					</>
				}
				{!organizerIsActivated &&
					<Mui.Grid item>
						<Mui.Card>
							<Mui.CardContent>
								<Mui.Grid container justifyContent="space-between" alignItems="center">
									<Mui.Grid item>
										<Mui.Typography>Organizer not activated</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Button
											variant="contained"
											color="secondary"
										>
											Activate
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardContent>
						</Mui.Card>
					</Mui.Grid>
				}
			</Mui.Grid>
		);
	}
}

export const PropertyOrganizerTab = Mui.withStyles(styles)(Component);
