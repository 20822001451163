class Type {

	public static BOOKMARKED = new Type("Bookmarked");
	public static BUYER = new Type("Buyer");
	public static SELLER = new Type("Seller");
	public static RENTER = new Type("Renter");

	private constructor(
		public readonly id: string,
	) {
	}

	public get label() {
		return this.id;
	}

	public get bookmarked() {
		return this === Type.BOOKMARKED;
	}

	public get buyer() {
		return this === Type.BUYER;
	}

	public get seller() {
		return this === Type.SELLER;
	}

	public get renter() {
		return this === Type.RENTER;
	}

	public get type() {
		return Type.leadTypeValues().includes(this);
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static leadTypeValues(): Type[] {
		return [Type.BUYER, Type.SELLER, Type.RENTER];
	}

	public static getLeadTypeById(id: string) {
		return this.leadTypeValues().find(value => value.id.toLowerCase() === id.toLowerCase());
	}

	public static getById(id: string) {
		return this.values().find(value => value.id.toLowerCase() === id.toLowerCase());
	}

}

export { Type as LeadTagType };
