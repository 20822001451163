import { PageVariableType } from "./page-variable";

class Type {

	public static DEFAULT = new Type(1, "Default",);
	public static LISTING = new Type(2, "Listing Details", "/listing?id={id}", [
		PageVariableType.LISTING_CITY,
		PageVariableType.LISTING_POSTAL_CODE,
		PageVariableType.LISTING_PHOTO_URL,
		PageVariableType.LISTING_PHOTO_WIDTH,
		PageVariableType.LISTING_PHOTO_HEIGHT,
		PageVariableType.LISTING_PRICE,
		PageVariableType.LISTING_SOLD_PRICE,
		PageVariableType.LISTING_SQUARE_FEET,
		PageVariableType.LISTING_BEDROOOMS,
		PageVariableType.LISTING_BATHROOMS,
		PageVariableType.LISTING_NUMBER,
		PageVariableType.LISTING_DESCRIPTION,
	]);
	public static LISTINGS = new Type(3, "Search");
	public static EMAIL_ALERTS = new Type(4,"Email Alerts");
	public static FEATURED_LISTINGS = new Type(5, "Featured Listings");
	public static PENDING_FEATURED_LISTINGS = new Type(6, "Pending Featured Listings");
	public static SOLD_FEATURED_LISTINGS = new Type(7, "Sold Featured Listings");
	public static OPEN_HOME_LISTINGS = new Type(8, "Open Home Listings");
	public static SUPPLEMENTAL_LISTINGS = new Type(9, "Supplemental Listings");
	public static HOT_SHEETS = new Type(10, "Markets");
	public static LISTING_REPORT = new Type(11, "Listing Report", "/listing-report?id={id}", [
		PageVariableType.MARKET_NAME,
		PageVariableType.MARKET_DESCRIPTION,
	]);
	public static OPEN_HOME_REPORT = new Type(12, "Open Home Report", "/open-home-report?id={id}", [
		PageVariableType.MARKET_NAME,
		PageVariableType.MARKET_DESCRIPTION,
	]);
	public static MARKET_REPORT = new Type(13, "Market Report", "/market-report?id={id}", [
		PageVariableType.MARKET_ID,
		PageVariableType.MARKET_NAME,
		PageVariableType.MARKET_DESCRIPTION,
	]);
	public static PROPERTY_ORGANIZER = new Type(14, "Property Organizer");
	public static CONTACT_FORM = new Type(15, "Contact Form");
	public static VALUATION_FORM = new Type(16, "Valuation Form");
	public static MORTGAGE_CALCULATOR = new Type(17, "Mortgage Calculator");
	public static AGENT = new Type(18, "Agent Profile", "/agent?id={id}", [
		PageVariableType.AGENT_ID,
		PageVariableType.AGENT_NAME,
		PageVariableType.AGENT_DESIGNATION,
	]);
	public static AGENTS = new Type(19,"Agents");
	public static OFFICE = new Type(20, "Office Profile", "/office?id={id}", [
		PageVariableType.OFFICE_ID,
		PageVariableType.OFFICE_NAME
	]);
	public static OFFICES = new Type(21, "Offices");

	private constructor(
		public readonly id: number,
		public readonly label: string,
		public readonly queryVars?: string,
		public readonly variables?: PageVariableType[],
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as PageType };
