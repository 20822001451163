import {SiteLinkGroupType as SiteLinkGroup} from "./site-link-group";

class Type {

	public static AGENT_LIST = new Type("agent_list", "Agent List", SiteLinkGroup.AGENT_OFFICE, 1);
	public static OFFICE_LIST = new Type("office_list", "Office List", SiteLinkGroup.AGENT_OFFICE, 2);

	public static MLS_SEARCH = new Type("mls_search", "MLS Search", SiteLinkGroup.LISTING_RELATED, 1);
	public static MAP_SEARCH = new Type("map_search", "Map Search", SiteLinkGroup.LISTING_RELATED, 2);
	public static EMAIL_UPDATE = new Type("email_update", "Email Update", SiteLinkGroup.LISTING_RELATED, 3);
	public static PROPERTY_ORGANIZER_LOGIN = new Type("property_organizer_login", "Property Organizer Login", SiteLinkGroup.LISTING_RELATED, 4);
	public static OPEN_HOMES_SEARCH = new Type("open_homes_search", "Open Homes Search", SiteLinkGroup.LISTING_RELATED, 5);
	public static FEATURED_LISTINGS = new Type("featured_listings", "Featured Listings", SiteLinkGroup.LISTING_RELATED, 6);
	public static RENTAL_LISTINGS = new Type("rental_listings", "Rental Listings", SiteLinkGroup.LISTING_RELATED, 7);
	public static COMMERCIAL_LISTINGS = new Type("commercial_listings", "Commercial Listings", SiteLinkGroup.LISTING_RELATED, 8);
	public static SOLD_LISTINGS = new Type("sold_listings", "Sold Listings", SiteLinkGroup.LISTING_RELATED, 9);
	public static PENDING_LISTINGS = new Type("pending_listings", "Pending Listings", SiteLinkGroup.LISTING_RELATED, 10);
	public static SOLD_PENDING_LISTINGS = new Type("sold_pending_listings", "Sold Pending Listings", SiteLinkGroup.LISTING_RELATED, 11);
	public static SUPPLEMENTAL_LISTINGS = new Type("supplemental_listings", "Supplemental Listings", SiteLinkGroup.LISTING_RELATED, 12); 
	public static SAVED_SEARCH_PAGES = new Type("saved_search_pages", "Saved Search Pages", SiteLinkGroup.LISTING_RELATED, 13);
	
	public static HOME_VALUATION_REQUEST = new Type("home_valuation_request", "Home Valuation Request", SiteLinkGroup.OPTIONAL_ADDON, 1);
	public static MORTGAGE_CALCULATOR = new Type("mortgage_calculator", "Mortgage Calculator", SiteLinkGroup.OPTIONAL_ADDON, 2);
	public static CONTACT_FORM = new Type("contact_form", "Contact Form", SiteLinkGroup.OPTIONAL_ADDON, 3);
	public static BLOG = new Type("blog", "Blog", SiteLinkGroup.OPTIONAL_ADDON, 4);

	public static MOBILE_HOMEPAGE = new Type("mobile_homepage", "Homepage", SiteLinkGroup.MOBILE, 1);
	public static MOBILE_SEARCH = new Type("mobile_search", "Search", SiteLinkGroup.MOBILE, 2);
	public static MOBILE_CONTACT = new Type("mobile_contact", "Contact", SiteLinkGroup.MOBILE, 3);
	public static MOBILE_OFFICES = new Type("mobile_offices", "Offices", SiteLinkGroup.MOBILE, 4);
	public static MOBILE_SEARCH_BY_ADDRESS = new Type("mobile_search_by_address", "Search By Address", SiteLinkGroup.MOBILE, 5);
	public static MOBILE_OPEN_HOMES_SEARCH = new Type("mobile_open_homes_search", "Open Homes Search", SiteLinkGroup.MOBILE, 6);
	public static MOBILE_SEARCH_BY_MLS = new Type("mobile_search_by_mls", "Search By MLS #", SiteLinkGroup.MOBILE, 7);
	public static MOBILE_FEATURED_LISTINGS = new Type("mobile_featured_listings", "Featured Listings", SiteLinkGroup.MOBILE, 8);
	public static MOBILE_SUPPLEMENTAL_LISTINGS = new Type("mobile_supplemental_listings", "Supplemental Listings", SiteLinkGroup.MOBILE, 9);

	public static WP_LISTING_SEARCH_FORM = new Type("listing_search_form", "Listing Search Form", SiteLinkGroup.NONE, 1);
	public static WP_TOP_PICKS = new Type("top_picks", "Top Picks", SiteLinkGroup.NONE, 2);
	public static WP_FEATURED_SEARCH = new Type("featured_search", "Featured Search", SiteLinkGroup.NONE, 3);
	public static WP_ORGANIZER_LOGIN = new Type("organizer_login", "Organizer Login", SiteLinkGroup.NONE, 4);
	public static WP_EMAIL_ALERTS = new Type("email_alerts", "Email Alerts", SiteLinkGroup.NONE, 5);

	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly group: SiteLinkGroup,
		public readonly order: number,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as SiteLinkType };