import { IntegrationCalendarResource } from "api/resource/integration-calendar";

export const integrationCalendars: IntegrationCalendarResource[] = [
	{
		id: "67qmz3fuk9wf",
		integrationId: 6,
		name: "Calendar",
		readOnly: false,
	},
	{
		id: "b4xm1jjibrxky",
		integrationId: 6,
		name: "Emailed events",
		readOnly: false,
	},
];