import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		dialogContent: {
			[theme.breakpoints.down("xs")]: {
				display: "flex",
				alignItems: "flex-end",
				textAlign: "center",
			},
		},
		dialogActions: {
			[theme.breakpoints.down("xs")]: {
				height: "50%",
				justifyContent: "center",
				alignItems: "flex-start",
				paddingLeft: 30,
				paddingRight: 30,
				"& button": {
					flex: 1,
				}
			},
		},
		disclaimer: {
			display: "flex",
			justifyContent: "flex-end",
			margin: -10
		}
	});
};
