import { CampaignRouteResource } from "api/resource/campaign-route";
import { LeadSourceType } from "type/lead-source-type";

export const campaignRoutes: CampaignRouteResource[] = [
	{
		id: 1,
		campaignId: 200,
		leadSource: LeadSourceType.ARCH_TELECOM.id,
	},
	{
		id: 2,
		campaignId: 100,
		leadSource: LeadSourceType.BOOMTOWN.id,
	}
];