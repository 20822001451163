import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Lead } from "model/lead";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import classNames from "classnames";
import { RootState } from "redux/store";
import { createLeadPipeline } from "redux/slice/lead-pipelines";
import { getLatestLeadPipelineForLead } from "redux/selector";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { LeadPipelineType } from "type/lead-pipeline";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		leadPipeline: getLatestLeadPipelineForLead(state, ownProps.lead.id),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			createLeadPipeline,
		},
		dispatch,
	);

interface Props
	extends Mui.WithStyles<typeof styles>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	lead: Lead;
}

interface State {
	open: boolean;
	anchorEl: any;
	pipelineType: LeadPipelineType | null;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			open: false,
			anchorEl: null,
			pipelineType: null,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		const { leadPipeline } = this.props;
		if (prevProps.leadPipeline !== leadPipeline && leadPipeline) {
			const typeId = leadPipeline.type.id;
			const pipelineType = LeadPipelineType.getById(typeId)!;
			this.setState({
				pipelineType,
			});
		}
	}

	private color(current: number) {
		const { pipelineType } = this.state;
		if (pipelineType) {
			const { stage } = pipelineType;
			if (stage === 4 && current <= stage) {
				return "primary";
			} else if (stage >= current) {
				return "secondary";
			} else {
				return "default";
			}
		}
	}

	private handleClick = (pipelineType: LeadPipelineType) => {
		this.setState({ open: false });
		const leadPipeline = {
			leadId: this.props.lead.id,
			type: LeadPipelineType.getByIdOrDefault(pipelineType.id),
		}
		this.props.createLeadPipeline({leadPipeline});
	}

	public render() {
		const { classes } = this.props;
		const { pipelineType } = this.state;

		return (
			<Mui.Grid container justifyContent="center">
				<Mui.Hidden xsDown>
					<Mui.Grid item>
						<div>
							{LeadPipelineType.values().map((type, index) => {
								if (type.closed) {
									return (
										<Mui.Button
											key={index}
											size="small"
											variant="contained"
											color={
												pipelineType && pipelineType.stage >= type.stage ? "secondary" : "default"
											}
											onClick={() => this.handleClick(type)}
											className={classes.closedButton}
										>
											{type.label}
										</Mui.Button>
									);
								}
								const zIndex = 5 - index;
								return (
									<Mui.Button
										key={index}
										size="small"
										variant="contained"
										color={this.color(type.stage)}
										disableRipple
										disableFocusRipple
										disableTouchRipple
										style={{ zIndex: zIndex }}
										onClick={() => this.handleClick(type)}
										className={classNames(
											classes.pipelineButton,
											type.uncontacted && classes.pipelineStart,
											type.underContract && classes.pipelineEnd,
											type.stage < 5 && classes.arrow,
											pipelineType &&
												pipelineType.stage === 4 &&
												type.stage <= pipelineType.stage &&
												classes.dead,
											pipelineType &&
												pipelineType.stage > 4 &&
												type.stage === 4 &&
												classes.lineThrough,
											pipelineType && pipelineType.stage >= type.stage
												? classes.completed
												: classes.incompleted,
										)}
									>
										{type.label}
									</Mui.Button>
								);
							})}
						</div>
					</Mui.Grid>
				</Mui.Hidden>

				<Mui.Hidden smUp>
					<Mui.Grid item>
						<Mui.Grid container spacing={2} alignItems="center">
							<Mui.Grid item>
								<Mui.Typography className={classes.fabLabel}>Lead Stage</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Fab
									variant="extended"
									color={pipelineType === LeadPipelineType.DEAD ? "primary" : "secondary"}
									className={classes.fab}
									onClick={event => {
										this.setState({
											open: !this.state.open,
											anchorEl: event.currentTarget,
										});
									}}
								>
									<Mui.Grid container justifyContent="space-between">
										<Mui.Grid item className={classes.popoverText}>
											<Mui.Typography>{pipelineType && pipelineType.label}</Mui.Typography>
										</Mui.Grid>
										<Mui.Grid item className={classes.popoverText}>
											<FeatherIcon>
												<Icons.ChevronDown />
											</FeatherIcon>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Fab>
								<Mui.Popover
									open={this.state.open}
									anchorEl={this.state.anchorEl}
									onClose={() => {
										this.setState({ open: false });
									}}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
								>
									<Mui.Grid container direction="column" className={classes.popover}>
										{LeadPipelineType.values().map((type, index) => (
											<Mui.Grid item key={index}>
												<Mui.Button
													key={index}
													disableRipple
													disableFocusRipple
													disableTouchRipple
													className={classNames(
														classes.itemButton,
														pipelineType &&
															pipelineType.stage === type.stage &&
															classes.itemCompleted,
													)}
													onClick={() => this.handleClick(type)}
												>
													{type.label}
												</Mui.Button>
											</Mui.Grid>
										))}
									</Mui.Grid>
								</Mui.Popover>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Hidden>
			</Mui.Grid>
		);
	}
}

export const Pipeline = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component),
);
