class Type {
	
	public static BIRTHDAY = new Type(1, "Birthday");
	public static TRANSACTION_ANNIVERSARY = new Type(2, "Transaction Anniversary")
	
	private constructor(
		public readonly id: number,
		public readonly label: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as LeadAnniversaryType };