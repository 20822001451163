import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => createStyles({
	link: {
		color: theme.palette.secondary.main,
		alignItems: "center",
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer"
		},
	},
})