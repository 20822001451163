import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		container: {
			[theme.breakpoints.up("xs")]: {
				height: "auto",
			},
			[theme.breakpoints.down("xs")]: {
				height: "100%",
			},
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
		actions: {
			justifyContent: "left",
			paddingLeft: 24,
		},
		action: {
			marginRight: 10,
			fontSize: 16,
		},
		actionIcon: {
			marginRight: 4,
			fontSize: 16,
			fontFamily: "monospace",
			fontWeight: "bold",
		},
	});
};
