import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		layout: {
			minHeight: "100vh",
			backgroundColor: "#fafafa",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			padding: "2em",
		},
		container: {
			maxWidth: 550,
			textAlign: "center",
		},
		header: {
			fontSize: 80,
			fontWeight: 400,
		},
		message: {
			color: "rgba(0, 0, 0, 0.54)",
			fontSize: "1.6em",
		},
	});
};