import { OfficeResource } from "api/resource/office";

export const offices: OfficeResource[] = [
	{
		id: 1,
		name: "San Francisco",
		address: "San Francisco, CA 94128",
		city: "San Francisco",
		email: "office@office.com",
		priority: 1,
	},
	{
		id: 2,
		name: "New York",
		address: "Liberty Island New York, NY 10004",
		city: "New York",
		email: "office@office.com",
		priority: 2,
	},
	{
		id: 3,
		name: "Bejing",
		address: "No 1 Jianguomenwai Avenue, Beijing 100004",
		city: "Beijing",
		email: "office@office.com",
		priority: 3,
	},
];