import React from "react";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FullPageCard } from "component/shared/full-page-card";
import { DashboardLayout } from "component/layout/dashboard";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { User } from "model/user";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	user: User;
}

interface State {
	requirePropertyOrganizerPassword: boolean;
	saving: boolean;
}

const mapStateToProps = (state: RootState) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			...props.user,
			saving: false,
		};
	}

	public save() {
		const { saveUser } = this.props;
		const user = {
			...this.state,
		};
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const { authError, authLoading } = this.props;
		const { requirePropertyOrganizerPassword, saving } = this.state;
		const title = "Property Organizer Settings";
		return (
			<DashboardLayout
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						<Mui.Grid item>
							<YesNoRadioField
								label="Require Password"
								onChange={(value) => {
									this.setState({
										requirePropertyOrganizerPassword: value,
									});
								}}
								value={requirePropertyOrganizerPassword}
							/>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
						<Mui.Grid item>
							<Mui.Button
								color="secondary"
								disabled={saving}
								onClick={() => this.save()}
								style={{ marginRight: 32 }}
								variant="contained"
							>
								Save Changes
							</Mui.Button>
							<Mui.Button
								onClick={() =>
									this.setState({
										saving: this.state.saving,
									})
								}
								variant="contained"
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
						open={saving && !authLoading && !authError}
						message="Settings saved"
						autoHideDuration={6000}
						onClose={() => this.setState({ saving: false })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ saving: false })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>Settings saved</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsPropertyOrganizerPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Component);
