import { LeadActivityStatsResource } from "api/resource/lead-activity-stats";

export const leadActivityStats: LeadActivityStatsResource[] = [
	{
		id: "lead_actions",
		name: "Lead Actions",
		previous: 201,
		current: 256
	},
	{
		id: "new_leads",
		name: "New Leads",
		previous: 25,
		current: 28
	},
	{
		id: "searches",
		name: "Searches",
		previous: 300,
		current: 323
	},
	{
		id: "property_views",
		name: "Property Views",
		previous: 578,
		current: 607
	},
	{
		id: "emails_sent",
		name: "Emails Sent",
		previous: 1002,
		current: 1123
	},
	{
		id: "click_thrus",
		name: "Click Thrus",
		previous: 176,
		current: 188
	},
	{
		id: "reg_prompts",
		name: "Reg Prompts",
		previous: 77,
		current: 89
	},
	{
		id: "reg_signups",
		name: "Reg Signups",
		previous: 33,
		current: 49
	}
];