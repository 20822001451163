import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { getUserUnsafe } from "redux/selector";
import { styles } from "./style";
import { Helmet } from "react-helmet";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUserUnsafe(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	title: string;
	header: React.ReactNode;
	message: React.ReactNode;
}

class Component extends React.Component<Props> {

	public render() {
		const { classes, title, header, message, user } = this.props;
		return (
			<>
				<Helmet
					title={title}
				/>
				<div className={classes.layout}>
					<div className={classes.container}>
						<h1 className={classes.header}>{header}</h1>
						<p className={classes.message}>{message}</p>
						{user &&
							<RouterLinkWrapper to={urls.landing(user)}>Go to your dashboard</RouterLinkWrapper>
						}
						{!user &&
							<RouterLinkWrapper to={urls.login}>Login to your account</RouterLinkWrapper>
						}
					</div>
				</div>
			</>
		);
	}

}

export const ErrorLayout = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);