import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		title: {
			alignItems: "center",
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
				cursor: "pointer"
			},
		},
		container: {
			alignItems: "center",
		},
		stats: {
			textAlign: "center",
		},
		stat: {
		},
		actions: {
			textAlign: "right",
			
		},
		actionButtons: {
			// marginLeft: 10,
		},
	});
