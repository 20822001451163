import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		iconContainer: {
			display: "flex",
			flex: "0 0 50px",
			justifyContent: "center",
		},
		actionContainer: {
			display: "flex",
			flex: "0 0 50px",
			justifyContent: "center",
		}
	})
};
