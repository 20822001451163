import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		layout: {
			minHeight: "100vh",
			background: "radial-gradient(rgb(22, 25, 31) 0%, rgb(45, 50, 62) 80%)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			padding: "2em",
		},
		container: {
			background: "white",
			width: 380,
			borderRadius: 4,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			paddingLeft: 30,
			paddingRight: 30,
			paddingTop: 40,
			paddingBottom: 50,
		},
		logo: {
			maxHeight: 60,
			bottom: 50,
			marginTop: -60,
			position: "relative",
			maxWidth: "100%",
			objectFit: "contain",
			objectPosition: "bottom",
		},
		header: {
			marginBottom: 40,
		}
	});
};