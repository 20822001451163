class Type {

	public static WORD_PRESS = new Type(
		"wordpress",
		"WordPress",
		"https://example.com",
	);

	public static SQUARESPACE = new Type(
		"squarespace",
		"Squarespace",
		"https://example.com",
	);

	public static WIX = new Type(
		"wix",
		"Wix",
		"https://example.com",
	);

	public static WEEBLY = new Type(
		"weebly",
		"Weebly",
		"https://example.com",
	);

	public static JOOMLA = new Type(
		"joomla",
		"Joomla!",
		"https://example.com",
	);

	public static DRUPAL = new Type(
		"drupal",
		"Drupal",
		"https://example.com",
	);

	public static GO_DADDY = new Type(
		"godaddy",
		"GoDaddy Website Builder",
		"https://example.com",
	);

	public static CUSTOM = new Type(
		"custom",
		"Custom HTML",
		"https://example.com",
	);

	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly kb: string,
	) {
	}

	public get wordpress() {
		return this === Type.WORD_PRESS;
	}

	public get squarespace() {
		return this === Type.SQUARESPACE;
	}

	public get wix() {
		return this === Type.WIX;
	}

	public get promoted() {
		return this.wordpress || this.squarespace || this.wix;
	}

	public get textEmbedCode() {
		return this.wix;
	}

	public static get autocomplete() {
		return [
			this.CUSTOM,
			this.WEEBLY,
			this.JOOMLA,
			this.DRUPAL,
			this.GO_DADDY,
		];
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

	public static getOrCreateById(id: string) {
		return this.getById(id) || new this(id, id, "");
	}

}

export { Type as WebsitePlatformType };