import React from "react";
import * as Mui from "@material-ui/core";
import { Avatar } from "component/shared/avatar";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { Lead } from "model/lead";
import { styles } from "./style";

interface Props extends
	Mui.WithStyles<typeof styles>,
	React.HTMLAttributes<HTMLDivElement>
{
	lead: Lead;
	diameter?: number;
}

const useStyles = makeStyles({
	avatar: (props: Props) => {
		const { lead } = props;
		return {
			backgroundColor: "#" + getColor(lead),
		};
	},
});


const Component = (props: Props) => {
	const { className, lead } = props;
	const classes = useStyles(props);
	return (
		<Avatar
			{...props}
			classes={{
				avatar: classNames(classes.avatar, className),
			}}
			phrase={lead.label}
		/>
	);
};

const getColor = (lead: Lead) => {
	const seed = lead.id;
	const colors = [
		"b2b2b2", // gray
		"4dbbe6", // blue
		"398ad6", // Sea Breeze
		"4dcdac", // teal
		"5eb95f", // green
		"90e871", // apple
		"41f4df", // mint
		"fe64af", // pink
		"e95759", // red
		"fa8a33", // orange
		"fdcc27", // yellow
		"ab47bc", // purple	
	];
	const i = Math.floor((Math.abs(Math.sin(seed) * colors.length)) % colors.length);
	const color = colors[i];
	return color;
}

export const LeadAvatar = Mui.withStyles(styles)(Component);