import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		header: {
			display: "flex",
			flex: 1,
			justifyContent: "space-between",
			alignItems: "center",
		},
	});
};
