import React from "react";
import * as Mui from "@material-ui/core";
import { CampaignSubscriptionsPanel } from "./campaign-subscriptions-panel";
import { MarketReportSubscriptionsPanel } from "./market-report-subscriptions-panel";
import { SavedSearchesPanel } from "./saved-searches-panel";
import { Lead } from "model/lead";
import { CampaignDialog } from "./campaign-dialog";
import { MarketDialog } from "./market-dialog";
import { User } from "model/user";

interface Props {
	user: User;
	lead: Lead;
	showPropertyOrganizer: Function;
}

interface State {
	campaignDialogIsOpen: boolean;
	marketDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			campaignDialogIsOpen: false,
			marketDialogIsOpen: false,
		};
	}

	public render() {
		const { user, lead, showPropertyOrganizer } = this.props;
		const { campaignDialogIsOpen, marketDialogIsOpen } = this.state;
		return (
			<div style={{ paddingBottom: 12 }}>
				<CampaignDialog
					open={campaignDialogIsOpen}
					onClose={() => this.setState({ campaignDialogIsOpen: false })}
					lead={lead}
				/>
				<MarketDialog
					open={marketDialogIsOpen}
					onClose={() => this.setState({ marketDialogIsOpen: false })}
					lead={lead}
				/>
				{user.permissions.campaigns && (
					<>
						<Mui.Grid container spacing={1} justifyContent="space-between">
							<Mui.Grid item xs={12} sm="auto">
								<Mui.Typography variant="h3">
									Marketing Automation Campaigns
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item xs={12} sm="auto">
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => this.setState({ campaignDialogIsOpen: true })}
								>
									<Mui.Typography>Add New</Mui.Typography>
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						<div style={{ marginTop: 8, marginBottom: 48 }}>
							<CampaignSubscriptionsPanel lead={lead} />
						</div>
					</>
				)}
				{user.permissions.report && (
					<>
						<Mui.Grid container spacing={1} justifyContent="space-between">
							<Mui.Grid item xs={12} sm="auto">
								<Mui.Typography variant="h3">Market Subscriptions</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item xs={12} sm="auto">
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => this.setState({ marketDialogIsOpen: true })}
								>
									<Mui.Typography>Add New</Mui.Typography>
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						<div style={{ marginTop: 8, marginBottom: 48 }}>
							<MarketReportSubscriptionsPanel lead={lead} />
						</div>
					</>
				)}
				{user.permissions.leadEmailUpdates && (
					<>
						<Mui.Grid container spacing={1} justifyContent="space-between">
							<Mui.Grid item xs={12} sm="auto">
								<Mui.Typography variant="h3">Email Alert Activity</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item xs={12} sm="auto">
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => showPropertyOrganizer()}
								>
									<Mui.Typography>Add New</Mui.Typography>
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						<div style={{ marginTop: 8, marginBottom: 48 }}>
							<SavedSearchesPanel
								lead={lead}
								showPropertyOrganizer={showPropertyOrganizer}
							/>
						</div>
					</>
				)}
			</div>
		);
	}

}

export const SubscriptionsTab = Component;
