import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as env from "shared/env";
import * as credentials from "shared/credentials";
import { getUserUnsafe } from "redux/selector";
import { MockIframe } from "../mock";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUserUnsafe(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	url: string;
	fullScreen? : boolean;
}

interface State {
	loading: boolean;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { loading: true };
	}

	public render() {
		const { user, url, fullScreen } = this.props;
		if (!env.live) {
			return <MockIframe />;
		}
		const creds = credentials.get();
		if (!user || !creds) {
			throw new Error();
		}
		if (user.admin || (user.permissions.oneCrm && user.type.agent)) {
			const { username, password } = creds;
			let style = (fullScreen) ? { height: "100%", width: "100%" } : {};
			let returnUrl = encodeURI(url);
			let encodedUser = encodeURIComponent(username);
			let encodedPassword = encodeURIComponent(password);

			let authenticatePath = `${
				env.oneCrmUrl
			}/admin/user/authenticate?user=${encodedUser}&token=${encodedPassword}&returnUrl=${returnUrl}&cid=${user.clientId}`;
			return (
				<>
					<iframe
						name="contentIframe"
						title="iframe"
						src={authenticatePath}
						style={style}
						onLoad={() => this.setState({ loading: false })}
					/>
					{this.state.loading && <LoadingIndicator />}
				</>
			);
		}
	}
}

export const OneCrmIframe = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Component);
