import { BoardResource } from "api/resource/boards";

export const boards: BoardResource[] = [
	{
		id: 1,
		isCanadian: true,
		externalLinkDisplayType: 0,
		isContiguous: false,
		shortName: "ABCD LLC",
		allowsSupplementalListings: true
	},
	{
		id: 2,
		isCanadian: true,
		externalLinkDisplayType: 0,
		isContiguous: false,
		shortName: "EFGH LLC",
		allowsSupplementalListings: true
	},
	{
		id: 3,
		isCanadian: true,
		externalLinkDisplayType: 0,
		isContiguous: false,
		shortName: "IJKL LLC",
		allowsSupplementalListings: false
	},
	{
		id: 4,
		isCanadian: true,
		externalLinkDisplayType: 0,
		isContiguous: false,
		shortName: "MNOP LLC",
		allowsSupplementalListings: true

	}
];