class Type {
	public static OFF = new Type(
		0,
		"Off, do not display any MLS-provided Open House information"
	);
	public static OWN_LISTINGS = new Type(
		1,
		"On, but only display Open House information for my own listings"
	);
	public static ALL_LISTINGS = new Type(
		2,
		"On, display Open House information for all IDX listings"
	);

	private constructor(
		public readonly id: number,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.OFF;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: number) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: number): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as OpenHouseDisplayType };
