import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		header: {
			margin: 0,
		},
		criteriaLabel: {
			display: "inline-block",
			fontWeight: "bold",
			marginRight: 10,
			fontSize: "0.9em",
		},
		criterion: {
			marginRight: 7,
			borderRadius: 5,
		},
	});
};
