import { OfficeBoardResource } from "api/resource/office-board";

export const officeBoards: OfficeBoardResource[] = [
	{
		id: 1,
		boardId: 1,
		officeCode: "123",
		officeId: 1,
	},
	{
		id: 2,
		boardId: 2,
		officeCode: "223",
		officeId: 1,
	},
	{
		id: 3,
		boardId: 3,
		officeCode: "323",
		officeId: 1,
	},
	{
		id: 4,
		boardId: 4,
		officeCode: "423",
		officeId: 2,
	}
];