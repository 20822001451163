import { createStyles, Theme } from "@material-ui/core";
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = (theme: Theme) => {
	const tableFont = theme.typography.fontSize - 2;
	return createStyles({
		table: {
			fontSize: tableFont,
			"& th": {
				fontSize: tableFont,
			},
			"& td": {
				fontSize: tableFont,
			}
		},
		address: {
			fontSize: tableFont,
		},
		tooltip: {
			fontSize: tableFont - 1,
		},
		errorBackground: {
			padding: 10, 
			backgroundColor: alpha(theme.palette.error.main, 0.11),
		},
		warningBackground: {
			padding: 10, 
			backgroundColor: alpha(theme.palette.warning.main, 0.11),
		}
	});
};
