import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		
		container: {
			[theme.breakpoints.down("sm")]: {
				height: "100%",
			},
		},
		errorMessage: {
			color: theme.palette.error.main,
			fontSize: "0.75rem",
			fontWeight: "bold",
		},
		error: {
			color: "#f44336",
			margin: 0,
			fontSize: "0.75rem",
			marginTop: 3,
			textAlign: "left",
		}
	});
};
