import { Theme, createStyles } from "@material-ui/core"

export const styles = (theme: Theme) => {
	return createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		title: {
			color: theme.palette.primary.contrastText,
			fontSize: theme.typography.h4.fontSize,
		},
	})
}