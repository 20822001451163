class Type {
	
	public static SIDEBAR_RIGHT = new Type("right", "Sidebar to right of main content", 0);
	public static SIDEBAR_LEFT = new Type("left", "Sidebar to left of main content", 1)
	public static SIDEBAR_OFF = new Type("off", "Sidebar off", 2)
	
	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly displayOrder: number,
	) {
	}

	public static get default(): Type {
		return this.SIDEBAR_RIGHT;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static valuesSorted(): Type[] {
		return this.values().sort((a,b) => b.displayOrder - a.displayOrder);
	}

	public static getById(id?: string) {
		return this.values().find(value => value.id === id);
	}

	public static getByIdOrDefault(id?: string): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as LeadCaptureSidebarPositionType };