import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		label: {
			whiteSpace: "nowrap",

		},
		minute: {
			textAlign: "left"
		},
		field: {
			minWidth: 55
		}
	});
