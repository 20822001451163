import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		card: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		cardActions: {
			flex: 1,
			alignItems: "flex-end",
		},
		icon: {
			width: 24,
			paddingTope: 2,
		},
	});
};
