class Type {
	
	public static HOME = new Type(1, "Home");
	public static MOBILE = new Type(2, "Mobile")
	public static OFFICE = new Type(3, "Office");
	
	private constructor(
		public readonly id: number,
		public readonly label: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as LeadPhoneType };