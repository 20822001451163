import { AgentBoardResource } from "api/resource/agent-board";

export const agentBoards: AgentBoardResource[] = [
	{
		id: 1,
		agentId: 1,
		agentCode: "007",
		boardId: 1,
	},
	{
		id: 3,
		agentId: 1,
		agentCode: "808",
		boardId: 2,
	},
	{
		id: 3,
		agentId: 2,
		agentCode: "404",
		boardId: 3
	}
];