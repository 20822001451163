import React from "react";
import * as Mui from "@material-ui/core";

interface Props {
	endAdornment?: React.ReactNode;
	error?: boolean;
	errorText?: string;
	label: string;
	onChange: (value: string) => void;
	placeholder?: string;
	sidebarNote?: React.ReactNode;
	value: string;
}

class Component extends React.Component<Props> {
	render() {
		const {
			endAdornment,
			error,
			errorText,
			label,
			onChange,
			placeholder,
			sidebarNote,
			value,
		} = this.props;
		return (
			<Mui.Grid container direction="row" spacing={2}>
				<Mui.Grid item xs>
					<Mui.TextField
						InputProps={{
							endAdornment,
						}}
						error={error}
						fullWidth
						helperText={errorText}
						label={label}
						onChange={(event) => {
							onChange(event.target.value);
						}}
						placeholder={placeholder}
						value={value}
						variant="outlined"
					/>
				</Mui.Grid>
				{sidebarNote !== false && (
					<Mui.Grid item xs>
						{sidebarNote && (
							<Mui.Typography>
								<strong>Note:</strong>
								{" "}
								{sidebarNote}
							</Mui.Typography>
						)}
					</Mui.Grid>
				)}
			</Mui.Grid>
		);
	}
}

export const TextFieldWithNote = Component;
