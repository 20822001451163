import { MarketReportSubscriptionResource } from "api/resource/market-report-subscription";

export const marketReportSubscriptions: MarketReportSubscriptionResource[] = [
	{
		id: 1322,
		leadId: 1,
		marketReportId: 14,
		inactive: false,
		createdOn: "2018-11-11T14:46:41.723-08:00",
	},
	// {
	// 	id: 1323,
	// 	leadId: 1,
	// 	marketReportId: 15,
	// 	inactive: false,
	// 	createdOn: "2018-11-12T14:46:41.723-08:00",
	// },
	// {
	// 	id: 1324,
	// 	leadId: 1,
	// 	marketReportId: 16,
	// 	inactive: false,
	// 	createdOn: "2020-11-11T14:46:41.723-08:00",
	// },
	// {
	// 	id: 1325,
	// 	leadId: 7,
	// 	marketReportId: 12,
	// 	inactive: false,
	// 	createdOn: "2019-11-11T14:46:41.723-08:00",
	// },
	// {
	// 	id: 1326,
	// 	leadId: 7,
	// 	marketReportId: 13,
	// 	inactive: false,
	// 	createdOn: "2019-11-11T14:46:41.723-08:00",
	// },
	// {
	// 	id: 1327,
	// 	leadId: 7,
	// 	marketReportId: 13,
	// 	inactive: false,
	// 	createdOn: "2019-11-11T14:46:41.723-08:00",
	// },

];