class Type {
	
	public static ACTION_ITEM = new Type(1, "Action Item", "#ab47bc");
	public static APPOINTMENT = new Type(2, "Appointment", "#398ad6")
	public static CALL_BACK = new Type(3, "Call Back", "#4dcdac");
	public static EMAIL = new Type(4, "Email", "#fa8a33");
	
	private constructor(
		public readonly id: number,
		public readonly name: string,
		public readonly backgroundColor: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as TaskType };