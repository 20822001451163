import { ResellerResource } from "api/resource/reseller";

export const resellers: ResellerResource[] = [
	{
		id: 511,
		name: "iHomefinder",
		logo: "https://pix.idxre.com/pix/agentOffice/577_clientcplogo_logo_577.png",
		direct: true,
		css: `
			#ih-cpnav-leads {display:none !important;}
			#ih-cpnav-leadexport {display:none !important;}
			#ih-cpnav-listingsettings {display:none !important;}
			#ih-cpnav-designbranding {display:none !important;}
			#ih-cpnav-mlssetup {display:none !important;}
		`,
	},
	{
		id: 1,
		name: "Company",
		logo: "https://dynamic.brandcrowd.com/asset/logo/62fdfa68-3a48-46da-9959-be64842d7c47/logo?v=4",
		direct: true,
		primaryColor: "#1da0f3",
		secondaryColor: "#c5c5c5",
	},
	{
		id: 2,
		name: "Company",
		logo: "https://dynamic.brandcrowd.com/asset/logo/d68dfd75-aeba-43c1-9f11-25702e971329/logo?v=4",
		direct: false,
	},
	{
		id: 3,
		name: "Company",
		direct: true,
	},
];