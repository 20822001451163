import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		cardContent: {
			// flexGrow: 1,
		},
		title: {
			alignItems: "center",
		},
		container: {
			alignItems: "center",
		},
		stats: {
			flexGrow: 1,
			textAlign: "center",
		},
		stat: {
			
		},
		actions: {
			textAlign: "right",
		},
		actionButtons: {
			marginLeft: 10,
		},
		eee: {
			backgroundColor: "#eee"
		},
		fff: {
			backgroundColor: "#fff"
		},
	});
