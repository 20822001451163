import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "../style";

interface Props extends Mui.WithStyles<typeof styles> {
	color: string;
	value: number;
	text: string;
}

const Component = (props: Props) => {
	const { color, value, text, classes } = props;
	return (
		<Mui.Grid item className={classes.row}>
			<Mui.Avatar className={classes.avatar} style={{ backgroundColor: color }}>
				{value}
			</Mui.Avatar>
			<Mui.Typography variant="h4">{text}</Mui.Typography>
		</Mui.Grid>
	);
}

export const Left = Mui.withStyles(styles)(Component);