import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		title: {
			color: theme.palette.primary.contrastText,
			fontSize: theme.typography.h4.fontSize,
		},
		newButtonAction: {
			marginTop: 0,
		},
		newButtonTitle: {
			color: theme.palette.primary.contrastText,
		},
		buttonRoot: {
			display: "inline-block",
			padding: "0px 8px",
			minHeight: 0,
			minWidth: 0,
		},
		cardActions: {
			justifyContent: "center",
		}
	});
};
