import { MarketResource } from "api/resource/market";
import { listings } from "./listings";

export const markets: MarketResource[] = [
	{
		id: 1001,
		name: "Hot Berkeley Condos",
		listingReportSupported: true,
		openHomesReportSupported: true,
		marketReportSupported: true,
		showOnIndex: true,
		inactive: false,
		criteria: [
			{
				name: "cityId",
				label: "Cities",
				area: true,
				value: [
					1,
					2,
				],
			},
			{
				name: "maxListPrice",
				label: "Max. Price",
				value: 500000,
			},
			{
				name: "something",
				label: "Some. thing",
				value: 1,
			},
			{
				name: "something2",
				label: "Some. thing2",
				value: 12,
			},
			{
				name: "something3",
				label: "Some. thing3",
				value: 13,
			},
			{
				name: "something4",
				label: "Some. thing4",
				value: 14,
			},
			{
				name: "something5",
				label: "Some. thing5",
				value: 15,
			},
		],
	},
	{
		id: 1002,
		name: "Hot San Francisco Condos",
		showOnIndex: true,
		inactive: false,
		criteria: [
			{
				name: "cityId",
				label: "Cities",
				area: true,
				value: [
					5,
					6,
				],
			},
		],
		listingReportSupported: true,
		openHomesReportSupported: true,
		marketReportSupported: true,
	},
	{
		id: 1003,
		name: "Indonesia",
		showOnIndex: true,
		inactive: false,
		criteria: [
			{
				name: "cityId",
				label: "Cities",
				area: true,
				value: [
					3,
					4,
				],
			},
		],
		listingReportSupported: true,
		openHomesReportSupported: true,
		marketReportSupported: true,
	},
	{
		id: 1004,
		name: "Moore's Market",
		showOnIndex: true,
		inactive: false,
		criteria: [
			{
				name: "cityId",
				label: "Cities",
				area: true,
				value: [
					3,
					4,
				],
			},
		],
		listingReportSupported: false,
		openHomesReportSupported: false,
		marketReportSupported: false,
	},
	{
		id: 1005,
		name: "Manual Market",
		showOnIndex: true,
		inactive: false,
		criteria: [
			{
				name: "ids",
				label: "ids",
				value: [
					listings[0].listingNumber,
				] as string[],
			},
		],
		listingReportSupported: true,
		openHomesReportSupported: false,
		marketReportSupported: false,
	},
];