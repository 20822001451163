import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		tag: {
			margin: 3,
		},
		moreTag: {
			minWidth: 38,
		},
		minButton: {
			minWidth: 0,
			color: "#aaa",
		},
	});
};