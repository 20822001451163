import { CityResource } from "api/resource/city";

export const cities: CityResource[] = [
	{
		id: 1,
		name: "Berkeley",
	},
	{
		id: 2,
		name: "Oakland",
	},
	{
		id: 3,
		name: "Bandung",
	},
	{
		id: 4,
		name: "Jakarta",
	},
	{
		id: 5,
		name: "San Francisco",
	},
	{
		id: 6,
		name: "Sausalito",
	},
];