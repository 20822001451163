import * as Mui from "@material-ui/core";
import { Dialog } from "component/shared/dialog";
import { Agent } from "model/agent";
import { styles } from "../style";

interface Props extends Mui.WithStyles<typeof styles> {
	agent: Agent;
	deleteAgentDialogIsOpen: boolean;
	hideDeleteAgentDialog: Function;
	deleteAgent: Function;
}

const component = (props: Props) => {
	const {
		agent,
		classes,
		deleteAgent,
		deleteAgentDialogIsOpen,
		hideDeleteAgentDialog,
	} = props;

	return (
		<Dialog
			open={deleteAgentDialogIsOpen}
			onClose={() => hideDeleteAgentDialog()}
		>
			<Mui.DialogTitle>Delete Contact</Mui.DialogTitle>
			<Mui.DialogContent className={classes.dialogContent}>
				<Mui.DialogContentText>
					{`Are you sure you want to delete ${agent.firstName} ${agent.lastName}?`}
				</Mui.DialogContentText>
			</Mui.DialogContent>
			<Mui.DialogActions className={classes.dialogActions}>
				<Mui.Button
					onClick={() => deleteAgent()}
					variant="contained"
					color="secondary"
				>
					Yes
				</Mui.Button>
				<Mui.Button onClick={() => hideDeleteAgentDialog()} variant="outlined">
					No
				</Mui.Button>
			</Mui.DialogActions>
		</Dialog>
	);
};

export const DeleteAgentDialog = Mui.withStyles(styles)(component);
