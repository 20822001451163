class Type {
	public static AGENT_OFFICE = new Type(1, "Agent/Office Links");
	public static LISTING_RELATED = new Type(2, "Listing Related Links");
	public static OPTIONAL_ADDON = new Type(3, "Optional Add-On Pages");
	public static MOBILE = new Type(4, "Mobile Links");
	public static NONE = new Type(5, "");

	private constructor(
		public readonly order: number, 
		public readonly label: string,
	) {
	}

	
	public static values(): Type[] {
		return Object.values(Type);
	}
}

export { Type as SiteLinkGroupType};