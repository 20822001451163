import React from "react";
import { OneCrmIframe } from "component/shared/iframe/onecrm";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		return (
			<Mui.Dialog fullScreen open={true}>
				<OneCrmIframe url="/admin/calendar/index" fullScreen />
			</Mui.Dialog>
		);
	}

}

export const OneCalendarPage = Component;
