import React from "react";
import * as Router from "react-router-dom";

interface RouteParams {
	id: string;
}

interface Props extends Router.RouteComponentProps<RouteParams> {
}

class Component extends React.Component<Props> {
	componentDidUpdate(prevProps: Props) {
		if (
			this.props.location.pathname !== prevProps.location.pathname
		) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return null;
	}
}

export const ScrollToTop = Router.withRouter(Component);