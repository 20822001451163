import numeral from "numeral";

export const formatPrice = (price: any) => {
	let result: string = "";
	if (price !== undefined && price !== null) {
		if (price >= 1000000) {
			result = numeral(price).format("$(0.00a)");
		} else if (price >= 1000) {
			result = numeral(price).format("$(0a)");
		} else {
			result = numeral(price).format("$0,0.00");
		}
	}
	return result;
}