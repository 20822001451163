import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import * as yup from "yup";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { DashboardLayout } from "component/layout/dashboard";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { saveUser } from "redux/slice/authentication";
import { isCanadianOnly } from "redux/selector";
import { RootState } from "redux/store";
import { PageProps } from "shared/page-props";
import { validate } from "shared/yup";
import { styles } from "./style";

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		Mui.WithStyles,
		WithMediaQuery,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}

interface State {
	canceling: boolean;
	defaultInterestRate: number;
	defaultTerm: number;
	downPaymentPercentage: number;
	includeMonthlyInsurance: boolean;
	includeMonthlyTax: boolean;
	monthlyInsurance: number;
	monthlyTaxPercentage: number;
	saving: boolean;
	submitted: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = this.getInitialState();
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.isCanadianOnly !== prevProps.isCanadianOnly) {
			this.setState(this.getInitialState());
		}
	}

	private decimalToPercent = (value: number) =>
		parseFloat((value * 100).toFixed(4));

	private percentToDecimal = (value: number) => value / 100;

	private closeSnackbar = (event: any) => {
		this.setState({ canceling: false, saving: false });
	};

	private onCancel = () => {
		this.setState({
			...this.getInitialState(),
			canceling: true,
		});
	};

	private onSubmit = () => {
		const {
			defaultInterestRate,
			defaultTerm,
			downPaymentPercentage,
			includeMonthlyInsurance,
			includeMonthlyTax,
			monthlyInsurance,
			monthlyTaxPercentage,
		} = this.state;
		const { saveUser, user } = this.props;

		this.setState({ saving: true, submitted: true });
		saveUser({ user: {
			...user,
			defaultInterestRate: this.percentToDecimal(defaultInterestRate),
			defaultTerm,
			downPaymentPercentage: this.percentToDecimal(downPaymentPercentage),
			includeMonthlyInsurance,
			includeMonthlyTax,
			monthlyInsurance: this.percentToDecimal(monthlyInsurance),
			monthlyTaxPercentage: this.percentToDecimal(monthlyTaxPercentage),
		}});
	};

	private getInitialState = () => {
		const { user } = this.props;
		const {
			defaultTerm,
			defaultInterestRate,
			downPaymentPercentage,
			monthlyTaxPercentage,
			includeMonthlyTax,
			monthlyInsurance,
			includeMonthlyInsurance,
		} = user;

		return {
			defaultTerm,
			defaultInterestRate: this.decimalToPercent(defaultInterestRate),
			downPaymentPercentage: this.decimalToPercent(downPaymentPercentage),
			monthlyTaxPercentage: this.decimalToPercent(monthlyTaxPercentage),
			includeMonthlyTax,
			monthlyInsurance: this.decimalToPercent(monthlyInsurance),
			includeMonthlyInsurance,
			saving: false,
			canceling: false,
			submitted: false,
		};
	};

	private getValidationSchema = () => {
		const { isCanadianOnly } = this.props;

		const defaultInterestRateErrorMessage =
			"Default interest rate should be a number between 0 and 100.";
		const defaultTermErrorMessage = `Default ${
			isCanadianOnly ? "amortization period" : "term"
		} should be a whole number.`;
		const downPaymentPercentageErrorMessage =
			"Down payment percentage should be a number between 0 and 100.";
		const monthlyInsuranceErrorMessage =
			"Monthly insurance should be a number between 0 and 100.";
		const monthlyTaxPercentageErrorMessage =
			"Monthly tax percentage should be a number between 0 and 100.";

		return {
			defaultInterestRate: yup
				.number()
				.typeError(defaultInterestRateErrorMessage)
				.min(0, defaultInterestRateErrorMessage)
				.max(100, defaultInterestRateErrorMessage)
				.required("Default interest rate is required."),
			defaultTerm: yup
				.number()
				.typeError(defaultTermErrorMessage)
				.integer(defaultTermErrorMessage)
				.required(
					`Default ${isCanadianOnly ? "amortization period" : "term"} is required.`
				),
			downPaymentPercentage: yup
				.number()
				.typeError(downPaymentPercentageErrorMessage)
				.min(0, downPaymentPercentageErrorMessage)
				.max(100, downPaymentPercentageErrorMessage)
				.required("Down payment percentage is required."),
			includeMonthlyInsurance: yup.boolean(),
			includeMonthlyTax: yup.boolean(),
			monthlyInsurance: yup
				.number()
				.typeError(monthlyInsuranceErrorMessage)
				.min(0, monthlyInsuranceErrorMessage)
				.max(100, monthlyInsuranceErrorMessage)
				.required("Monthly insurance percentage is required."),
			monthlyTaxPercentage: yup
				.number()
				.typeError(monthlyTaxPercentageErrorMessage)
				.min(0, monthlyTaxPercentageErrorMessage)
				.max(100, monthlyTaxPercentageErrorMessage)
				.required("Monthly tax percentage is required."),
		};
	};

	renderInputRow = (
		endAdornment: "year term" | "%",
		errorMessage: string | false,
		label: string,
		onChange: (event: any) => void,
		value: number,
		note?: string
	) => {
		const { classes, mediaQuery } = this.props;
		const { submitted } = this.state;
		const noteContainerStyle = mediaQuery
			? { marginTop: 6, paddingLeft: 12 }
			: { paddingLeft: 12 };

		return (
			<Mui.Grid alignItems="center" container direction="row" item xs={12}>
				<Mui.Grid item xs={12} md={6} className={classes.textFieldContainer}>
					<Mui.TextField
						error={submitted && !!errorMessage}
						fullWidth
						helperText={submitted && errorMessage}
						label={label}
						value={value}
						onChange={(event) => onChange(event.currentTarget.value)}
						InputProps={{
							endAdornment: endAdornment && (
								<Mui.InputAdornment position="start">{endAdornment}</Mui.InputAdornment>
							),
						}}
						size="small"
						variant="outlined"
					/>
				</Mui.Grid>
				{note && (
					<Mui.Grid
						item
						xs={12}
						md={6}
						className={classes.note}
						style={noteContainerStyle}
					>
						<strong>Note: </strong>
						{note}
					</Mui.Grid>
				)}
			</Mui.Grid>
		);
	};

	renderRadioButtonRow = (
		label: string,
		onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
		value: boolean
	) => {
		const { classes } = this.props;
		return (
			<Mui.Grid alignItems="center" container direction="row" item xs={12}>
				<Mui.Grid item xs={6} md={3} className={classes.radioLabel}>
					{label}
				</Mui.Grid>
				<Mui.Grid item xs={6} md={3}>
					<Mui.RadioGroup name={label} value={value} onChange={onChange} row>
						<Mui.FormControlLabel
							value={true}
							control={
								<Mui.Radio
									classes={{ root: classes.radio, checked: classes.radioChecked }}
								/>
							}
							label="On"
						/>
						<Mui.FormControlLabel
							value={false}
							control={
								<Mui.Radio
									classes={{ root: classes.radio, checked: classes.radioChecked }}
								/>
							}
							label="Off"
						/>
					</Mui.RadioGroup>
				</Mui.Grid>
			</Mui.Grid>
		);
	};

	public render() {
		const title = "Mortgage Calculator Settings";
		const {
			canceling,
			defaultInterestRate,
			defaultTerm,
			downPaymentPercentage,
			includeMonthlyInsurance,
			includeMonthlyTax,
			monthlyInsurance,
			monthlyTaxPercentage,
			saving,
		} = this.state;
		const { classes, isCanadianOnly, userError, userLoading } = this.props;
		const errors = validate(this.getValidationSchema(), this.state);

		return (
			<DashboardLayout
				fullScreen={false}
				title="Mortgage Calculator Settings"
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid
						container
						direction="column"
						spacing={4}
						style={{ marginRight: -100 }}
					>
						{this.renderInputRow(
							"year term",
							!!errors && errors.defaultTerm,
							`Default ${
								isCanadianOnly ? "Amortization Period" : "Term"
							} in Mortgage Calculator`,
							(defaultTerm) => this.setState({ defaultTerm }),
							defaultTerm
						)}
						{this.renderInputRow(
							"%",
							!!errors && errors.defaultInterestRate,
							"Default Interest Rate in Mortgage Calculator",
							(defaultInterestRate) => this.setState({ defaultInterestRate }),
							defaultInterestRate
						)}
						{this.renderInputRow(
							"%",
							!!errors && errors.downPaymentPercentage,
							"Default Down Payment Percentage in Mortgage Calculator",
							(downPaymentPercentage) => this.setState({ downPaymentPercentage }),
							downPaymentPercentage,
							"Down payment is calculated as a percentage of the asking price."
						)}
						{this.renderInputRow(
							"%",
							!!errors && errors.monthlyTaxPercentage,
							"Default Monthly Tax Percentage in Mortgage Calculator",
							(monthlyTaxPercentage) => this.setState({ monthlyTaxPercentage }),
							monthlyTaxPercentage,
							"Monthly tax is calculated as a percentage of the total asking price divided by 12 months."
						)}
						{this.renderRadioButtonRow(
							"Include Monthly Tax in Mortgage Calculations",
							(event) =>
								this.setState({
									includeMonthlyTax: event.target.value === "true",
								}),
							includeMonthlyTax
						)}
						{this.renderInputRow(
							"%",
							!!errors && errors.monthlyInsurance,
							"Default Monthly Insurance in Mortgage Calculator",
							(monthlyInsurance) => this.setState({ monthlyInsurance }),
							monthlyInsurance,
							"Default monthly insurance is calculated as a percentage of the total asking price divided by 12 months."
						)}
						{this.renderRadioButtonRow(
							"Include Monthly Insurance in Mortgage Calculations",
							(event) =>
								this.setState({
									includeMonthlyInsurance: event.target.value === "true",
								}),
							includeMonthlyInsurance
						)}
						<Mui.Snackbar
							open={canceling || (saving && !userError && !userLoading)}
							message={saving ? "Changes saved" : "Changes reverted"}
							autoHideDuration={6000}
							onClose={this.closeSnackbar}
							action={
								<Mui.IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={this.closeSnackbar}
								>
									<Icons.X fontSize="small" />
								</Mui.IconButton>
							}
						/>
					</Mui.Grid>
					<Mui.CardActions className={classes.cardActions}>
						<Mui.Button
							color="secondary"
							disabled={!!errors}
							onClick={this.onSubmit}
							variant="contained"
						>
							Save Changes
						</Mui.Button>
						<Mui.Button
							onClick={this.onCancel}
							style={{ marginLeft: 32 }}
							variant="outlined"
						>
							Cancel
						</Mui.Button>
					</Mui.CardActions>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

const mapStateToProps = (state: RootState) => {
	return {
		isCanadianOnly: isCanadianOnly(state),
		userError: state.authentication.error,
		userLoading: state.authentication.loading,
	};
};

export const SettingsMortgageCalculator = connect(
	mapStateToProps,
	mapDispatchToProps
)(
	Mui.withStyles(styles)(
		Mui.withStyles(styles)(withMediaQuery("(max-width:960px")(Component))
	)
);
