import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { ColdFusionIframe } from "component/shared/iframe/coldfusion";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		const { user } = this.props;
		return (
			<DashboardLayout
				permitted={user.admin || (user.type.agent && user.permissions.modifyBio)}
				title="Modify Bio"
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						Modify Bio
					</Mui.Typography>
				}
				iframe={<ColdFusionIframe url="/agentmodify.cfm" />}
			/>
		);
	}

}

export const AgentBio = Component;