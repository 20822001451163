import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		wrapper: {
			position: "relative",
		},
		popper: {
			zIndex: 10,
		},
		card: {
			width: 404,
		},
		input: {
			paddingTop: 33.5,
			height: 4,
		},
		button: {
			minWidth: "unset",
			width: 33,
			height: 33,
			padding: 0,
		},
	});
};
