import React from "react";
import * as Mui from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { styles } from "./style";

interface Props
	extends Mui.WithStyles<typeof styles>,
		React.HTMLAttributes<HTMLDivElement> {
	phrase: string;
	diameter?: number;
	src?: string;
}

const getLetters = (phrase: string) => {
	return phrase
		.split(" ")
		.filter((word, index, array) => {
			return index === 0 || index === array.length - 1;
		})
		.map((word) => {
			return word.charAt(0).toUpperCase();
		})
		.join("");
};

const useStyles = makeStyles({
	avatar: (props: Props) => {
		const diameter = props.diameter || 36;
		return {
			width: diameter,
			height: diameter,
			fontSize: diameter * 0.5,
		};
	},
});

const Component = (props: Props) => {
	const { className, src, phrase } = props;
	const classes = useStyles(props);
	const letters = getLetters(phrase);
	return (
		<Mui.Avatar
			{...props}
			classes={{
				root: classNames(classes.avatar, className),
			}}
			src={src}
		>
			{letters}
		</Mui.Avatar>
	);
};

export const Avatar = Mui.withStyles(styles)(Component);
