import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import classNames from "classnames";

interface OwnProps {
	desc?: boolean,
}

type Props = OwnProps & Mui.WithStyles<typeof styles> & Mui.TableCellProps;

class Component extends React.Component<Props> {

	public render() {
		const { classes, desc, ...others } = this.props;
		return (
			<Mui.TableCell
				{...others}
				classes={{
					root: classNames(
						classes.root,
						desc === false && classes.asc,
						desc === true && classes.desc,
					),
				}}
			/>
		);
	}
}

export const SortCell = Mui.withStyles(styles)(Component);