import { AggregateStatsResource } from "api/resource/aggregate-stats";

export const aggregateStats: AggregateStatsResource[] = [
	{
		id: "1031_1",
		dateStamp: "2018-01-01T00:00:00.000-07:00",
		moreInfoRequests: 89,
		userRegistrations: 44,
		newSubscribers: 48,
		listingSaves: 502,
		newListingSearchSaves: 35,
		totalListingSearchSaves: 3005,
		listingViews: 169766,
		listingSearches: 107878,
		listingPolygonSearches: 1334
	},
	{
		id: "1031_2",
		dateStamp: "2018-02-01T00:00:00.000-07:00",
		moreInfoRequests: 122,
		valuationRequests: 12,
		userRegistrations: 58,
		newSubscribers: 55,
		listingSaves: 287,
		newListingSearchSaves: 44,
		totalListingSearchSaves: 3005,
		listingViews: 185532,
		listingSearches: 97581,
		listingPolygonSearches: 1343
	},
	{
		id: "1031_3",
		dateStamp: "2018-03-01T00:00:00.000-07:00",
		moreInfoRequests: 105,
		valuationRequests: 26,
		newSubscribers: 62,
		listingSaves: 374,
		newListingSearchSaves: 35,
		totalListingSearchSaves: 3005,
		listingViews: 183019,
		listingSearches: 99298,
		listingPolygonSearches: 1272
	},
	{
		id: "1031_4",
		dateStamp: "2018-04-01T00:00:00.000-07:00",
		moreInfoRequests: 82,
		userRegistrations: 65,
		newSubscribers: 43,
		listingSaves: 343,
		newListingSearchSaves: 41,
		totalListingSearchSaves: 3005,
		listingViews: 184868,
		listingSearches: 109349,
		listingPolygonSearches: 1352
	},
	{
		id: "1031_5",
		dateStamp: "2018-05-01T00:00:00.000-07:00",
		moreInfoRequests: 89,
		userRegistrations: 44,
		newSubscribers: 48,
		listingSaves: 502,
		newListingSearchSaves: 35,
		totalListingSearchSaves: 3005,
		listingViews: 169766,
		listingSearches: 107878,
		listingPolygonSearches: 1334
	},
	{
		id: "1031_6",
		dateStamp: "2018-06-01T00:00:00.000-07:00",
		moreInfoRequests: 122,
		valuationRequests: 12,
		userRegistrations: 58,
		newSubscribers: 55,
		listingSaves: 287,
		newListingSearchSaves: 44,
		totalListingSearchSaves: 3005,
		listingViews: 185532,
		listingSearches: 97581,
		listingPolygonSearches: 1343
	},
	{
		id: "1031_7",
		dateStamp: "2018-07-01T00:00:00.000-07:00",
		moreInfoRequests: 105,
		valuationRequests: 26,
		newSubscribers: 62,
		listingSaves: 374,
		newListingSearchSaves: 35,
		totalListingSearchSaves: 3005,
		listingViews: 183019,
		listingSearches: 99298,
		listingPolygonSearches: 1272
	},
	{
		id: "1031_8",
		dateStamp: "2018-08-01T00:00:00.000-07:00",
		moreInfoRequests: 105,
		valuationRequests: 26,
		newSubscribers: 62,
		listingSaves: 374,
		newListingSearchSaves: 35,
		totalListingSearchSaves: 3005,
		listingViews: 183019,
		listingSearches: 99298,
		listingPolygonSearches: 1272
	},
	{
		id: "1031_9",
		dateStamp: "2018-09-01T00:00:00.000-07:00",
		moreInfoRequests: 105,
		valuationRequests: 26,
		newSubscribers: 62,
		listingSaves: 374,
		newListingSearchSaves: 35,
		totalListingSearchSaves: 3005,
		listingViews: 183019,
		listingSearches: 99298,
		listingPolygonSearches: 1272
	},
	{
		id: "1031_10",
		dateStamp: "2018-10-01T00:00:00.000-07:00",
		moreInfoRequests: 122,
		valuationRequests: 12,
		userRegistrations: 58,
		newSubscribers: 55,
		listingSaves: 287,
		newListingSearchSaves: 44,
		totalListingSearchSaves: 3005,
		listingViews: 185532,
		listingSearches: 97581,
		listingPolygonSearches: 1343
	},
	{
		id: "1031_11",
		dateStamp: "2018-11-01T00:00:00.000-07:00",
		moreInfoRequests: 42,
		userRegistrations: 35,
		newSubscribers: 23,
		listingSaves: 153,
		newListingSearchSaves: 21,
		totalListingSearchSaves: 1505,
		listingViews: 94868,
		listingSearches: 59349,
		listingPolygonSearches: 852
	},
];