import { LeadTagResource } from "api/resource/lead-tag";

export const leadTags: LeadTagResource[] = [
	{
		id: 1,
		leadId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Buyer",
	},
	{
		id: 2,
		leadId: 3,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Seller",
	},
	{
		id: 4,
		leadId: 4,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Has Loan",
	},
	{
		id: 5,
		leadId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Pre-Qualified $1M",
	},
	{
		id: 6,
		leadId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Has Active Listing",
	},
	{
		id: 7,
		leadId: 2,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Out of Area",
	},
	{
		id: 8,
		leadId: 4,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Starred",
	},
	{
		id: 9,
		leadId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Starred",
	},
	{
		id: 10,
		leadId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "VIP",
	},
	{
		id: 11,
		leadId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Brentwood",
	},
];