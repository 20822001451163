import React from "react";
import * as Mui from "@material-ui/core";
import { BaseProps } from "./types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styles } from "./style";

interface Props extends BaseProps, Mui.WithStyles<typeof styles> {
}

interface State {
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
		};
	}

	public render() {
		const { 
			options,
			optionsDisabled,
			classes, 
			placeholder, 
			error,
			helperText, 
			FormHelperTextProps,
			disableClearable,
			margin, 
			optionLabelExtractor, 
			fullWidth, 
			required, 
			disabled, 
			onInputChange, 
			onChange, 
			onFocus,
			label,
			textInputHeight, 
			inputValue,
			inputRef,
		} = this.props;
		return (
			<Autocomplete
				openOnFocus
				freeSolo
				autoHighlight
				disableClearable={disableClearable && disableClearable}
				options={options}
				getOptionDisabled={(option) => {
					return optionsDisabled ? optionsDisabled.includes(option) : false;
				}}
				classes={{
					focused: classes.focused,
					inputRoot: classes.textField
				}}
				renderInput={(params) => (
					<Mui.TextField
						{...params}
						InputProps={ 
							textInputHeight ?
							{
								...params.InputProps,
								className: classes.textField,
							}
							: 
							{ ...params.InputProps }
						}
						error={error}
						label={label}
						InputLabelProps={{
							shrink: true, 
						}}
						placeholder={placeholder}
						helperText={helperText}
						FormHelperTextProps={FormHelperTextProps}
						margin={margin}
						fullWidth={fullWidth}
						required={required}
						disabled={disabled}
						variant="outlined"
					/>
				)}
				getOptionLabel={optionLabelExtractor}
				onInputChange={onInputChange}
				onChange={onChange}
				onFocus={onFocus}
				inputValue={inputValue}
				ref={inputRef}
				style={{...this.props.style}}
			/>
		)
	}
}

export const CustomAutocomplete = Mui.withStyles(styles)(Component);