import React from "react";
import * as Icons from "react-feather";

class Type {
	
	public static NOTE = new Type(1, "Note", Icons.Edit2);
	public static PHONE_CALL = new Type(2, "Phone Call", Icons.PhoneCall);
	
	private constructor(
		public readonly id: number,
		public readonly label: string,
		public readonly icon: React.ComponentType<Icons.IconProps>
	) {
	}

	public get note() {
		return this === Type.NOTE;
	}

	public get phoneCall() {
		return this === Type.PHONE_CALL;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as LeadNoteType };