import { PostalCodeResource } from "api/resource/postal-code";

export const postalCodes: PostalCodeResource[] = [
	{
		id: 1,
		postalCode: "66262",
	},
	{
		id: 2,
		postalCode: "22222",
	},
	{
		id: 3,
		postalCode: "8973387",
	},
	{
		id: 4,
		postalCode: "8973387",
	}
];