import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { Table } from "component/shared/table";
import { Office } from "model/office";
import { User } from "model/user";
import { deleteOffice } from "redux/slice/offices";
import { RootState } from "redux/store";
import { OfficeColumnType } from "type/office-column";
import { AddOfficeDialog } from "../office-dialog/add-office-dialog";
import { DeleteOfficeDialog } from "./delete-dialog";
import { styles } from "./style";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	let columnTypes = [];
	columnTypes.push(OfficeColumnType.OFFICE_NAME);
	columnTypes.push(OfficeColumnType.ADDRESS);
	columnTypes.push(OfficeColumnType.CITY);
	columnTypes.push(OfficeColumnType.PRIORITY);
	columnTypes.push(OfficeColumnType.ACTION);
	return {
		columnTypes,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			deleteOffice,
		},
		dispatch
	);

interface Props
	extends OwnProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {}

interface OwnProps {
	offices: Office[];
	user: User;
}

interface State {
	addOfficeDialogIsOpen: boolean;
	deleteOfficeDialogIsOpen: boolean;
	officeToEdit: Office | null;
	officeToDelete: Office | null;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			addOfficeDialogIsOpen: false,
			deleteOfficeDialogIsOpen: false,
			officeToDelete: null,
			officeToEdit: null,
		};
	}

	private showAddOfficeDialog = () =>
		this.setState({ addOfficeDialogIsOpen: true, officeToEdit: null });

	private hideAddOfficeDialog = () =>
		this.setState({ addOfficeDialogIsOpen: false, officeToEdit: null });

	private showDeleteOfficeDialog = (office: Office) => {
		this.setState({
			officeToDelete: office,
			deleteOfficeDialogIsOpen: true,
		});
	};

	private hideDeleteOfficeDialog = () =>
		this.setState({
			officeToDelete: null,
			deleteOfficeDialogIsOpen: false,
		});

	private deleteOffice = () => {
		const { officeToDelete } = this.state;
		if (officeToDelete) {
			this.props.deleteOffice({ office: {
				...officeToDelete,
			}});
		}
		this.hideDeleteOfficeDialog();
	};

	public render() {
		const { offices, classes } = this.props;
		const {
			addOfficeDialogIsOpen,
			deleteOfficeDialogIsOpen,
			officeToDelete,
		} = this.state;
		return (
			<>
				<AddOfficeDialog
					open={addOfficeDialogIsOpen}
					onClose={this.hideAddOfficeDialog}
				/>
				{officeToDelete && (
					<DeleteOfficeDialog
						deleteOffice={this.deleteOffice}
						deleteOfficeDialogIsOpen={deleteOfficeDialogIsOpen}
						hideDeleteOfficeDialog={this.hideDeleteOfficeDialog}
						office={officeToDelete}
					/>
				)}
				<Mui.Grid container direction="column" spacing={4}>
					<Mui.Grid item>
						<Mui.Grid
							container
							item
							alignItems="center"
							justifyContent="space-between"
							spacing={4}
						>
							<Mui.Grid item>
								<Mui.Grid container spacing={5}>
									<Mui.Grid item>
										<Mui.Button
											variant="contained"
											color="secondary"
											onClick={() => this.showAddOfficeDialog()}
										>
											<Mui.Typography>Add New</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Table
							items={offices}
							columns={[
								{
									id: "officeName",
									title: OfficeColumnType.OFFICE_NAME.label,
									sort: (office1, office2) => {
										return office1.name.toLowerCase() < office2.name.toLowerCase()
										? -1
										: 1;										
									},
									value: (office) => {
										return (
											<Mui.Link
												className={classes.link}
												component={RouterLinkWrapper} to={urls.settingsOffice(office)}
											>
												{office.name}
											</Mui.Link>
										);
									},
								},
								{
									id: "address",
									title: OfficeColumnType.ADDRESS.label,
									sort: (office1, office2) => {
										return office1.address.toLowerCase() < office2.address.toLowerCase()
											? -1
											: 1;
									},
									value: (office) => {
										return office.address;
									},
								},
								{
									id: "city",
									title: OfficeColumnType.CITY.label,
									sort: (office1, office2) => {
										return office1.city.toLowerCase() < office2.city.toLowerCase()
											? -1
											: 1;
									},
									value: (office) => {
										return office.city;
									},
								},
								{
									id: "priority",
									title: OfficeColumnType.PRIORITY.label,
									sort: (office1, office2) => {
										return office1.priority - office2.priority;
									},
									value: (office) => {
										return office.priority;
									},
								},
								{
									id: "delete",
									width: 70,
									showOnHover: true,
									value: (office) => {
										return (
											<Mui.IconButton onClick={() => this.showDeleteOfficeDialog(office)}>
												<FeatherIcon>
													<Icons.Trash />
												</FeatherIcon>
											</Mui.IconButton>
										);
									},
								},
							]}
						/>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}
}

export const OfficeTable = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
