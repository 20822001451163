import { LeadLastActivityResource } from "api/resource/lead-last-activity";

export const leadLastActivities: LeadLastActivityResource[] = [
	{
		id: 1,
		leadId: 1,
		timestamp: "2019-11-19T14:46:41.723-08:00",
	},
	{
		id: 2,
		leadId: 2,
		timestamp: "2019-03-13T14:46:41.723-08:00",
	},
];