import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getSiteLinks } from "redux/selector";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { SiteLink } from "model/site-link";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	PageProps,
	Router.RouteComponentProps,
	WithMediaQuery
{
}

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.siteLinks.loading,
		siteLinks: getSiteLinks(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const copyToClipboard = (url: string) => {
	const element = document.createElement("input");
	element.value = url;
	document.body.appendChild(element);
	element.select();
	document.execCommand("copy");
	document.body.removeChild(element);
}

class Component extends React.Component<Props> {

	public render() {
		const { siteLinks, mediaQuery: isMobile } = this.props;
		const groupedSiteLinks = groupSiteLinks(siteLinks);
		const title = "IDX Page Links";
		return (
			<DashboardLayout
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={4}>
					{groupedSiteLinks && Object.keys(groupedSiteLinks).map((groupLabel, groupIndex) => {
						const links = groupedSiteLinks[groupLabel];
						return (
							<Mui.Grid key={groupIndex} item>
								<Mui.TableContainer component={Mui.Paper}>
									<Mui.Table>
										<Mui.TableHead>
											<Mui.TableRow>
												<Mui.TableCell colSpan={isMobile ? 2 : 3}>
													<Mui.Typography variant="h3">
														{groupLabel}
													</Mui.Typography>
												</Mui.TableCell>
											</Mui.TableRow>
										</Mui.TableHead>
										<Mui.TableBody>
											{links.map((link, linkIndex) => (
												<Mui.TableRow key={linkIndex}>
													<Mui.TableCell width={250}>
														{isMobile ? (
															<Mui.Link href={link.url} target="_blank">
																{link.type.label}
															</Mui.Link>
														) : (
															link.type.label
														)}
													</Mui.TableCell>
													{!isMobile && (
														<Mui.TableCell>
															<Mui.Link href={link.url} target="_blank">
																{link.url}
															</Mui.Link>
														</Mui.TableCell>
													)}
													<Mui.TableCell align="right">
														<Mui.Button
															aria-label="Copy"
															size="small"
															onClick={() => copyToClipboard(link.url)}
															startIcon={<Icons.Copy />}
															>
																Copy
														</Mui.Button>
													</Mui.TableCell>
												</Mui.TableRow>
											))}
										</Mui.TableBody>
									</Mui.Table>
								</Mui.TableContainer>
							</Mui.Grid>
						)
					})}
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

interface GroupedSiteLinks {
	[key: string]: SiteLink[]
}

function groupSiteLinks(siteLinks: SiteLink[]): GroupedSiteLinks {
	const groupedLinks: GroupedSiteLinks = {};
	siteLinks.forEach((link) => {
		const group = (groupedLinks[link.group.label] || []);
		groupedLinks[link.group.label] = group;
		group.push(link);
	});
	Object.keys(groupedLinks).forEach((group) => {
		groupedLinks[group] = groupedLinks[group].sort((a, b) => a.type.order - b.type.order);
	});
	return groupedLinks;
}

export const SettingsLinksPage = withMediaQuery("(max-width:600px)")(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);