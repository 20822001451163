import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		pagesField: {
			width: "72px"
		},
		limitFieldLabel: {
			padding: "1rem 0 0 1rem"
		}
	})
}
