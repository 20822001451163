import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		textarea: {
			border: "1px solid #ccc",
			display: "flex",
			height: "100%",
			lineHeight: 1.42,
			outline: "none",
			overflowY: "auto",
			padding: "12px 15px",
			resize: "none",
			tabSize: 4,
			textAlign: "left",
			whiteSpace: "pre-wrap",
			width: "100%",
			wordWrap: "break-word",
		},
	});
