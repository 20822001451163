import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		hiddenMuiMenuItem: {
			display: "none",
		},
		input: {
			backgroundColor: "#fff",
		},
		block: {
			position: "relative", 
			marginTop: 10,
			marginBottom: 10,
		},
		pageBlock: {
			backgroundColor: "#F4F5F7", 
			padding: "10px 10px 10px 10px"
		},
		codeBlockCopy: {
			position: "absolute", 
			right: 0, 
			bottom: 0,
			margin: 10
		},
		codeBlockCopyIcon: {
			marginLeft: 5
		},
		codeBlock: {
			marginTop: 10,
			marginBottom: 10,
		},
		platformSelect: {
			marginTop:5,
		},
		platformOption: {
			width:150, 
			height: 150, 
			display: "flex", 
			justifyContent: "center", 
			alignItems: "center", 
			cursor: "pointer",
		},
		token: {
			textTransform: "uppercase",
		},
		baseUrlRequired: {
			fontWeight: "bold", 
			color: "#f00"
		},
		popoverList: {
			maxHeight: 200,
		}
	})
}
