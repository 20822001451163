import { LeadNoteResource } from "api/resource/lead-note";

export const leadNotes: LeadNoteResource[] = [
	{
		id: 1,
		leadId: 10,
		typeId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
	},
	{
		id: 2,
		leadId: 9,
		typeId: 1,
		createdOn: "2019-03-13T14:46:41.723-08:00",
		updatedOn: "2019-03-13T14:46:41.723-08:00",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
	},
	{
		id: 3,
		leadId: 10,
		typeId: 2,
		createdOn: "2019-09-12T14:46:41.723-08:00",
		updatedOn: "2019-09-12T14:46:41.723-08:00",
		text: "5 minute call to Catherine Mcgregor's cell phone"
	},
	{
		id: 4,
		leadId: 9,
		typeId: 1,
		createdOn: "2019-09-08T14:46:41.723-08:00",
		updatedOn: "2019-09-08T14:46:41.723-08:00",
		text: "On vacation last week of August/first week of September. Try again Sept 10"
	},
	{
		id: 5,
		leadId: 8,
		typeId: 1,
		createdOn: "2019-09-01T14:46:41.723-08:00",
		updatedOn: "2019-09-01T14:46:41.723-08:00",
		text: "Catherine has a hard time with dogs. Leave Me Butterscotch at home during her appointments in the office"
	},
	{
		id: 6,
		leadId: 10,
		typeId: 1,
		createdOn: "2019-08-30T14:46:41.723-08:00",
		updatedOn: "2019-08-30T14:46:41.723-08:00",
		text: "Catherine referred from Zaek Harding. Called with interest in the Temescal neighborhood. Looking to buy next fall."
	}
];