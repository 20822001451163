import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		container: {
			width: "100%",
			height: "100%",
			position: "relative",
			overflow: "hidden",
			borderRadius: 8,
		},
	});
};
