import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		button: {
			"&:active": {
				backgroundColor: theme.palette.action.disabledBackground,
				color: theme.palette.text.disabled,
			}
		},
	});
};
