import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { ColdFusionIframe } from "component/shared/iframe/coldfusion";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		const { user } = this.props;
		const title = user.admin ? "Active Listings" : "Manage Listings";
		const url = user.admin ? "/listingseditor.cfm" : "/agentprop.cfm";
		return (
			<DashboardLayout
				permitted={user.admin || (user.type.agent && user.permissions.modifyListings)}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Home />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
				iframe={<ColdFusionIframe url={url} />}
			/>
		);
	}

}

export const ListingsActivePage = Component;