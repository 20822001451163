import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		header: {
			paddingBottom: 20,
		},
		formControl: {
			formControl: {
				margin: theme.spacing(1),
				minWidth: 160,
				maxWidth: 300,
			  },
		},
		button: {
			width: 100
		},
		dialogContent: {
			margin: "25px auto",
			[theme.breakpoints.down("xs")]: {
				display: "flex",
				alignItems: "flex-end",
				textAlign: "center",
			},
		},
		dialogActions: {
			justifyContent: "center",
			alignItems: "flex-start",
			padding: 15,
		},
		chipContainer: {
			marginTop: 10,
		},
		chip: {
			margin: theme.spacing(0.5),
			fontSize: "1rem",
		},
		checkbox: {
			marginRight: 15,
			marginLeft: 15
		},
		filters: {
			height: "100%",
		},
	});
};
