import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		logo: {
			height: 50,
			backgroundSize: "contain",
			backgroundPosition: "left",
		},
	});
};
