import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { ColdFusionIframe } from "component/shared/iframe/coldfusion";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		const { user } = this.props;
		const title = "Billing Info";
		return (
			<DashboardLayout
				permitted={user && user.permissions.billing}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
				iframe={<ColdFusionIframe url="/billing.cfm" />}
			/>
		);
	}

}

export const SettingsBillingPage = Component;