import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { getLeadActivityStats } from "redux/selector";
import { fetchLeadActivityStats } from "redux/slice/lead-activity-stats";

const mapStateToProps = (state: RootState) => {
	return {
		leadActivityStats: getLeadActivityStats(state),
		loading: state.leadActivityStats.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchLeadActivityStats
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {}

class Component extends React.Component<Props> {

	public componentDidMount() {
		this.props.fetchLeadActivityStats();
	}

	public render() {
		const { leadActivityStats, loading } = this.props;
		return (
				<Mui.Grid container item xs={12} md={5}>
					<Mui.Grid container item spacing={4} alignContent="flex-start">
						<Mui.Grid item xs={12}>
							<Mui.Grid container direction="column" spacing={2}>
								<Mui.Grid item>
									<Mui.Typography variant="h3">Recent Monthly Activity</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Card>
										<Mui.CardContent>
											<Mui.Grid item>
												<Mui.TableContainer>
													<Mui.Table>
														<Mui.TableHead>
															<Mui.TableRow>
																<Mui.TableCell>Description</Mui.TableCell>
																<Mui.TableCell align="right">Previous</Mui.TableCell>
																<Mui.TableCell align="right">Current</Mui.TableCell>
															</Mui.TableRow>
														</Mui.TableHead>
														{!loading && !!leadActivityStats.length && 
															<Mui.TableBody>
																{leadActivityStats.map((activityStat, index) => (
																	<Mui.TableRow key={activityStat.id}>
																		<Mui.TableCell component="th" scope="row">
																			{activityStat.name}
																		</Mui.TableCell>
																		<Mui.TableCell align="right">{activityStat.previous}</Mui.TableCell>
																		<Mui.TableCell align="right">{activityStat.current}</Mui.TableCell>
																	</Mui.TableRow>
																))}
															</Mui.TableBody>
														}
													</Mui.Table>
												</Mui.TableContainer>
											</Mui.Grid>
											<Mui.Grid item><br />
												* month-to-date
											</Mui.Grid>
										</Mui.CardContent>
									</Mui.Card>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
		);
	}

}

export const RecentMonthlyActivity = connect(mapStateToProps, mapDispatchToProps)(Component);
