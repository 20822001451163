import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		timeline: {
			color: "#616161",
			textAlign: "center",
			paddingTop: 0,
		},
		title: {
			marginBottom: 15,
		}
	});
