import { PageResource } from "api/resource/page";

export const pages: PageResource[] = [
	{
		id: 1,
		typeId: 1,
		path: "/idx",
		title: "Title",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 2,
		typeId: 2,
		path: "/listing/{id}",
		title: "{address}",
		head: `<meta property="og:image" content="{listingPhotoUrl}" />\n<meta property="og:image:width" content="{listingPhotoWidth}" />\n<meta property="og:image:height" content="{listingPhotoHeight}" />\n<meta name="description" content="Photos and Property Details for {listingAddress}. Get complete property information, maps, street view, schools, walk score and more. Request additional information, schedule a showing, save to your property organizer." />\n<meta name="keywords" content="{listingAddress}, {listingCity} Real Estate, {listingCity} Property for Sale" />`,
		enabled: false,
	},
	{
		id: 3,
		typeId: 3,
		path: "/listings",
		title: "Property Search",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 4,
		typeId: 4,
		path: "/email-alerts",
		title: "Email Alerts",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 5,
		typeId: 5,
		path: "/featured-listings",
		title: "Featured Listings",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 6,
		typeId: 6,
		path: "/pending-featured-listings",
		title: "Pending Featured Listings",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 7,
		typeId: 7,
		path: "/sold-featured-listings",
		title: "Sold Featured Listings",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 8,
		typeId: 8,
		path: "/open-home-listings",
		title: "Open Homes",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 9,
		typeId: 9,
		path: "/supplemental-listings",
		title: "Supplemental Listings",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 10,
		typeId: 10,
		path: "/markets",
		title: "Markets",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 11,
		typeId: 11,
		path: "/listing-report?id={id}",
		title: "{marketName}: Listing Report",
		head: `<meta name="description" content="{marketDescription}" />`,
		enabled: true,
	},
	{
		id: 12,
		typeId: 12,
		path: "/open-home-report?id={id}",
		title: "{marketName}: Open Home Report",
		head: `<meta name="description" content="{marketDescription}" />`,
		enabled: true,
	},
	{
		id: 13,
		typeId: 13,
		path: "/market-report?id={id}",
		title: "{marketName}: Market Report",
		head: `<meta name="description" content="{marketDescription}" />`,
		enabled: true,
	},
	{
		id: 14,
		typeId: 14,
		path: "/organizer",
		title: "Title",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 15,
		typeId: 15,
		path: "/contact",
		title: "Contact",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 16,
		typeId: 16,
		path: "/valuation",
		title: "Valuation Request",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 17,
		typeId: 17,
		path: "/mortgage-calculator",
		title: "Mortgage Calculator",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 18,
		typeId: 18,
		path: "/agent?id={id}",
		title: "{name}",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 19,
		typeId: 19,
		path: "/agents",
		title: "Agents",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 20,
		typeId: 20,
		path: "/office?id={id}",
		title: "Offices",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
	{
		id: 21,
		typeId: 21,
		path: "/offices",
		title: "Office: {name}",
		head: `<meta name="description" content="" />`,
		enabled: true,
	},
];