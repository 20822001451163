import React from "react";
import * as Mui from "@material-ui/core";
import Fuse from "fuse.js";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { DashboardLayout } from "component/layout/dashboard";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { Office } from "model/office";
import { getOffices } from "redux/selector";
import { RootState } from "redux/store";
import { PageProps } from "shared/page-props";
import { OfficeTable } from "./office-table";
import { styles } from "./style";

interface Props
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		PageProps,
		Router.RouteComponentProps,
		Mui.WithStyles<typeof styles> {}

interface State {
	term: string;
}

const mapStateToProps = (state: RootState) => {
	const offices = getOffices(state);
	const options: Fuse.IFuseOptions<Office> = {
		shouldSort: true,
		threshold: 0.5,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: ["id", "name", "address", "city", "priority"],
	};
	return {
		offices,
		fuse: new Fuse(offices, options),
		loading: state.offices.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({}, dispatch);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			term: "",
		};
	}
	public render() {
		const { classes, fuse, loading, user } = this.props;
		let { offices } = this.props;
		const { term } = this.state;
		const title = "Manage Offices";
		if (term) {
			offices = fuse.search(term).map((result) => result.item);
		}
		return (
			<DashboardLayout
				permitted={user && user.permissions.broker}
				title={title}
				header={
					<Mui.Grid container justifyContent="space-between" alignItems="center">
						<Mui.Grid item xs={3}>
							<Mui.Typography variant="h1">
								<FeatherIcon>
									<Icons.Settings />
								</FeatherIcon>
								{title}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Hidden xsDown>
							<Mui.Grid item xs={6}>
								<Mui.InputBase
									placeholder="Search for office"
									fullWidth
									onChange={(event) => {
										this.setState({
											term: event.target.value,
										});
									}}
									value={term}
									className={classes.searchBar}
								/>
							</Mui.Grid>
						</Mui.Hidden>
						<Mui.Grid item xs={3} />
					</Mui.Grid>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={2}>
						{loading ? (
							<Mui.Grid item>
								<LoadingIndicator />
							</Mui.Grid>
						) : (
							<Mui.Grid container direction="column" spacing={2}>
								<Mui.Hidden smUp>
									<Mui.Grid item>
										<Mui.Grid container justifyContent="space-between" alignItems="center">
											<Mui.Grid item xs={5}>
												<Mui.InputBase
													placeholder="Search for lead"
													fullWidth
													onChange={(event) => {
														this.setState({
															term: event.target.value,
														});
													}}
													className={classes.searchBar}
													style={{ border: "1px solid gray" }}
												/>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Hidden>
								<Mui.Grid item>
									<OfficeTable offices={offices} user={user} />
								</Mui.Grid>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsOfficesPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
