import { GeographicRouteResource } from "api/resource/geographic-route";

export const geographicRoutes: GeographicRouteResource[] = [
	{
		id: 28,
		agentId: 1,
		postalCode: "3513442",
		createdOn: "2023-04-21T11:12:32.010-07:00",
		updatedOn: "2023-04-21T11:12:32.010-07:00"
	},
	{
		id: 29,
		agentId: 1,
		postalCode: "356988",
		createdOn: "2023-04-21T11:12:32.010-07:00",
		updatedOn: "2023-04-21T11:12:32.010-07:00"
	},
	{
		id: 30,
		agentId: 3,
		postalCode: "356061",
		createdOn: "2023-04-21T11:12:32.010-07:00",
		updatedOn: "2023-04-21T11:12:32.010-07:00"
	},
]