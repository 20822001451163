import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { WebsitePlatformType } from "type/website-platform";
import wordpressLogo from "../image/wordpress-logo.png";
import squareSpaceLogo from "../image/squarespace-logo.png";
import wixLogo from "../image/wix-logo.png";
import codeLogo from "../image/code-logo.png";
import { Integration } from "model/integration";


interface Props extends 
	Mui.WithStyles<typeof styles>
{
	websitePlatformType?: WebsitePlatformType;
	onChange: (value: WebsitePlatformType) => void;
	wixIntegration?: Integration
}


interface State {
	websitePlatformType?: WebsitePlatformType;
}

class Component extends React.Component<Props, State> {
	private handlePlaformSelect(websitePlatformType: WebsitePlatformType) {
		this.setState({ websitePlatformType: WebsitePlatformType.WORD_PRESS }, () => {
			this.props.onChange(websitePlatformType);
		})
	}
	public render() {
		const {
			classes,
			websitePlatformType,
			wixIntegration
		} = this.props;
		return (
			<Mui.Grid container direction="row" className={classes.platformSelect} spacing={2} alignItems="center">
				{!wixIntegration ? (
					<>
						<Mui.Grid item>
							<Mui.Button
								className={classes.platformOption}
								variant="outlined"
								color={websitePlatformType && websitePlatformType.wordpress ? "secondary" : undefined}
								onClick={() => this.handlePlaformSelect(WebsitePlatformType.WORD_PRESS)}
							>
								<Mui.Grid container alignItems="center" justifyContent="center" direction="column">
									<Mui.Grid item>
										<img alt="cms logo" src={wordpressLogo} className={classes.logo} />
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Typography className={classes.logoText}>{WebsitePlatformType.WORD_PRESS.label}</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Button>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								className={classes.platformOption}
								variant="outlined"
								color={websitePlatformType && websitePlatformType.squarespace ? "secondary" : undefined}
								onClick={() => this.handlePlaformSelect(WebsitePlatformType.SQUARESPACE)}
							>
								<Mui.Grid container alignItems="center" justifyContent="center" direction="column">
									<Mui.Grid item>
										<img alt="cms logo" src={squareSpaceLogo} className={classes.logo} />
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Typography className={classes.logoText}>{WebsitePlatformType.SQUARESPACE.label}</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Button>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								className={classes.platformOption}
								variant="outlined"
								color={websitePlatformType && websitePlatformType.wix ? "secondary" : undefined}
								onClick={() => this.handlePlaformSelect(WebsitePlatformType.WIX)}
							>
								<Mui.Grid container alignItems="center" justifyContent="center" direction="column">
									<Mui.Grid item>
										<img alt="cms logo" src={wixLogo} className={classes.logo} />
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Typography className={classes.logoText}>{WebsitePlatformType.WIX.label}</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Button>
						</Mui.Grid>
						<Mui.Grid item> 
							Or 
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								className={classes.platformOption}
								variant="outlined"
								color={websitePlatformType && !websitePlatformType.promoted ? "secondary" : undefined}
								onClick={() => this.handlePlaformSelect(WebsitePlatformType.getOrCreateById(""))}
							>
								<Mui.Grid container alignItems="center" justifyContent="center" direction="column">
									<Mui.Grid item>
										<img alt="cms logo" src={codeLogo} className={classes.logo} />
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Typography className={classes.logoText}>Other</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Button>
						</Mui.Grid>
					</>
				) : (
					<>
						<Mui.Grid item className={classes.wixSelected}>
							<Mui.Grid container alignItems="center" justifyContent="center" direction="column">
								<Mui.Grid item>
									<img alt="cms logo" src={wixLogo} className={classes.logo} />
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Typography className={classes.logoText}>{WebsitePlatformType.WIX.label}</Mui.Typography>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</>
				)}
			</Mui.Grid>
		);
	}
}

export const Platform = Mui.withStyles(styles)(Component);
