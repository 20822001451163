import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		title: {
			alignItems: "center",
		},
		container: {
			alignItems: "center",
		},
		stats: {
			flexGrow: 1,
			textAlign: "center",
		},
		actions: {
			textAlign: "right",
		},
		actionButtons: {
			marginLeft: 10,
		},
		timeline: {
			color: "#616161",
			textAlign: "center",
			paddingTop: 0,
		},
		timelineLabel: {
			fontSize: 24,
		},
		TimelineIcon: {
			fontSize: 38,
			lineHeight: .5
		},
		add: {
			textAlign: "left",
		},
		addNew: {
			textAlign: "center", 
			marginTop: 20, 
			marginBottom: 20
		},
		addNewButton: {
			textAlign: "center"
		}
	});
