import React from "react";
import { Table } from "component/shared/table";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { formatPrice } from "shared/price-formatter";
import { SavedSearch } from "model/saved-search";
import { CriterionComponent } from "component/shared/criterion-component";
import { styles } from "./style";
import { FeatherIcon } from "component/shared/feather-icon";
import moment from "moment";
import { dateFormatter } from "shared/date-formatter";

interface Props extends 
	Mui.WithStyles<typeof styles>
{
	savedSearch: SavedSearch;
	index: number;
	showPropertyOrganizer: Function;
}

const Component = (props: Props) => {
	const { savedSearch, showPropertyOrganizer } = props;
	const monthAgo = moment().subtract(1, "M");
	const sentCount = (savedSearch.emailSents) ? savedSearch.emailSents.filter(emailSent => emailSent.sentOn > monthAgo).length : 0;
	const emailClicks = (savedSearch.emailClicks) ? savedSearch.emailClicks : [];
	return (
		<Mui.ExpansionPanel>
			<Mui.ExpansionPanelSummary
				expandIcon={
					<FeatherIcon>
						<Icons.ChevronDown />
					</FeatherIcon>
				}
			>
				<Mui.Grid container justifyContent="space-between">
					<Mui.Grid item>
						<Mui.Typography variant="h4">
							{savedSearch.label}
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						{!savedSearch.inactive && 
							<Mui.Chip
								label={savedSearch.recieveEmails ? "Active" : "Inactive"}
								size="small"
								variant="outlined"
								color={savedSearch.recieveEmails ? "secondary" : "default"}
							/>
						}
						{savedSearch.inactive && 
							<Mui.Chip
								label="Deleted"
								size="small"
							/>
						}
					</Mui.Grid>
				</Mui.Grid>
			</Mui.ExpansionPanelSummary>
			<Mui.ExpansionPanelDetails>
				<Mui.Grid container direction="column" spacing={2}>
					<Mui.Grid item>
						<Mui.Typography align="right">
							{sentCount} {sentCount === 1 ? "email" : "emails"} sent in the past 30 days
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<CriterionComponent criteria={savedSearch.criteria} />
					</Mui.Grid>
					<Mui.Grid item>
						<Table
							className="-highlight"
							items={emailClicks}
							columns={[
								{
									id: "clickedOn",
									title: "Date Clicked",
									width: 165,
									value: (emailClick) => {
										return (
											<Mui.Typography>
												{dateFormatter(emailClick.clickedOn)}
											</Mui.Typography>
										);
									},
									style: {
										alignItems: "flex-start",
									},
								},
								{
									id: "listingAddress",
									title: "Opened Listing",
									value: (emailClick) => {
										return (
											<Mui.Typography>
												{emailClick.listing ? (
													<Mui.Link href={emailClick.listing.detailUrl} target="_blank">
														{emailClick.listing.addressOneLine}
													</Mui.Link>
												) : (
													"--"
												)}
											</Mui.Typography>
										);
									},
								},
								{
									id: "listingPrice",
									title: "Listing Price",
									width: 110,
									value: (emailClick) => {
										return (
											<Mui.Typography>
												{emailClick.listing ? (
													formatPrice(emailClick.listing.price)
												) : (
													"--"
												)}
											</Mui.Typography>
										);
									},
								},
							]}
							style={{
								width: "100%",
							}}
							minRows={0}
							defaultPageSize={5}
							showPagination={emailClicks.length > 5 ? true : false }
							NoDataComponent={() => (
								<Mui.Typography>No emails clicked yet.</Mui.Typography>
							)}
						/>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.ExpansionPanelDetails>
			<Mui.ExpansionPanelActions>
				<Mui.Button
					variant="text"
					color="secondary"
					style={{ background: "none" }}
					disableRipple
					disableTouchRipple
					onClick={() => showPropertyOrganizer()}
				>
					{!savedSearch.inactive && 
						<Mui.Typography>Manage Email Alerts/Saved Searches</Mui.Typography>
					}
				</Mui.Button>
			</Mui.ExpansionPanelActions>
		</Mui.ExpansionPanel>
	);
};

export const ExpansionTable = Mui.withStyles(styles)(Component);
