import { Theme, createStyles } from "@material-ui/core";
export const styles = (theme: Theme) => {
    return createStyles({
        adornedEnd: {
            paddingRight: 5,
        },
        button: {
            paddingLeft: 15,
            paddingRight: 15,
		},
		labelAsterisk: {
			color: theme.palette.error.main,
		},
		errorMessage: {
			color: theme.palette.error.main,
			fontWeight: "bold",
		}
    });
};
