import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		expansionTable: {
			marginBottom: "100px",
			color: "red",
		}
	});
};
