import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		body: {
			padding: 20
		},
		typography: {
			textTransform: "capitalize"
		},
		boldText: {
			fontWeight: "bold"
		},
		button: {
			marginLeft: 5,
			marginRight: 5
		}
	});
};