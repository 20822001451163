import React from "react";
import * as Icons from "react-feather";

class Type {
	
	public static ADMIN = new Type("admin", "Admin", Icons.ShieldOff);
	public static CLIENT = new Type("client", "Client", Icons.Shield);
	
	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly icon: React.ComponentType<Icons.IconProps>
	) {
	}

	public get admin() {
		return this === Type.ADMIN;
	}

	public get client() {
		return this === Type.CLIENT;
	}

	// work around for odd TS error
	// @ts-ignore
	public get inverse(): Type {
		return this.admin ? Type.CLIENT : Type.ADMIN;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as ViewType };