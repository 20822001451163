import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { saveIntegration } from "redux/slice/integrations";
import { Integration } from "model/integration";
import { Card } from "../card";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveIntegration,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	integration: Integration;
}

interface State {
	name?: string;
	oauthId?: string,
	clientId?: string
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			name: props.integration.name,
			oauthId: props.integration.data.googleLoginOAuthId as string,
			clientId: props.integration.data.googleLoginClientId as string,
		};
	}

	public render() {
		const { integration } = this.props;
		const { name, oauthId, clientId } = this.state;

		return (
			<Card
				integration={integration}
				deletable={true}
				content={
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item>
							<Mui.TextField
								label="Name"
								placeholder={integration.integrationType.name}
								value={name || ""}
								onChange={event => this.setState({ name: event.target.value })}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.TextField
								label="OAuth Client ID"
								value={oauthId || ""}
								onChange={event => this.setState({ oauthId: event.target.value })}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.TextField
								label="OAuth Client Secret"
								value={clientId || ""}
								onChange={event => this.setState({ clientId: event.target.value })}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
					</Mui.Grid>
				}
				actions={
					<Mui.Grid container spacing={2}>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => this.handleSave()}
							>
								Save
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				}
			/>
		);
	}

	public handleSave = () => {
		const { integration, saveIntegration } = this.props;
		const { name, oauthId, clientId } = this.state;
		saveIntegration({ integration: {
			...integration,
			name,
			data: {
				...integration.data,
				googleLoginOAuthId: oauthId,
				googleLoginClientId: clientId
			},
		}})
	}
	
}

export const GoogleLoginIntegration = connect(mapStateToProps, mapDispatchToProps)(Component);
