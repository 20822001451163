import topProducerIcon from "image/top-producer-icon.png";
import topProducerLogo from "image/top-producer-logo.png";
import zapierIcon from "image/zapier-icon.png";
import zapierLogo from "image/zapier-logo.png";
import zillowIcon from "image/zillow-icon.png";
import zillowLogo from "image/zillow-logo.png";
import emailCalendarIcon from "image/email-calendar-icon.png";
import emailCalendarLogo from "image/email-calendar-logo.png";
import googleIcon from "image/google-icon.png";
import googleLogo from "image/google-logo.png";
import wixIcon from "image/wix-icon.png";
import wixLogo from "image/wix-logo.png";
import { User } from "model/user";

class Type {

	public static TOP_PRODUCER_LTS = new Type(
		1,
		"Top Producer LTS",
		"https://www.topproducer.com",
		topProducerIcon,
		topProducerLogo,
		() => true,
		Number.MAX_SAFE_INTEGER,
	);

	public static CUSTOM_LTS = new Type(
		2,
		"Custom LTS",
		null,
		null,
		null,
		() => true,
		Number.MAX_SAFE_INTEGER,
	);

	public static ZAPIER_NEW_LEAD = new Type(4,
		"Zapier",
		"https://zapier.com",
		zapierIcon,
		zapierLogo,
		() => true,
		Number.MAX_SAFE_INTEGER,
	);

	public static LEAD_AGGREGATION = new Type(
		5,
		"Lead Aggregation Email",
		null,
		null,
		null,
		(user) => !!user.permissions.campaigns,
		1,
	);

	public static ZILLOW_TECH_CONNECT = new Type(
		6,
		"Zillow Tech Connect",
		"https://www.zillow.com",
		zillowIcon,
		zillowLogo,
		(user) => !!user.permissions.campaigns,
		Number.MAX_SAFE_INTEGER,
	);

	public static EMAIL_CALENDAR = new Type(
		7,
		"Email & Calendar Sync",
		null,
		emailCalendarIcon,
		emailCalendarLogo,
		(user) => !!user.permissions.emailCalendarIntegration,
		1,
	);

	public static GOOGLE_LOGIN = new Type(
		8,
		"Google Login",
		"https://www.google.com",
		googleIcon,
		googleLogo,
		(user) => !!user.permissions.googleLogin && user.kestrelVersion.all,
		1,
	);

	public static WIX_APP = new Type(
		9,
		"Wix",
		"https://www.wix.com",
		wixIcon,
		wixLogo,
		(user) => !!user.permissions.googleLogin && user.kestrelVersion.all,
		1,
	);

	private constructor(
		public readonly id: number,
		public readonly name: string,
		public readonly websiteUrl: string | null,
		public readonly iconUrl: string | null,
		public readonly logoUrl: string | null,
		public readonly permitted: (user: User) => boolean,
		public readonly limit: number,
	) {
	}

	public get topProducerLts() {
		return this === Type.TOP_PRODUCER_LTS;
	}

	public get customLts() {
		return this === Type.CUSTOM_LTS;
	}

	public get zapierNewLead() {
		return this === Type.ZAPIER_NEW_LEAD;
	}

	public get leadAggregation() {
		return this === Type.LEAD_AGGREGATION;
	}

	public get zillowTechConnect() {
		return this === Type.ZILLOW_TECH_CONNECT;
	}

	public get emailCalendar() {
		return this === Type.EMAIL_CALENDAR;
	}

	public get googleLogin() {
		return this === Type.GOOGLE_LOGIN;
	}

	public get wixApp() {
		return this === Type.WIX_APP;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as IntegrationType };
