import { CampaignCampaignMessageResource } from "api/resource/campaign-campaign-message";

export const campaignCampaignMessages: CampaignCampaignMessageResource[] = [
	{
		id: 1,
		campaignId: 100,
		campaignMessageId: 1,
		messageOrder: 1,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	{
		id: 2,
		campaignId: 100,
		campaignMessageId: 2,
		messageOrder: 2,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	{
		id: 3,
		campaignId: 100,
		campaignMessageId: 3,
		messageOrder: 3,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	// {
	// 	id: 4,
	// 	campaignId: 100,
	// 	campaignMessageId: 4,
	// 	messageOrder: 4,
	// 	timeToSendUnit: "days",
	// 	timeToSendNumber: 3,
	// 	status: "Active",
	// 	dateCreated: "2018-10-08T12:19:20.230"
	// },
	// {
	// 	id: 5,
	// 	campaignId: 100,
	// 	campaignMessageId: 5,
	// 	messageOrder: 5,
	// 	timeToSendUnit: "days",
	// 	timeToSendNumber: 1,
	// 	status: "Active",
	// 	dateCreated: "2018-10-08T12:19:20.230"
	// },
	{
		id: 9,
		campaignId: 200,
		campaignMessageId: 9,
		messageOrder: 1,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	{
		id: 10,
		campaignId: 200,
		campaignMessageId: 10,
		messageOrder: 2,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	{
		id: 11,
		campaignId: 300,
		campaignMessageId: 11,
		messageOrder: 1,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	{
		id: 12,
		campaignId: 400,
		campaignMessageId: 12,
		messageOrder: 1,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	{
		id: 13,
		campaignId: 500,
		campaignMessageId: 13,
		messageOrder: 1,
		dateCreated: "2018-10-08T12:19:20.230"
	},
	{
		id: 14,
		campaignId: 600,
		campaignMessageId: 14,
		messageOrder: 1,
		dateCreated: "2022-11-19T14:46:41.723-08:00",
	},
];