import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		cardHeader: {
			padding: "10px 16px 0px 16px",
		},
		label: {
			textTransform: "uppercase",
			fontSize: 13,
			fontWeight: "bold",
		},
		reactivateButtonLabel: {
			textTransform: "none",
		},
		text: {
			paddingLeft: 5,
		},
		activeChip: {
			color: "white",
			backgroundColor: "#8fa638",
			fontSize: "1rem",
		},
		inactiveChip: {
			color: "black",
			backgroundColor: "#e0e0e0",
			fontSize: "1rem",
		},
		tagChip: {
			margin: 2,
		},
	});
};

