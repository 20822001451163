import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
		},
		title: {
			color: theme.palette.primary.contrastText,
		},
		action: {
			marginTop: 0,
		},
		buttonTitle: {
			color: theme.palette.primary.contrastText,
		},
		linebreak: {
			overflowWrap: "break-word",
			wordWrap: "break-word",
		    hyphens: "auto",
		}
	});
};
