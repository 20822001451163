import { RoundRobinRouteResource } from "api/resource/round-robin-route";

export const roundRobinRoutes: RoundRobinRouteResource[] = [
	{
		id: 1,
		agentId: 1,
		createdOn: "2023-04-21T11:12:32.010-07:00",
	},
	{
		id: 2,
		agentId: 2,
		createdOn: "2023-04-21T11:12:32.010-07:00",
	}
]
