import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import classNames from "classnames";

interface Props extends Mui.WithStyles<typeof styles> {
	className?: string;
}

class Component extends React.Component<Props> {
	
	public render() {
		const { classes, children, className } = this.props;
		return (
			<Mui.Typography
				className={classNames([
					classes.root,
					className,
				])}
			>
				{children}
			</Mui.Typography>
		);
	}

}

export const ColumnLabel = Mui.withStyles(styles)(Component);