import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		dialogContent: {
			[theme.breakpoints.up("sm")]: {
				width: 470,
			},
			padding: 16,
		},
		dialogActions: {
			paddingRight: "24px",
			paddingBottom: "16px",
		},
	});
};
