import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		alertCircle: {
			marginLeft: 9,
			marginRight: 7
		},
	});
};
