import React from "react";
import * as Mui from "@material-ui/core"
import { styles } from "./style";

interface Props extends Mui.WithStyles<typeof styles>{

}

class Component extends React.Component<Props> {

	public render() {
		const { classes } = this.props;
		return (
			<Mui.Card className={classes.card}>
				<Mui.CardContent className={classes.cardContent}>
					{this.props.children}
				</Mui.CardContent>
			</Mui.Card>
		);
	}
}


export const FullPageCard = Mui.withStyles(styles)(Component);