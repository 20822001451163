import React from "react";
import * as Mui from "@material-ui/core";
import { IntegrationType } from "type/integration-type";
import { AddButton } from "../add-button";

interface Props {
	integrationType: IntegrationType;
	onClose: Function;
}

class Component extends React.Component<Props> {

	public render() {
		const { integrationType, onClose } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography>Forwards information for new leads to a URL of your choosing in the Top Producer "Lead Transmission Standard" data format.</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<AddButton integrationType={integrationType} onClose={onClose} />
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const CustomLtsIntegration = Component;