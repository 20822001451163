class Type {
	public static ROADMAP = new Type("ROADMAP", "Street (Normal)", "streets-v11");
	public static SATELLITE = new Type("SATELLITE", "Satellite", "satellite-v9");
	public static HYBRID = new Type("HYBRID", "Hybrid", "satellite-streets-v11");
	public static TERRAIN = new Type("TERRAIN", "Terrain", "outdoors-v11");

	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly styleId: string,
	) {
	}

	public get style() {
		return "mapbox://styles/mapbox/" + this.styleId;
	}

	public static get default(): Type {
		return this.ROADMAP;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: string) {
		return this.getById(id) || this.default;
	}
}

export { Type as MapSearchMapType };
