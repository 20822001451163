class Type {
	public static ON_FEATURED_LISTINGS_PAGE = new Type(0, "On Featured Listings Page");
	public static ON_SOLD_PAGE = new Type(2, "On Sold Page");

	private constructor(
		public readonly id: number,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.ON_FEATURED_LISTINGS_PAGE;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: number) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: number): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as FeaturedPendingResultsType };
