class Type {
	public static PRICE_HIGH_TO_LOW = new Type("priceDesc", "Price (High to Low)");
	public static PRICE_LOW_TO_HIGH = new Type("priceAsc", "Price (Low to High)");
	public static IMPORT_DATE = new Type("importDate", "List Date");
	public static LISTING_NUMBER = new Type("listingNumber", "Listing Number");

	private constructor(
		public readonly id: string,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.PRICE_HIGH_TO_LOW;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: string): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as EurekaResultsSortType };
