import React from "react";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import * as Router from "react-router-dom";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getAllDripCampaigns } from "redux/selector";
import { styles } from "./styles";
import { Card } from "./card"
import { AddCampaign } from "./add-campaign";
import { UnauthorizedMa } from "./unauthorized";

const mapStateToProps = (state: RootState) => {
	const campaigns = getAllDripCampaigns(state);
	return {
		campaigns,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	PageProps,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles> {
}

interface State {
	openActionsCampaignId: number | null,
	openAddNew: boolean,
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			openActionsCampaignId: null,
			openAddNew: false,
		}
	}

	public render() {
		const { openAddNew } = this.state;
		const { user, campaigns } = this.props;
		const title = "Campaigns";
		return (
			<DashboardLayout
				permitted={user && user.permissions.campaigns}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				{user.pending ? (
					<UnauthorizedMa />
				) : (
					<>
						<AddCampaign open={openAddNew} onClose={() => this.setState({openAddNew: false})}/>
						<Mui.Grid container direction="column" spacing={3}>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									variant="contained"
									onClick={() => this.setState({openAddNew: true})}
								>
									Create Campaign
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						{campaigns.map((campaign, index) => (
							<Card campaign={campaign} key={index}/>
						))}
					</>
				)}
				
			</DashboardLayout>
		);
	}

}

export const CampaignsPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);