
class Type {
	public static OFF = new Type(0, 0, "Off", 
		"The user is not prompted to register. Users can still manually sign-up for " +
		"Email Updates and Property Organizer accounts.");
	public static ULTRA_LIGHT_ONCE = new Type(3, 1, "Ultra-Light (One-Prompt)",
		"The user is prompted for registration, but has the option to ignore the request " +
		"and continue on with searching and property viewing. If the user ignores the " +
		"first prompt, they are not prompted again.");
	public static ULTRA_LIGHT_MULTI = new Type(4, 2, "Ultra-Light (Multi-Prompt)",
		"The user is prompted for registration, but has the option to ignore the request " +
		"and continue on with property viewing. If the user ignores the prompt, the " +
		"system will begin tracking user searching and viewing again. Once the user " +
		"reaches the limit again, the system will prompt for registration again.");
	public static LIGHT = new Type(2, 3, "Light", "The user must register when prompted. " +
		"After providing information, user is directed back to the page they were trying " +
		"to access when registration was triggered.");
	public static HEAVY = new Type(1, 4, "Heavy", "The user must register and then " +
		"wait to receive an email from the system. The email contains a link back to the " +
		"website and brings the user back to the place where they were prior to registration. " +
		"This method ensures that the user has provided a valid email address.");

	private constructor(
		public readonly id: number,
		public readonly order: number,
		public readonly label: string,
		public readonly help?: string,
	) {

	}
	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static valuesSorted(): Type[] {
		return Object.values(Type).sort((a,b) => b.order - a.order);
	}
}

export { Type as LeadCaptureStrengthType }