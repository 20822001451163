import * as Mui from "@material-ui/core";
import { Dialog } from "component/shared/dialog";
import { Market } from "model/market";
import { styles } from "./styles";

interface Props extends Mui.WithStyles<typeof styles> {
	market: Market;
	deleteMarketDialogIsOpen: boolean;
	hideDeleteMarketDialog: Function;
	deleteMarket: Function;
}

const component = (props: Props) => {
	const {
		market,
		classes,
		deleteMarket,
		deleteMarketDialogIsOpen,
		hideDeleteMarketDialog,
	} = props;

	return (
		<Dialog
			open={deleteMarketDialogIsOpen}
			onClose={() => hideDeleteMarketDialog()}
		>
			<Mui.DialogTitle>Delete Market</Mui.DialogTitle>
			<Mui.DialogContent className={classes.dialogContent}>
				<Mui.DialogContentText>
					{`Are you sure you want to delete ${market.name}?`}
				</Mui.DialogContentText>
			</Mui.DialogContent>
			<Mui.DialogActions className={classes.dialogActions}>
				<Mui.Button
					onClick={() => deleteMarket()}
					variant="contained"
					color="secondary"
				>
					Yes
				</Mui.Button>
				<Mui.Button onClick={() => hideDeleteMarketDialog()} variant="outlined">
					No
				</Mui.Button>
			</Mui.DialogActions>
		</Dialog>
	);
};

export const DeleteMarketDialog = Mui.withStyles(styles)(component);
