import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "../style";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";

interface Props extends Mui.WithStyles<typeof styles> {
	icon: JSX.Element;
	prefix: string;
	value: number;
	suffix: string;
	url: string
}

const Component = (props: Props) => {
	const { icon, prefix, value, suffix, url, classes } = props;
	let line1 = prefix + " " + value;
	let line2 = suffix;

	if (value === 0) {
		line1 = "";
		line2 = "You completed your goal for today!";
	}

	return (
		<Mui.Grid item className={classes.row}>
			<div className={classes.icon}>{icon}</div>
			<Mui.Grid container item direction="column">
				<Mui.Link component={RouterLinkWrapper} to={url}>
					<Mui.Typography color="secondary" variant="h3">
						{line1}
					</Mui.Typography>
					<Mui.Typography color="textPrimary" variant="h4">
						{line2}
					</Mui.Typography>
				</Mui.Link>
			</Mui.Grid>
		</Mui.Grid>
	);
};

export const Right = Mui.withStyles(styles)(Component);
