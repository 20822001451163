class Type {
	public static ACTIVE = new Type("Active");
	public static INACTIVE = new Type("Inactive");

	private constructor(
		public readonly id: string,
	) {
	}

	public get label() {
		return this.id;
	}

	public get active() {
		return this === Type.ACTIVE;
	}

	public get inactive() {
		return this === Type.INACTIVE;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as CampaignStatus };
