import { LeadAnniversaryResource } from "api/resource/lead-anniversary";
import { LeadAnniversaryType } from "type/lead-anniversary";

export const leadAnniversaries : LeadAnniversaryResource[] = [
	{
		id: 1,
		leadId: 1,
		typeId: LeadAnniversaryType.BIRTHDAY.id,
		startDate: "1900-09-01",
		updatedOn: "2019-10-10T14:00:00.000-08:00",
		createdOn: "2019-10-10T14:00:00.000-08:00",
	},
	{
		id: 2,
		leadId: 1,
		typeId: LeadAnniversaryType.TRANSACTION_ANNIVERSARY.id,
		startDate: "1900-11-09",
		updatedOn: "2019-10-10T14:00:00.000-08:00",
		createdOn: "2019-10-10T14:00:00.000-08:00",
	},
	{
		id: 3,
		leadId: 4,
		typeId: LeadAnniversaryType.BIRTHDAY.id,
		startDate: "1900-11-12",
		updatedOn: "2019-10-10T14:00:00.000-08:00",
		createdOn: "2019-10-10T14:00:00.000-08:00",
	},
	{
		id: 4,
		leadId: 3,
		typeId: LeadAnniversaryType.TRANSACTION_ANNIVERSARY.id,
		startDate: "1900-11-10",
		updatedOn: "2019-10-10T14:00:00.000-08:00",
		createdOn: "2019-10-10T14:00:00.000-08:00",
	},
	{
		id: 5,
		leadId: 3,
		typeId: LeadAnniversaryType.TRANSACTION_ANNIVERSARY.id,
		startDate: "1900-12-13",
		updatedOn: "2019-10-10T14:00:00.000-08:00",
		createdOn: "2019-10-10T14:00:00.000-08:00",
	}
]