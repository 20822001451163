import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		formContainer: {
			display: "table"
		},
		hiddenMuiMenuItem: {
			display: "none",
		},
		input: {
			backgroundColor: "#fff",
		},
		block: {
			position: "relative", 
			marginTop: 10,
			marginBottom: 10,
		},
		pageBlock: {
			backgroundColor: "#F4F5F7", 
			padding: "10px 10px 10px 10px",
		},
		platformSelect: {
			marginTop: 5,
		},
		platformOption: {
			width: 150,
		},
		baseUrlRequired: {
			fontWeight: "bold", 
			color: "#f00",
		},
		baseUrlRequiredText: {
			whiteSpace: "pre-line",
		},
		circularProgress: {
			position: "fixed",
			top: "50%",
			bottom: "50%",
			left: "50%",
			right: "50%"
		},
	})
}
