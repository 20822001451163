class Type {
	
	public static TYPE = new Type("type");
	public static STATUS = new Type("status");
	public static PRIORITY = new Type("priority");
	public static TODAY = new Type("today");
	public static LEAD = new Type("lead");
	
	private constructor(
		public readonly id: string,
	) {
	}

	public get type() {
		return this === Type.TYPE;
	}

	public get status() {
		return this === Type.STATUS;
	}

	public get priority() {
		return this === Type.PRIORITY;
	}

	public get today() {
		return this === Type.TODAY;
	}

	public get lead() {
		return this === Type.LEAD;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as TaskFilterType };