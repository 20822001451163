class Type {
	public static PT = new Type("PT", "Pacific Time", "US/Pacific");
	public static CT = new Type("CT", "Central Time", "US/Central");
	public static CST = new Type ("CST", "Central standard time- no daylight savings", "UTC-06:00");
	public static ET = new Type("ET","Eastern Standard Time", "US/Eastern");
	public static MT = new Type("MT", "Mountain Time", "US/Mountain");
	public static MST = new Type("MST","Mountain Standard Time- no daylight savings", "UTC-07:00");
	public static AKT = new Type("AKT", "Alaska Time", "US/Alaska");
	public static HAST = new Type("HAST","Hawaii Standard Time- no daylight savings", "US/Hawaii");
	public static NT = new Type ("NT","Newfoundland Time", "Canada/Newfoundland");
	public static AT = new Type("AT", "Atlantic time", "Canada/Atlantic");
	public static AST = new Type("AST", "Atlantic Standard Time - no daylight savings", "UTC-04:00");

	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly tzName: string,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as TimeZoneType };
