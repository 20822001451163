import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		activeZap: {
			color: "#ebcd00",
			fill: "currentColor",
		},
		container: {
			alignItems: "center",
			display: "flex",
		},
		star: {
			color: "#e0e0e0",
			fill: "#fff",
		},
	});
};
