import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		tooltip: {
			padding: 10,
			width: 200,
		},
		text: {
			color: theme.palette.common.white,
			fontSize: 16,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline"
			}
		}
	});
};
