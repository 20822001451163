import { CampaignSubscriptionStatResource } from "api/resource/campaign-subscription-stat";

export const campaignSubscriptionStats: CampaignSubscriptionStatResource[] = [
	{
		id: 1,
		campaignId: 100,
		leadId: 1,
		campaignSubscriptionId: 7,
		messageStatus: "Bounced",
		status: "status",
		step: 1,
		click: 0,
		open: 0,
		lastSent: "2019-03-13T14:46:41.723-08:00",
	},
	{
		id: 2,
		campaignId: 100,
		leadId: 2,
		campaignSubscriptionId: 13,
		messageStatus: "Delivered",
		status: "status",
		step: 1,
		click: 2,
		open: 0,
		lastSent: "2019-03-13T14:46:41.723-08:00",
	},
	// {
	// 	id: 3,
	// 	campaignId: 100,
	// 	leadId: 3,
	// 	campaignSubscriptionId: 14,
	// 	messageStatus: "Delivered",
	// 	status: "status",
	// 	step: 1,
	// 	click: 1,
	// 	open: 1,
	// 	lastSent: "2019-03-13T14:46:41.723-08:00",
	// },
];