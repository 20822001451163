import React from "react";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import { ClientEmailSetup } from "./client-email-setup";
import { AgentEmailMessage } from "./agent-email-message";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		const { user } = this.props;
		if (user.admin) {
			return <ClientEmailSetup {...this.props} />;
		} else {
			return <AgentEmailMessage {...this.props} />;
		}
	}
}

export const SettingsEmailPage = Component;