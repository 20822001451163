import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { CodeBlock } from "component/shared/code-block";
import { User } from "model/user";

interface OwnProps {
	embedCode?: string;
	user: User;
}

type Props = OwnProps & Mui.WithStyles<typeof styles>;

class Component extends React.Component<Props> {

	public render() {
		const { classes, children, user, embedCode } = this.props;
		return (
			<Mui.Grid container spacing={2} direction="column">
				{children && 
					<Mui.Grid item>
						{children}
					</Mui.Grid>
				}
				<Mui.Grid item xs>
					{embedCode ? ( 
						<>
							<Mui.Grid item>
								<Mui.Typography variant="h4">
									Embed Code
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<CodeBlock
									className={classes.codeBlock}
									code={embedCode}
									language={user.websitePlatform.textEmbedCode ? "text" : "html"}
								/>
							</Mui.Grid>
						</>
						
					) : (
						<Mui.Grid>
							<Mui.Typography>
								Select all the required * options to view the embed code.
							</Mui.Typography>
						</Mui.Grid>
					)}
				</Mui.Grid>
			</Mui.Grid>
			
		);
	}
}

export const WidgetConfigLayout = Mui.withStyles(styles)(Component);