import { SavedSearchResource } from "api/resource/saved-search";

export const savedSearches: SavedSearchResource[] = [
	{
		id: 1,
		leadId: 1,
		name: "My Super Faves",
		recieveEmails: true,
		createdOn: "2018-11-11T14:46:41.723-08:00",
		modifiedOn: "2018-11-12T14:46:41.723-08:00",
		inactive: false,
		criteria: [
			{
				name: "cityId",
				label: "Cities",
				value: [
					"San Jose",
					"Santa Clara"
				],
			},
			{
				name: "cityId1",
				label: "Cities",
				area: true,
				value: [
					1,
					2,
				],
			},
			{
				name: "maxListPrice",
				label: "Max. Price",
				value: 500000,
			},
			{
				name: "bedrooms",
				label: "Bedrooms",
				value: 3,
			},
			{
				name: "bathCount",
				label: "Bath Count",
				value: 2,
			},
			{
				name: "something",
				label: "Some. thing",
				value: 1,
			},
			{
				name: "something2",
				label: "Some. thing2",
				value: 12,
			},
			{
				name: "something3",
				label: "Some. thing3",
				value: 13,
			},
			{
				name: "something4",
				label: "Some. thing4",
				value: 14,
			},
			{
				name: "something5",
				label: "Some. thing5",
				value: 15,
			},
		],
	},
	{
		id: 2,
		leadId: 1,
		name: "Part TWO",
		recieveEmails: true,
		createdOn: "2018-11-13T14:46:41.723-08:00",
		modifiedOn: "2018-11-14T14:46:41.723-08:00",
		inactive: false,
		criteria: [
			{
				name: "cityId",
				label: "Cities",
				area: true,
				value: [
					3,
					4,
				],
			},
			{
				name: "minListPrice",
				label: "minListPrice",
				value: 70000
			},
			{
				name: "cityId2",
				label: "Cities",
				area: true,
				value: [
					"New York",
					"San Francisco"
				],
			},
		],
	},
];