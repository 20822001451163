import { Moment } from "moment";

export const dateFormatter = (date: Moment) => {
	return date.format("ddd MMM D YYYY");
}

export const shortDateFormatter = (date: Moment) => {
	return date.format("MMM D YYYY");
}

export const numberedDateFormatter = (date: Moment) => {
	return date.format("MM/DD/YYYY");
}

export const numberedDashedDateFormatter = (date: Moment) => {
	return date.format("YYYY-MM-DD");
}

export const monthDayFormatter = (date: Moment) => {
	return date.format("MM/DD");
}

export const dateTimeFormatter = (date: Moment) => {
	return date.format("MMM D, LT");
}

export const dayDateFormatter = (date: Moment) => {
	return date.format("ddd MMM D");
}

export const timeDateFormatter = (date: Moment) => {
	return date.format("h:mm A M/D/YY");
}