import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		cardActions: {
			paddingLeft: 0,
			paddingTop: 24,
		},
		note: {
			fontSize: 12,
		},
		radio: {
			color: "#616161",
			"&$radioChecked": {
				color: "#616161",
			},
		},
		radioChecked: {},
		radioLabel: {
			fontSize: 12,
			fontWeight: "bold",
			paddingLeft: 12,
		},
		textFieldContainer: {
			paddingRight: 12,
		},
	});
};
