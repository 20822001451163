import { CampaignResource } from "api/resource/campaign";

export const campaigns: CampaignResource[] = [
	{
		id: 100,
		name: "New-Buyer",
		description: "This is a very cool description",
		status: "Active",
		emailMarketingTypeId: 1,
		dateCreated: "2019-11-19T14:46:41.723-08:00",
	},
	{
		id: 200,
		name: "Second-Campaign",
		status: "Active",
		emailMarketingTypeId: 1,
		dateCreated: "2019-11-19T14:46:41.723-08:00",
	},
	{
		id: 300,
		name: "Mortgage Campanies Hate This New Trick",
		status: "Active",
		emailMarketingTypeId: 2,
		dateCreated: "2019-11-19T14:46:41.723-08:00",
	},
	{
		id: 400,
		name: "9 Ways To Buy A Mansion For Free",
		status: "Active",
		emailMarketingTypeId: 2,
		dateCreated: "2019-11-19T14:46:41.723-08:00",
	},
	{
		id: 500,
		name: "Seminar for new home sellers next Saturday",
		status: "Active",
		emailMarketingTypeId: 2,
		dateCreated: "2019-11-19T14:46:41.723-08:00",
	},
	{
		id: 600,
		name: "blast email types",
		status: "Active",
		emailMarketingTypeId: 2,
		dateCreated: "2022-11-19T14:46:41.723-08:00",
	},
]