class Type {
	public static LISTING = new Type(1, "Listing Report", "listingReport");
	public static OPEN_HOMES = new Type(2, "Open Homes Report", "openHomeReport")
	public static MARKET = new Type(3, "Market Report", "marketReport");

	private constructor(
		public readonly id: 1 | 2 | 3,
		public readonly label: string,
		public readonly value: string,
	) {
	}

	public get listing() {
		return this === Type.LISTING;
	}

	public get openHomes() {
		return this === Type.OPEN_HOMES;
	}

	public get market() {
		return this === Type.MARKET;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: number) {
		return this.values().find(value => value.id === id);
	}
}

export { Type as MarketReportType };