import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { saveIntegration, testIntegration } from "redux/slice/integrations";
import { Integration } from "model/integration";
import { Card } from "../card";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveIntegration,
	testIntegration,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	integration: Integration;
}

interface State {
	testDataSent: boolean;
	name?: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			testDataSent: false,
			name: props.integration.name,
		};
	}

	public render() {
		const { integration, saveIntegration, testIntegration } = this.props;
		const { testDataSent, name } = this.state;
		return (
			<Card
				integration={integration}
				deletable={true}
				content={
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item>
							<Mui.TextField
								label="Name"
								placeholder={integration.integrationType.name}
								value={name || ""}
								onChange={event => this.setState({ name: event.target.value })}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
					</Mui.Grid>
				}
				actions={
					<Mui.Grid container spacing={2}>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => saveIntegration({ integration: {
									...integration,
									name,
								}})}
							>
								Save
							</Mui.Button>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Tooltip
								placement="bottom"
								PopperProps={{
									disablePortal: true,
								}}
								title="Sending test data"
								open={testDataSent}
								disableFocusListener
								disableHoverListener
								disableTouchListener
							>
								<Mui.Button
									variant="contained"
									color="primary"
									onClick={() => {
										testIntegration({integration});
										this.setState({ testDataSent: true });
										setTimeout(() => {
											this.setState({ testDataSent: false });
										}, 3000);
									}}
								>
									Send Test Data
								</Mui.Button>
							</Mui.Tooltip>
						</Mui.Grid>
						{integration.data.editUrl && (
							<Mui.Grid item>
								<Mui.Button
									href={integration.data.editUrl as string}
									target="_blank"
								>
									Edit
								</Mui.Button>
							</Mui.Grid>
						)}
					</Mui.Grid>
				}
			/>
		);
	}

}

export const ZapierNewLeadIntegration = connect(mapStateToProps, mapDispatchToProps)(Component);