import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		label: {
			fontWeight: "bold"
		},
		noteLabel: {
			fontWeight: "bold"
		},
		labelSpace: {
			marginTop:'8px'
		}
	});
};
