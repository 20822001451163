import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getDraftBlastCampaigns } from "redux/selector";
import { missingEmailCalendarIntegration } from "redux/selector";
import { Card } from "./card";
import { Campaign } from "model/campaign";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	return {
		campaigns: getDraftBlastCampaigns(state),
		missingEmailCalendarIntegration: missingEmailCalendarIntegration(state),
		loading: state.campaigns.loading || state.campaignSubscriptions.loading || state.campaignCampaignMessages.loading || state.campaignEmails.loading || state.integrations.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	PageProps,
	Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

class Component extends React.Component<Props> {

	private get filteredCampaigns() {
		let campaigns: Campaign[] = this.props.campaigns
		.filter((campaign) => {
			return campaign.campaignCampaignMessages[0] && campaign.campaignCampaignMessages[0].campaignMessage;
		})
		.sort((a, b) => b.dateCreated.valueOf() - a.dateCreated.valueOf())
	;
		return campaigns;
	}
	
	public render() {
		const { user, loading, campaigns } = this.props;
		const title = "Email Drafts";
		const filteredCampaigns = this.filteredCampaigns;
		return (
			<DashboardLayout
				permitted={user && user.permissions.blastCampaigns}
				fullScreen={false}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={3} style={{paddingTop: 25}}>
					{loading ? 
						<LoadingIndicator />
						: 
						<>
							{
								<>
									{campaigns && campaigns.length 
									? 
										filteredCampaigns.map((campaign) => (
											<Mui.Grid key={campaign.id} item>
												<Card campaign={campaign} />
											</Mui.Grid>
										))
									: 
										<Mui.Grid item>
											<Mui.Typography variant="h3" align="center">
											You have no draft emails. Draft emails will appear here.
											</Mui.Typography>
										</Mui.Grid>
									}
								</>
							}
						</>
					}
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const CampaignDraftEmailsPage = connect(mapStateToProps, mapDispatchToProps)(Component);